const translations = {
  stepper: {
    overview: "Überblick",
    guest_registration: "Gastdaten",
    signature: "Unterschrift",
    payment: "Zahlung",
    issue_key: "Schlüssel",
    checkin_finish: "Check-In abschliessen",
    checkout_finish: "Check-Out abschliessen",
    pre_checkin_summary: "Übersicht",
    checkin_summary: "Übersicht",
    checkout_summary: "Übersicht",
  },
};

export {translations};
