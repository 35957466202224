const translations = {
  findBooking: {
    title: "Let's find your Booking",
    subTitle: "With one of the following options",
    bookingIntro: "Please enter your last name and your booking number",
    lastName: "Last Name",
    bookingId: "Booking Number",
    bookingNumberInfo:
      "The booking number can be found in your reservation confirmation.",
    loadingSearchResults: "One moment please... \n\nReservation is searched...",
    personalDetails: "Personal Details",
    scanQrCode: "QR Code",
    detailSearchNotAvailable: "Detail search is currently not available.",
    confirm: "I confirm that this data belongs to me.",
  },
};

export {translations};
