import "./QrCode.scss";

import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {isNil} from "lodash";
import PropTypes from "prop-types";
import QRCodeImage from "qrcode.react";
import {useTranslation} from "react-i18next";
import QrReader from "react-qr-reader";
import {
  useDispatch,
  useSelector,
} from "react-redux";

import {Plugins} from "@capacitor/core";

import {ReactComponent as CameraSwitchIcon} from "../../assets/images/camera_switch.svg";
import {ReactComponent as QrCodeIcon} from "../../assets/images/Icon_qr_code.svg";
import {NOTIFICATION_TYPES} from "../../constants";
import {
  hideAllNotifications,
  setNotification,
} from "../../store/actions";
import {usePreviousValue} from "../../utils/customHooks/previousValue.hook";
import {isNativeApp} from "../../utils/mobileDevice.utils";
import {
  getNotificationKeyByApiErrorCode,
  getNotificationNameByText,
  notificationMessages,
} from "../../utils/notification.utils";
import {Banner} from "../Banner";
import {
  Button,
  buttonTypes,
} from "../Button";

const {BarcodeScanner} = Plugins;

const QR_READER_CAMERA_FACING_MODES = {
  FRONT: "user",
  BACK: "environment",
};

const QrCode = ({
  isScanSuccessful,
  scannedText,
  onSuccessScan = () => {},
  shouldStopScanning,
  onIsScanningChange = () => {},
  isClosing,
  isValidQR = () => true,
}) => {
  const {t} = useTranslation(["qrCode", "general", "notification"]),
    dispatch = useDispatch();

  const general = useSelector(({generalReducer}) => generalReducer),
    {isTerminal} = general;

  const [constraint, setConstraint] = useState({}),
    [isScanning, setIsScanning] = useState(false),
    [isVideoDevice, setIsVideoDevice] = useState(false),
    [isAccessGranted, setIsAccessGranted] = useState(false),
    [cameraFacingMode, setCameraFacingMode] = useState(
      QR_READER_CAMERA_FACING_MODES.BACK
    ),
    [videoDevicesCount, setVideoDevicesCount] = useState(0);

  const shouldResumeScanning = useRef(null);

  const previousIsScanning = usePreviousValue(isScanning);

  const qrCodeError = useCallback(() => {
    dispatch(
      setNotification({
        type: NOTIFICATION_TYPES.ERROR,
        messageKey: getNotificationNameByText(notificationMessages.error.invalidQrCode),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (isScanning) {
      dispatch(hideAllNotifications());
    }
  }, [isScanning, dispatch]);

  useEffect(() => {
    if (isScanning !== previousIsScanning) {
      onIsScanningChange(isScanning);
    }
  }, [isScanning, previousIsScanning, onIsScanningChange]);

  const onSuccess = useCallback(
    (data) => {
      if (isNil(data)) {
        return;
      }

      if (isValidQR(data)) {
        onSuccessScan(data);
        setIsScanning(false);
      } else {
        qrCodeError();

        if (isNativeApp) {
          BarcodeScanner.startScan({targetedFormats: ["QR_CODE"]}).then((result) => {
            if (result.hasContent) {
              onSuccess(result.content);
            }
          });
        }
      }
    },
    [onSuccessScan, qrCodeError, isValidQR]
  );

  const setScannerState = useCallback(
    (state) => {
      setIsScanning(state);

      if (state) {
        dispatch(hideAllNotifications());
        if (!isNativeApp) {
          return;
        }

        checkAppPermission().then((isGranted) => {
          if (isGranted) {
            BarcodeScanner.startScan({targetedFormats: ["QR_CODE"]}).then((result) => {
              if (result.hasContent) {
                onSuccess(result.content);
              }
            });
          } else {
            setIsScanning(false);
          }
        });
      }
    },
    [onSuccess, dispatch]
  );

  useEffect(() => {
    if (shouldStopScanning) {
      setScannerState(false);

      if (!isNativeApp) {
        return;
      }

      BarcodeScanner.stopScan();
    }
  }, [shouldStopScanning, setScannerState]);

  useEffect(() => {
    if (isNativeApp) {
      BarcodeScanner.prepare();
    }

    if (navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia({video: true}).then(() => {
        navigator.mediaDevices?.enumerateDevices().then((info) => {
          const devices = info.filter((device) => device.kind === "videoinput");

          setVideoDevicesCount(devices.length);

          if (devices.length > 0) {
            setConstraint({
              deviceId: {exact: devices[devices.length - 1].deviceId},
            });
            setIsVideoDevice(true);
          } else {
            setIsVideoDevice(false);
          }
        });
      });
    }

    return () => {
      if (!isNativeApp) {
        return;
      }

      BarcodeScanner.stopScan();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCameraStatus = useCallback(
    (isGranted) => {
      if (isGranted) {
        setIsAccessGranted(true);
      } else {
        setScannerState(false);
        setIsAccessGranted(false);
      }
    },
    [setScannerState]
  );

  const isCameraStatusGranted = (state) => {
    return state.toLowerCase() === "granted" || state.toLowerCase() === "prompt";
  };

  useEffect(() => {
    if (!isNativeApp) {
      try {
        if ("permissions" in navigator) {
          navigator.permissions.query({name: "camera"}).then(function (permissionStatus) {
            setIsAccessGranted(isCameraStatusGranted(permissionStatus.state));
            permissionStatus.addEventListener("change", (event) => {
              let state = event.target.state;
              setCameraStatus(isCameraStatusGranted(state));
            });
          });
        }
      } catch (_) {
        if (navigator.mediaDevices) {
          navigator.mediaDevices
            .getUserMedia({video: true})
            .then(() => {
              setCameraStatus(true);
            })
            .catch(() => {
              setCameraStatus(false);
            });
        }
      }
    } else {
      checkAppPermission().then((isGranted) => {
        setCameraStatus(isGranted);
      });
    }
  }, [setCameraStatus]);

  const checkAppPermission = async () => {
    // check or request permission
    return BarcodeScanner.checkPermission({force: true}).then((status) => {
      if (status.granted) {
        // the user granted permission
        return true;
      }
      return false;
    });
  };

  const onError = (error) => {
    if (getNotificationKeyByApiErrorCode(error.name)) {
      setScannerState(false);
    }
  };

  const checkCameraStatus = () => {
    if (!isVideoDevice) {
      dispatch(
        setNotification({
          type: NOTIFICATION_TYPES.ERROR,
          messageKey: getNotificationNameByText(
            notificationMessages.error.noVideoInputDevicesError
          ),
        })
      );
    } else if (!isAccessGranted) {
      dispatch(
        setNotification({
          type: NOTIFICATION_TYPES.ERROR,
          messageKey: getNotificationNameByText(
            notificationMessages.error.cameraAccessDenied
          ),
        })
      );
    }
  };

  const switchCameraDirection = () => {
    const oppositeMode = Object.values(QR_READER_CAMERA_FACING_MODES).find(
      (mode) => mode !== cameraFacingMode
    );

    setCameraFacingMode(oppositeMode);
  };

  useEffect(() => {
    if (!isScanning || isNativeApp) {
      return;
    }

    setIsScanning(false);
    shouldResumeScanning.current = true;
  }, [cameraFacingMode, isScanning]);

  useEffect(() => {
    if (!shouldResumeScanning.current) {
      return;
    }

    shouldResumeScanning.current = false;
    setIsScanning(true);
  }, [isScanning]);

  return (
    <div className="qr-code">
      {!isClosing && (
        <>
          {isScanSuccessful ? (
            <>
              <div className="qr-code__image">
                <div className="qr-code__image__inner-square">
                  <QRCodeImage value={scannedText} size={350} />
                </div>
              </div>

              <Banner isSuccess isLarge className="qr-code__message">
                {t("scanSuccessful")}
              </Banner>
            </>
          ) : (
            <>
              {isScanning && (
                <>
                  {!isNativeApp ? (
                    <QrReader
                      delay={300}
                      onError={(e) => {
                        onError(e);
                        checkCameraStatus();
                      }}
                      onScan={onSuccess}
                      constraints={
                        isTerminal
                          ? {...constraint, facingMode: cameraFacingMode}
                          : {facingMode: cameraFacingMode}
                      }
                      className="qr-code__camera"
                    />
                  ) : (
                    <div className="qr-code__camera qr-code__camera-box"></div>
                  )}
                  <div className="qr-code__hint">{t("hint")}</div>
                  {isTerminal && (
                    <div className="qr-code__hint">{t("additionalHint")}</div>
                  )}
                </>
              )}

              {!isScanning && <QrCodeIcon className="qr-code__icon" />}

              {!isScanning && (
                <Button
                  className="qr-code__button"
                  buttonType={isScanning ? buttonTypes.secondary : buttonTypes.accent}
                  onClick={() => {
                    checkCameraStatus();
                    setScannerState(true);
                  }}>
                  {t("startScan")}
                </Button>
              )}
            </>
          )}
        </>
      )}
      {videoDevicesCount > 1 && !isNativeApp && (
        <CameraSwitchIcon
          className="qr-code__switch-button"
          onClick={switchCameraDirection}
        />
      )}
    </div>
  );
};

QrCode.propTypes = {
  isScanSuccessful: PropTypes.bool,
  scannedText: PropTypes.string,
  onSuccessScan: PropTypes.func,
  isClosing: PropTypes.bool,
  shouldStopScanning: PropTypes.bool,
  onIsScanningChange: PropTypes.func,
  isValidQR: PropTypes.func,
};

export {QrCode};
