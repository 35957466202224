import React from "react";

import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {goHomeAction} from "../../store";
import {
  Button,
  buttonTypes,
} from "../Button";

const NavToStartBtn = ({className = "", isSecondary = false, title}) => {
  const {t} = useTranslation(["general"]),
    dispatch = useDispatch();

  const handleGoToStartPageButtonClick = () => {
    dispatch(goHomeAction({hasConfirmModal: true}));
  };

  return (
    <Button
      onClick={handleGoToStartPageButtonClick}
      buttonType={isSecondary ? buttonTypes.secondary : buttonTypes.primary}
      className={className}>
      {title || t("startpage")}
    </Button>
  );
};

NavToStartBtn.propTypes = {
  className: PropTypes.string,
  isSecondary: PropTypes.bool,
  title: PropTypes.string,
};

export {NavToStartBtn};
