import "./PersonalData.scss";

import React, {useCallback, useEffect, useState} from "react";

import {isNil} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {Datepicker} from "../../../components/Datepicker";
import {DateRange} from "../../../components/DateRange/DateRange.component";
import {Input} from "../../../components/Input";
import {DATE_FORMAT, FORM_REQUIREMENTS, LOOKUP_FIELDS_MAPPING} from "../../../constants";
import {getLookupFieldsSagaAction} from "../../../store/actions";
import {removeAllNumbers} from "../../../utils/formInput.utils";

const PersonalData = ({
  onChange = () => {},
  onSearch = () => {},
  personalData,
  invalidData,
  isSearched,
  isPersonalDataConfirmed,
  onToggleConfirm = () => {},
}) => {
  const {t} = useTranslation(["form", "findBooking", "general", "reservation"]),
    dispatch = useDispatch();

  const [hasError, setHasError] = useState(false);

  const lookupFields = useSelector(({lookupFieldsReducer}) => lookupFieldsReducer),
    {requirements = {}} = lookupFields;

  const fetchLookupFields = useCallback(() => {
    new Promise((resolve, reject) => {
      dispatch(getLookupFieldsSagaAction({resolve, reject}));
    })
      .then(() => {
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchLookupFields();
  }, [fetchLookupFields]);

  const fieldIsRequired = (key) => {
    return requirements.hasOwnProperty(key) && requirements[key];
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const handleDateRangeSelect = (dates) => {
    onChange({
      [LOOKUP_FIELDS_MAPPING.MAIN_GUEST.ARRIVAL_DATE.uiKey]: moment(
        dates.selection.startDate
      ).format(DATE_FORMAT),
      [LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DEPARTURE_DATE.uiKey]: moment(
        dates.selection.endDate
      ).format(DATE_FORMAT),
    });
  };

  return (
    <>
      {hasError ? (
        <div>{t("findBooking:detailSearchNotAvailable")}</div>
      ) : (
        <div className="personal-data__fields">
          {fieldIsRequired(
            LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EXTERNAL_RESERVATION_CODE.uiKey
          ) && (
            <Input
              onKeyPress={handleKeyPress}
              label={t("externalReservationCode")}
              onChange={onChange}
              fieldKey={LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EXTERNAL_RESERVATION_CODE.uiKey}
              value={
                personalData[
                  LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EXTERNAL_RESERVATION_CODE.uiKey
                ]
              }
              isInvalid={
                isNil(
                  personalData[
                    LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EXTERNAL_RESERVATION_CODE.uiKey
                  ]
                ) ||
                !!invalidData[
                  LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EXTERNAL_RESERVATION_CODE.uiKey
                ]
              }
              shouldDisplayErrorMessage={
                isSearched &&
                !personalData[
                  LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EXTERNAL_RESERVATION_CODE.uiKey
                ]?.trim()
              }
              requirement={FORM_REQUIREMENTS.REQUIRED}
            />
          )}
          {fieldIsRequired(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.FIRST_NAME.uiKey) && (
            <Input
              onKeyPress={handleKeyPress}
              label={t("firstName")}
              onChange={onChange}
              value={personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.FIRST_NAME.uiKey]}
              isInvalid={
                isNil(personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.FIRST_NAME.uiKey]) ||
                !!invalidData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.FIRST_NAME.uiKey]
              }
              fieldKey={LOOKUP_FIELDS_MAPPING.MAIN_GUEST.FIRST_NAME.uiKey}
              shouldDisplayErrorMessage={
                isSearched &&
                !personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.FIRST_NAME.uiKey]?.trim()
              }
              requirement={FORM_REQUIREMENTS.REQUIRED}
              filter={removeAllNumbers}
            />
          )}
          {fieldIsRequired(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.LAST_NAME.uiKey) && (
            <Input
              onKeyPress={handleKeyPress}
              label={t("lastName")}
              onChange={onChange}
              value={personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.LAST_NAME.uiKey]}
              isInvalid={
                isNil(personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.LAST_NAME.uiKey]) ||
                !!invalidData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.LAST_NAME.uiKey]
              }
              fieldKey={LOOKUP_FIELDS_MAPPING.MAIN_GUEST.LAST_NAME.uiKey}
              shouldDisplayErrorMessage={
                isSearched &&
                !personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.LAST_NAME.uiKey]?.trim()
              }
              requirement={FORM_REQUIREMENTS.REQUIRED}
            />
          )}
          {fieldIsRequired(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.ARRIVAL_DATE.uiKey) &&
            fieldIsRequired(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DEPARTURE_DATE.uiKey) && (
              <DateRange
                arrivalDate={
                  personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.ARRIVAL_DATE.uiKey]
                }
                departureDate={
                  personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DEPARTURE_DATE.uiKey]
                }
                onChange={handleDateRangeSelect}
                minDate={moment().startOf("year")}
                maxDate={moment().add(1, "years").endOf("year")}
                instructionsText={t("chooseDeparture")}
                requirement={FORM_REQUIREMENTS.REQUIRED}
                startDatePlaceholder={t("reservation:arrival")}
                endDatePlaceholder={t("reservation:departure")}
                isInvalid={
                  isSearched &&
                  (isNil(
                    personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.ARRIVAL_DATE.uiKey]
                  ) ||
                    !moment(
                      personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.ARRIVAL_DATE.uiKey],
                      DATE_FORMAT,
                      true
                    ).isValid() ||
                    isNil(
                      personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DEPARTURE_DATE.uiKey]
                    ) ||
                    !moment(
                      personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DEPARTURE_DATE.uiKey],
                      DATE_FORMAT,
                      true
                    ).isValid() ||
                    !!invalidData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.ARRIVAL_DATE.uiKey] ||
                    !!invalidData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DEPARTURE_DATE.uiKey])
                }
              />
            )}
          {fieldIsRequired(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EMAIL.uiKey) && (
            <Input
              onKeyPress={handleKeyPress}
              inputType="email"
              label={t("email")}
              onChange={onChange}
              value={personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EMAIL.uiKey]}
              isInvalid={
                isNil(personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EMAIL.uiKey]) ||
                !!invalidData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EMAIL.uiKey]
              }
              fieldKey={LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EMAIL.uiKey}
              shouldDisplayErrorMessage={
                isSearched &&
                !personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EMAIL.uiKey]?.trim()
              }
              requirement={FORM_REQUIREMENTS.REQUIRED}
              filter={removeAllNumbers}
            />
          )}
          {fieldIsRequired(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DATE_OF_BIRTH.uiKey) && (
            <Datepicker
              label={t("dateOfBirth")}
              date={personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DATE_OF_BIRTH.uiKey]}
              minDate={moment().subtract(117, "years")}
              maxDate={moment().subtract(18, "years")}
              onKeyPress={handleKeyPress}
              onChange={onChange}
              fieldKey={LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DATE_OF_BIRTH.uiKey}
              isInvalid={
                isNil(
                  personalData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DATE_OF_BIRTH.uiKey]
                ) || !!invalidData[LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DATE_OF_BIRTH.uiKey]
              }
              shouldDisplayErrorMessage={
                isSearched &&
                !personalData[
                  LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DATE_OF_BIRTH.uiKey
                ]?.trim()
              }
              requirement={FORM_REQUIREMENTS.REQUIRED}
              index={2}
              shouldDisplayLegalAgeErrorMessage
            />
          )}
          <label
            className={`confirm-checkbox ${
              isSearched && !isPersonalDataConfirmed ? "confirm-checkbox--error" : ""
            }`}>
            <input
              type="checkbox"
              onChange={(e) => onToggleConfirm(e.target.checked)}
              checked={isPersonalDataConfirmed}
            />
            {t("findBooking:confirm")}
          </label>
        </div>
      )}
    </>
  );
};

PersonalData.propTypes = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  personalData: PropTypes.object,
  invalidData: PropTypes.shape({
    [LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EXTERNAL_RESERVATION_CODE.uiKey]: PropTypes.bool,
    [LOOKUP_FIELDS_MAPPING.MAIN_GUEST.FIRST_NAME.uiKey]: PropTypes.bool,
    [LOOKUP_FIELDS_MAPPING.MAIN_GUEST.LAST_NAME.uiKey]: PropTypes.bool,
    [LOOKUP_FIELDS_MAPPING.MAIN_GUEST.ARRIVAL_DATE.uiKey]: PropTypes.bool,
    [LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DEPARTURE_DATE.uiKey]: PropTypes.bool,
    [LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EMAIL.uiKey]: PropTypes.bool,
    [LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DATE_OF_BIRTH.uiKey]: PropTypes.bool,
  }),
  isSearched: PropTypes.bool,
  onToggleConfirm: PropTypes.func,
  isPersonalDataConfirmed: PropTypes.bool,
};

export {PersonalData};
