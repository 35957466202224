import React, {useEffect} from "react";

import {ConnectedRouter} from "connected-react-router";
import {
  useDispatch,
  useSelector,
} from "react-redux";

import {Plugins} from "@capacitor/core";

import {Router} from "../";
import {history} from "../../../store";
import {
  findBookingDetailsByGuidSagaAction,
  findBookingSagaAction,
  getConfiguration,
} from "../../../store/actions";
import {isNativeApp} from "../../../utils/mobileDevice.utils";
import {getBookingDetailsFromUrl} from "../../../utils/route.utils";
import {RefreshCache} from "../RefreshCache.component";

const Root = () => {
  const {AssaAbloyBle, App} = Plugins;

  const dispatch = useDispatch(),
    siteSettings = useSelector(({appConfigReducer}) => appConfigReducer.siteSettings);

  useEffect(() => {
    dispatch(getConfiguration());
  }, [dispatch]);

  const getSavedReservationDataOnNative = () => {
    const {bookingId, bookingName, type} = getBookingDetailsFromUrl();

    if (bookingId && bookingName && type) {
      return;
    }

    let isFoundByGUID = false;

    AssaAbloyBle.getReservationGUID()
      .then((reservationData) => {
        const guid = reservationData["reservation-id"];

        if (!!guid) {
          isFoundByGUID = true;
          dispatch(findBookingDetailsByGuidSagaAction({guid}));
        }
      })
      .finally(() => {
        if (!isFoundByGUID) {
          AssaAbloyBle.getReservation().then((reservationData) => {
            if (!!reservationData.reservationCode && !!reservationData.lastName) {
              dispatch(
                findBookingSagaAction({
                  bookingId: reservationData.reservationCode,
                  bookingName: reservationData.lastName,
                })
              );
            }
          });
        }
      });
  };

  useEffect(() => {
    if (isNativeApp) {
      App.addListener("appUrlOpen", getSavedReservationDataOnNative);
      getSavedReservationDataOnNative();

      return () => {
        App.removeAllListeners();
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ConnectedRouter history={history}>
        {siteSettings?.id && <Router />}
        <RefreshCache />
      </ConnectedRouter>
    </>
  );
};

export {Root};
