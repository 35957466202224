export const scrollToErrorAndFocus = () => {
  const firstInvalidElement = document.querySelector(".error--focusable");

  if (firstInvalidElement) {
    firstInvalidElement.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    firstInvalidElement.focus();
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  const page = document.querySelector(".page");

  if (!page) {
    return;
  }

  page.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollToBottom = () => {
  window.scrollTo({
    top: window.scrollHeight,
    behavior: "smooth",
  });

  const page = document.querySelector(".page");

  if (!page) {
    return;
  }

  page.scrollTo({
    top: page.scrollHeight,
    behavior: "smooth",
  });
};
