import {SET_TRANSLATIONS} from "../../../constants";
import {createReducer} from "@reduxjs/toolkit";

let initialState = {
  translations: {},
};

const translationsReducer = createReducer(initialState, (builder) => {
  builder.addCase(SET_TRANSLATIONS, (state, action) => {
    state.translations = action.payload;
  });
});

export {translationsReducer};
