import {
  get,
  set,
} from "lodash";

import {createReducer} from "@reduxjs/toolkit";

import {
  RESERVATION_MAPPING,
  SET_CHECKOUT_AVAILABLE,
  SET_IS_CHECKIN_VIEWED,
  SET_IS_CHECKOUT_VIEWED,
  SET_RATE_PLAN,
  SET_RESERVATION,
} from "../../../constants";
import {getUpdateObjectFn} from "../../../utils/state.utils";

let initialState = {
  uuid: "",
  code: "",
  name: "",
  ratePlanCode: "",
  ratePlanName: "",
  arrivalDate: "",
  departureDate: "",
  guestCount: 0,
  overnightStays: 0,
  checkOutAvailable: false,
  roomCategoryName: "",
  isCheckinViewed: false,
  isCheckoutViewed: false,
};

const reservationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_RESERVATION, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      updateState(RESERVATION_MAPPING.UUID, "");
      updateState(RESERVATION_MAPPING.CODE, "");
      updateState(RESERVATION_MAPPING.NAME, "");
      updateState(RESERVATION_MAPPING.GUEST_COUNT, 0);
      updateState(RESERVATION_MAPPING.ARRIVAL_DATE, "");
      updateState(RESERVATION_MAPPING.DEPARTURE_DATE, "");
      updateState(RESERVATION_MAPPING.OVERNIGHT_STAYS, 0);
      updateState(RESERVATION_MAPPING.ROOM_CATEGORY_NAME, "");

      if (
        !!state.code &&
        state.code !== get(action.payload, RESERVATION_MAPPING.CODE.apiKey) &&
        !!state.name &&
        state.name !== get(action.payload, RESERVATION_MAPPING.NAME.apiKey)
      ) {
        state.isCheckinViewed = false;
        state.isCheckoutViewed = false;
      }
    })
    .addCase(SET_CHECKOUT_AVAILABLE, (state, action) => {
      set(state, RESERVATION_MAPPING.CHECK_OUT_AVAILABLE.uiKey, action.payload);
    })
    .addCase(SET_RATE_PLAN, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      updateState(RESERVATION_MAPPING.RATE_PLAN_NAME);
      updateState(RESERVATION_MAPPING.RATE_PLAN_CODE);
    })
    .addCase(SET_IS_CHECKIN_VIEWED, (state) => {
      state.isCheckinViewed = true;
    })
    .addCase(SET_IS_CHECKOUT_VIEWED, (state) => {
      state.isCheckoutViewed = true;
    });
});

export {reservationReducer};
