const translations = {
  folio: {
    payNow: "Jetzt bezahlen",
    title: "Kostenübersicht",
    subtitle: "Bitte prüfen Sie Ihre Kostenübersicht, um fortzufahren",
    invoiceAddress: "Rechnungsanschrift:",
    positions: "Rechnungspositionen",
    description: "Beschreibung",
    quantity: "Mng.",
    price: "Preis",
    total: "Gesamt",
    remainingPayments: "Ausstehende Zahlungen:",
    question: "Haben Sie Fragen zur Rechnung?",
    part: "Teil {{count}}",
    summary: "Zusammenfassung",
    onlyCreditCardAllowed:
      "<0>Online Zahlung nur mit Kreditkarte möglich.</0> Wenn Sie keine Kreditkarte haben, wenden Sie sich bitte an <2>das Hotel</2>.",

    billingAddressWarningHeader: "Ihre Rechnungsadresse hat sich geändert",
    billingAddressWarningText:
      "Die Änderung der Rechnungsadresse wird möglicherweise nicht sofort auf Ihrer Rechnung ausgewiesen. Sie wurde erfasst und wird bis zu Ihrem Check-Out angepasst.\n\nWenn Sie weitere Fragen oder Wünsche bezüglich der Rechnungsstellung haben, kontaktieren Sie bitte <1>das Hotel</1>.",
    billingAddressWarningButton: "Fortfahren",

    // split invoice
    invoice: "Rechnung",
    noInvoices: "Es wurden keine Rechnungen zu Ihrer Buchung gefunden.",
    editInvoice: "Rechnung bearbeiten",
    business: "Business",
    private: "Privat",
    move: "Verschieben",
  },
};

export {translations};
