const translations = {
  notification: {
    errorTitle: "Error",
    retry: "Try again",
    contactHotelAction: "Contact the hotel",
    error: {
      technical: {
        headline: "Error",
        text: "Unfortunately, there was a technical error.\nPlease contact the hotel.",
      },

      inconsistentPayment:
        "The payment attempt failed. In order to complete the process please come to the reception.",

      invalidAuthBookingNumber:
        "No reservation found with this booking number.\nPlease check your booking data and try again.",
      invalidAuthName:
        "No reservation found with this name.\nPlease check your booking data and try again.",
      reservationNotFound:
        "No reservation found.\nPlease check your booking data and try again.",
      moreThanOneReservationWithTheSameData:
        "No reservation found.\nPlease select a different search method to find it.",

      cameraAccessDenied:
        "Unfortunately, the camera access is denied.\nPlease allow access to your camera or select another authentication option.",
      noVideoInputDevicesError:
        "Unfortunately, the camera is not available.\nPlease select another authentication option.",
      bluetoothNotGranted:
        "Bluetooth permission not granted.\nPlease adjust your Bluetooth settings.",
      bluetoothNotEnabled:
        "Bluetooth not activated.\nPlease turn on your bluetooth to get access to your keys.",
      checkInNotAvailable: {
        headline: "Check-In failed",
        text: "Your check-in could not be finished.\nPlease contact the hotel.",
      },

      checkInNoRoomAssigned: {
        headline: "Check-In failed",
        text: "Check-in cannot be completed.\nPlease contact the hotel.",
      },
      checkinRoomNotReady: {
        headline: "Your Room is not yet ready",
        text: "We need a bit more time to prepare your Room.\nPlease try to Check-In later.",
      },
      checkoutWrongTime: {
        headline: "It’s not your Arrival Time",
        text: "Your check-Out could not be finished.\nPlease contact the hotel.",
      },
      checkoutNotAvailable: {
        headline: "Check-Out not possible",
        text: "Your Check-Out could not be finished.\nPlease contact the hotel.",
      },
      checkOutGeneral: "Please go to the reception",
      checkOutFinish:
        "An error occurred during check out.\nPlease try again or contact the hotel.",
      checkOutOpenInvoice: {
        headline: "Please finish Payment",
        text: "You will be able to continue with the next step after finishing all Payments.",
      },

      writeKey:
        "An error occurred while writing to the key card.\nPlease try again or contact the hotel.",
      openDoor:
        "Please try again.\nIf an error occurs repeatedly, renew the keys or contact the hotel.",
      mobileKey:
        "Door could not be opened.\nPlease hold your phone closer to the doorlock.",
      invalidQrCode:
        "This QR Code is not valid.\nPlease provide a valid code or press “Back” to cancel.",
    },
    warning: {
      noValidQrCode: "This is not a valid QrCode",
      emptyKeys: "There are currently no keys available.",
    },
    success: {
      writingDone:
        "Your room card has been successfully written,\nyou can remove the card.",
      openDoor: "{{name}} can be opened.",
      payment: "Payment completed successfully",
    },
  },
};

export {translations};
