import "./Tile.scss";

import {ReactComponent as ArrowRightIcon} from "../../assets/images/Icon_arrow_right.svg";
import PropTypes from "prop-types";
import React from "react";

const Tile = ({
  imageUrl,
  title,
  link,
  hasArrow = false,
  hasRadialGradient = false,
  Icon,
  className = "",
  style,
  ...args
}) => {
  return (
    <a
      className={`tile ${hasRadialGradient ? "tile--radial-gradient" : ""} ${className}`}
      style={{backgroundImage: `url("${imageUrl}")`, ...style}}
      href={link}
      {...args}>
      <div className="tile__title">
        {Icon && <Icon />}
        {title}
      </div>
      {hasArrow && (
        <div className="tile__arrow">
          <ArrowRightIcon />
        </div>
      )}
    </a>
  );
};

Tile.propTypes = {
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  style: PropTypes.object,
  hasArrow: PropTypes.bool,
  Icon: PropTypes.object,
  hasRadialGradient: PropTypes.bool,
};

export {Tile};
