import "./Loader.scss";

import React from "react";

import SVG from "react-inlinesvg";

import LoaderIcon from "../../assets/images/Loader.svg";

const Loader = () => {
  return <SVG className="loader-icon" src={LoaderIcon} />;
};

Loader.propTypes = {};

export {Loader};
