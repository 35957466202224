import {createReducer} from "@reduxjs/toolkit";

import {
  HIDE_ALL_NOTIFICATIONS,
  HIDE_NOTIFICATION,
  SET_NOTIFICATION,
} from "../../../constants";

let initialState = [];

const notificationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_NOTIFICATION, (state, action) => {
      const existsNotificationWithKeyAndType = state.find(
        (notification) =>
          notification.messageKey === action.payload.messageKey &&
          notification.type === action.payload.type
      );

      if (!existsNotificationWithKeyAndType) {
        state.push({
          type: action.payload.type,
          messageHeader: action.payload.messageHeader || "",
          messageKey: action.payload.messageKey || "",
          messageAttributes: action.payload.messageAttributes || {},
          duration: action.payload.duration,
          shouldCloseManually: action.payload.shouldCloseManually || true,
          isPersistent: action.payload.isPersistent || false,
          isAllowedRetry: action.payload.isAllowedRetry,
        });
      }
    })
    .addCase(HIDE_NOTIFICATION, (state, action) => {
      return state.filter(
        (notification) =>
          !action.payload.messageKey ||
          !action.payload.type ||
          notification.messageKey !== action.payload.messageKey ||
          notification.type !== action.payload.type
      );
    })
    .addCase(HIDE_ALL_NOTIFICATIONS, (state) => {
      return [];
    });
});

export {notificationsReducer};
