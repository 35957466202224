import "./PageHeader.scss";

import {MODULE_TYPES, ROUTE_PATHS} from "../../../constants";
import {getModuleType, getStepFromUrl} from "../../../utils/route.utils";

import {LangSelect} from "../LangSelect";
import React from "react";
import {Support} from "../Support";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const PageHeader = () => {
  const {t} = useTranslation(["mobileKeys"]);
  const siteSettings = useSelector(({appConfigReducer}) => appConfigReducer.siteSettings),
    {name} = siteSettings;

  let module = getModuleType(true);

  return (
    <div className="page-header">
      <Support />
      <div className="page-header__title">
        {getStepFromUrl().step === ROUTE_PATHS.KEY_OVERVIEW.PATHNAME.substring(1) ? (
          <h1>{t("header")}</h1>
        ) : (
          <>
            {module === MODULE_TYPES.CHECK_IN && <h1>{t(`general:checkIn`)}</h1>}
            {module === MODULE_TYPES.CHECK_OUT && <h1>{t(`general:checkOut`)}</h1>}
            {module === MODULE_TYPES.PRE_CHECK_IN && <h1>{t(`general:preCheckIn`)}</h1>}
            {!Object.values(MODULE_TYPES).includes(module) && <h1>{name || ""}</h1>}
          </>
        )}
      </div>
      <LangSelect />
    </div>
  );
};

export {PageHeader};
