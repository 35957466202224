import "../Title/Title.scss";
import "../Subtitle/Subtitle.scss";

import {positions, sizes} from "../title.constants";

import PropTypes from "prop-types";
import React from "react";
import {Subtitle} from "../Subtitle";
import {Title} from "../Title";

const TitleWrapper = ({
  title = "",
  subtitle = "",
  size = sizes.default,
  position = positions.center,
}) => {
  return (
    <div className="title-wrapper">
      {title && <Title value={title} size={size} position={position} />}
      {subtitle && <Subtitle value={subtitle} position={position} />}
    </div>
  );
};

TitleWrapper.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  size: PropTypes.oneOf(Object.values(sizes)),
  position: PropTypes.oneOf(Object.values(positions)),
};

export {TitleWrapper};
