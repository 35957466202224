export const getElementId = (label, index = 0) => {
  return `${label}-${index}`;
};

export const getRandomName = () => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result + "-random";
};

export const jumpToNextInput = (event) => {
  if (event.key === "Enter") {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    const nextInput = form.elements[index + 1];

    if (!nextInput) {
      form.elements[index].blur();
    } else {
      nextInput.focus();
    }

    event.preventDefault();
  }
};

export const jumpToNextInputWithoutKey = (event) => {
  const form = event.target.form;
  const index = Array.prototype.indexOf.call(form, event.target);
  const nextInput = form.elements[index + 1];

  if (!nextInput) {
    form.elements[index].blur();
  } else {
    nextInput.focus();
  }

  event.preventDefault();
};

export const isEmailValid = (email) => {
  // eslint-disable-next-line
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const isPhoneValid = (phone) => {
  return /^[\d()/+-\s]*$/.test(phone);
};

export const isNameValid = (name) => {
  return /^[\p{N}\p{L}\p{Pc}\p{Pd}'\p{Zs}]*$/gu.test(name);
};

export const removeAllNumbers = (string) => {
  return string.replace(/\d+/g, "");
};

export const keepOnlyNumbers = (string) => {
  return string.toString().replace(/\D/g, "");
};

export const removeWhitespaces = (string) => {
  return string.trim();
};
