import {call, select, takeEvery} from "redux-saga/effects";

import {POST_OPEN_DOOR__SAGA} from "../../../../constants";
import {getClientTypeFn} from "../../utils/getClientType.generatorFunction";
import {isNil} from "lodash";

function* postOpenDoor(action) {
  const {id = null, resolve = null, reject = null} = action.payload;

  try {
    const store = yield select(),
      {mobile} = store.keysReducer,
      {key = "", parameters = {}} = mobile,
      {openDoors = ""} = parameters;

    if (!isNil(id) && openDoors && openDoors.length > 0) {
      const url = openDoors.replace(":door:", id);
      const client = yield* getClientTypeFn();
      yield call([client, "openDoor"], url, key);

      if (resolve) {
        resolve();
      }
    } else {
      if (reject) {
        reject();
      }
    }
  } catch (e) {
    if (reject) {
      reject();
    }
  }
}

export function* postOpenDoorSaga() {
  yield takeEvery(POST_OPEN_DOOR__SAGA, postOpenDoor);
}
