import "./ErrorScreen.scss";

import React, {useCallback} from "react";

import {get} from "lodash";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {
  ReactComponent as GenericErrorIcon,
} from "../../../assets/images/generic_error.svg";
import {NOTIFICATION_TYPES} from "../../../constants";
import {
  hideAllNotifications,
  toggleSupportModal,
} from "../../../store/actions";
import {
  getNotificationNameByText,
  notificationMessages,
  plainNotificationMappingsWithoutCategories,
} from "../../../utils/notification.utils";
import {
  Button,
  ButtonGroup,
  buttonTypes,
} from "../../Button";
import {Card} from "../../FeedbackNotificationCard";
import {LegendText} from "../../Page";
import {NavToStartBtn} from "../../PageNavBtn";

const ErrorScreen = ({
  messageHeader,
  messageKey,
  messageAttributes,
  isAllowedRetry = false,
  type,
  actionText,
}) => {
  const {t} = useTranslation(["notification", "general"]);
  const dispatch = useDispatch();

  const handleSupportClick = () => {
    dispatch(toggleSupportModal(true));
  };

  const handleRetryClick = () => {
    dispatch(hideAllNotifications());

    if (window.location.search.length > 0) {
      window.history.replaceState("", "", "" + window.location.pathname);
    }

    window.location.reload();
  };

  const notificationObject = messageKey
    ? t(messageKey, {...messageAttributes, returnObjects: true})
    : null;

  const getTitle = useCallback(() => {
    if (messageHeader) {
      return t(messageHeader);
    }

    return get(notificationObject, "headline", t("errorTitle"));
  }, [messageHeader, notificationObject, t]);

  const getContent = useCallback(() => {
    return typeof notificationObject === "string"
      ? notificationObject
      : get(
          notificationObject,
          "text",
          t(getNotificationNameByText(notificationMessages.error.technical.text))
        );
  }, [notificationObject, t]);

  const getIcon = useCallback(() => {
    return (
      Object.values(plainNotificationMappingsWithoutCategories).find(
        (notification) => notification.NOTIFICATION_KEY === messageKey
      )?.ICON || GenericErrorIcon
    );
  }, [messageKey]);

  const getType = useCallback(() => {
    return (
      type ||
      Object.values(plainNotificationMappingsWithoutCategories).find(
        (notification) => notification.NOTIFICATION_KEY === messageKey
      )?.TYPE ||
      NOTIFICATION_TYPES.ERROR
    );
  }, [type, messageKey]);

  return (
    <div className="error-screen">
      <Card
        type={getType()}
        title={getTitle()}
        Icon={getIcon()}
        className="error-screen__card error-screen-card">
        <div>{getContent()}</div>

        {isAllowedRetry && (
          <Button
            className="error-screen-card__button"
            buttonType={buttonTypes.primary}
            onClick={handleRetryClick}>
            {actionText || t(getNotificationNameByText(notificationMessages.retry))}
          </Button>
        )}

        <LegendText
          text={t(getNotificationNameByText(notificationMessages.contactHotelAction))}
          isClickable
          onClick={handleSupportClick}
          className="error-screen__support"
        />
      </Card>

      <div className="error-screen__action-button">
        <ButtonGroup>
          <NavToStartBtn isSecondary />
          <Button disabled buttonType={buttonTypes.inactive}>
            {t("general:next")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export {ErrorScreen};

ErrorScreen.propTypes = {
  messageHeader: PropTypes.string,
  messageKey: PropTypes.oneOf(
    Object.values(plainNotificationMappingsWithoutCategories)
      .filter((notificationObject) => notificationObject.IS_PERSISTENT)
      .map((notificationObject) => notificationObject.NOTIFICATION_KEY)
  ),
  messageAttributes: PropTypes.object,
  type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)),
  isAllowedRetry: PropTypes.bool,
  actionText: PropTypes.string,
};
