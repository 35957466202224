const translations = {
  findBooking: {
    title: "Finden Sie Ihre Buchung",
    subTitle: "Bitte wählen Sie die Art der Authentifizierung",
    bookingIntro: "Bitte geben Sie Ihren Nachnamen und Ihre Buchungsnummer ein",
    lastName: "Nachname",
    bookingId: "Buchungsnummer",
    bookingNumberInfo: "Die Buchungsnummer finden Sie in Ihrer Reservierungsbestätigung.",
    loadingSearchResults: "Einen Moment bitte... \n\nIhre Reservierung wird gesucht...",
    personalDetails: "Persönliche Angaben",
    scanQrCode: "QR-Code",
    detailSearchNotAvailable: "Die Detailsuche ist Momentan nicht verfügbar.",
    confirm: "Ich bestätige, dass dies meine Daten sind.",
  },
};

export {translations};
