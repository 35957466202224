import {Button, buttonTypes} from "../Button";

import PropTypes from "prop-types";
import React from "react";

const PageNavBtn = ({text, onClick, disabled = ""}) => {
  return (
    <Button
      buttonType={disabled ? buttonTypes.inactive : buttonTypes.btnType}
      type="button"
      onClick={onClick}>
      <span>{text}</span>
    </Button>
  );
};

PageNavBtn.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.string,
};

export {PageNavBtn};
