import * as Sentry from "@sentry/react";

import {Integrations} from "@sentry/tracing";
import PropTypes from "prop-types";
import React from "react";
import {getEnvironment} from "./utils/environment.utils";

const init = () => {
  Sentry.init({
    dsn: "https://da6a941d98c749cebaa9049bc442f7bf@o454267.ingest.sentry.io/5531701",
    integrations: [new Integrations.BrowserTracing()],
    environment: getEnvironment(),
    normalizeDepth: 5,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
};

const Logger = (props) => {
  const {children = []} = props;

  return (
    <Sentry.ErrorBoundary fallback="An error has occurred">
      {children}
    </Sentry.ErrorBoundary>
  );
};

Logger.propTypes = {
  children: PropTypes.any,
};

const middleware = Sentry.createReduxEnhancer({
  // Optionally pass options
});

export {init, Logger, middleware};
