import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

import {
  NOTIFICATION_TYPES,
  POST_KEY_CARD_ADDITIONAL_WRITING__SAGA,
} from "../../../constants";
import {
  getNotificationNameByText,
  notificationMessages,
} from "../../../utils/notification.utils";
import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  setNotification,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postKeyCardAdditionalWriting(action) {
  const {resolve, reject} = action.payload;

  try {
    let store = yield select(),
      {code, name} = store.reservationReducer;

    const client = yield* getClientTypeFn();
    yield call([client, "postKeyCardAdditionalWriting"], code, name);

    if (resolve) {
      resolve();
      yield put(hideLoadingOverlay());
    }

    yield put(
      setNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        messageKey: getNotificationNameByText(notificationMessages.success.writingDone),
        duration: 8000,
      })
    );
  } catch (e) {
    yield put(apiErrorSagaAction({error: e, isAllowedRetry: true}));
    yield put(hideLoadingOverlay());

    if (reject) {
      reject(e);
    }
  }
}

export function* postKeyCardAdditionalWritingSaga() {
  yield takeEvery(POST_KEY_CARD_ADDITIONAL_WRITING__SAGA, postKeyCardAdditionalWriting);
}
