const translations = {
  terms: {
    title: "Allgemeine Geschäftsbedingung",
    subtitle:
      "Bitte lesen und akzeptieren Sie die AGB, um unseren Check-In-Prozess zu starten.",
    acceptTerms: "Ich habe die AGB gelesen und akzeptiere sie",
  },
};

export {translations};
