import {HIDE_LOADING_OVERLAY, SHOW_LOADING_OVERLAY} from "../../../constants";

import {createReducer} from "@reduxjs/toolkit";

let initialState = {
  isVisible: false,
  message: "",
};

const loadingOverlayReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SHOW_LOADING_OVERLAY, (state, action) => {
      state.isVisible = true;
      state.message = action.payload || "";
    })
    .addCase(HIDE_LOADING_OVERLAY, (state) => {
      state.isVisible = false;
      state.message = "";
    });
});

export {loadingOverlayReducer};
