import env from "./env";
import {isNil} from "lodash";

const isTerminal = () => {
  return !isNil(env) && !isNil(env.Device) && env.Device === "terminal";
};

const isMobile = () => {
  return !isNil(env) && !isNil(env.Device) && env.Device === "mobile";
};

const isTablet = () => {
  return !isNil(env) && !isNil(env.Device) && env.Device === "tablet";
};

export {isTerminal, isMobile, isTablet};
