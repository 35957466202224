import {all} from "redux-saga/effects";

import {configSaga} from "./appConfig/config.saga";
import {costOverviewFinishSaga} from "./costOverview/costOverviewFinish.saga";
import {
  covidCheckFinishSaga,
  getCovidCheckGuestStatusSaga,
  getCovidCheckStatusesSaga,
  postCovidCheckSaga,
} from "./covid";
import {apiErrorSaga, goHomeSaga} from "./effects";
import {postCheckInFinishSaga} from "./finish/postCheckInFinish.saga";
import {postCheckOutFinishSaga} from "./finish/postCheckOutFinish.saga";
import {generalDataSaga} from "./generalData/generalData.saga";
import {postKeyCardAdditionalReceivedSaga} from "./keyCards/postKeyCardAdditionalReceived.saga";
import {postKeyCardAdditionalWritingSaga} from "./keyCards/postKeyCardAdditionalWriting.saga";
import {postKeyCardFinishSaga} from "./keyCards/postKeyCardFinish.saga";
import {postKeyCardReceivedSaga} from "./keyCards/postKeyCardReceived.saga";
import {postKeyCardWritingSaga} from "./keyCards/postKeyCardWriting.saga";
import {getWebKeysSaga} from "./keyMobile/4Suits/getWebKeys.saga";
import {postOpenDoorSaga} from "./keyMobile/4Suits/postOpenDoor.saga";
import {postIssueMobileKeySaga} from "./keyMobile/postIssueMobileKey.saga";
import {postRegisterDeviceSaga} from "./keyMobile/postRegisterDevice.saga";
import {postPaymentInitiateSaga} from "./payment/postPaymentInitiate.saga";
import {postPaymentSplitSaga} from "./payment/postPaymentSplit.saga";
import {postIDDocumentSaga} from "./registration/postIDDocument.saga";
import {postSignatureCheckSaga} from "./registration/postSignatureCheck.saga";
import {saveRegistrationSaga} from "./registration/saveRegistration.saga";
import {sendSignatureSaga} from "./registration/sendSignature.saga";
import {setReservationDataSaga} from "./reservation/setReservationData.saga";
import {findBookingSaga} from "./searchBooking/findBooking.saga";
import {findBookingDetailsByGuidSaga} from "./searchBooking/findBookingDetailsByGuid.saga";
import {findBookingUrlSaga} from "./searchBooking/findBookingUrl.saga";
import {getLookupFieldsSaga} from "./searchBooking/getLookupFields.saga";
import {postLookupFieldsSaga} from "./searchBooking/postLookupFields.saga";

export function* rootSaga() {
  yield all([
    configSaga(),
    apiErrorSaga(),
    findBookingSaga(),
    findBookingDetailsByGuidSaga(),
    findBookingUrlSaga(),
    getLookupFieldsSaga(),
    postLookupFieldsSaga(),
    generalDataSaga(),
    apiErrorSaga(),
    goHomeSaga(),
    saveRegistrationSaga(),
    sendSignatureSaga(),
    postIDDocumentSaga(),
    postSignatureCheckSaga(),
    costOverviewFinishSaga(),
    postPaymentInitiateSaga(),
    setReservationDataSaga(),
    postPaymentSplitSaga(),
    postIssueMobileKeySaga(),
    getWebKeysSaga(),
    postRegisterDeviceSaga(),
    postKeyCardReceivedSaga(),
    postKeyCardAdditionalReceivedSaga(),
    postKeyCardAdditionalWritingSaga(),
    postKeyCardWritingSaga(),
    postKeyCardFinishSaga(),
    postOpenDoorSaga(),
    postCheckInFinishSaga(),
    postCheckOutFinishSaga(),
    apiErrorSaga(),
    covidCheckFinishSaga(),
    getCovidCheckStatusesSaga(),
    getCovidCheckGuestStatusSaga(),
    postCovidCheckSaga(),
  ]);
}
