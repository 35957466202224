import {useEffect, useState} from "react";

const useWindowSize = () => {
  const [isSmall, setIsSmall] = useState(window.innerWidth < 800);

  useEffect(() => {
    const updateSize = () => {
      setIsSmall(window.innerWidth < 800);
    };
    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return {isSmallDisplay: isSmall};
};

export {useWindowSize};
