import "normalize.css";
import "./index.scss";

import * as serviceWorker from "./serviceWorker";

import {Logger, init as initLogging} from "./logging";

import {App} from "./App";
import React from "react";
import ReactDOM from "react-dom";
import {defineCustomElements} from "@ionic/pwa-elements/loader";

initLogging();

ReactDOM.render(
  <React.StrictMode>
    <Logger>
      <App />
    </Logger>
  </React.StrictMode>,
  document.getElementById("root")
);

// Call the element loader after the app has been rendered the first time
defineCustomElements(window).then();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
