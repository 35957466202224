import {get, set} from "lodash";

import {createReducer} from "@reduxjs/toolkit";

import {
  APP_CONFIG_MAPPING,
  SET_APP_CONFIG,
  SET_COVID_CONFIGURATION,
  SET_PROCESS_CONFIGURATION,
  SET_PROCESS_OVERVIEW,
} from "../../../constants";
import {getUpdateObjectFn} from "../../../utils/state.utils";

let initialState = {
  siteSettings: {
    id: undefined,
    name: undefined,
    currency: undefined,
    address: {
      country: undefined,
    },
    contact: {
      email: undefined,
      phone: undefined,
      website: undefined,
    },
  },
  process: {
    allowBillingAddress: undefined,
    allowAdditionalGuests: undefined,
    enableAutomaticBillingAddress: undefined,
    documentScanning: undefined,
  },
  processOverview: {
    showKeysInfoAtCheckIn: undefined,
    showKeysInfoAtCheckOut: undefined,
    trKeysInfoCheckIn: undefined,
    trKeysInfoCheckOut: undefined,
    additionalInfo: [],
  },
  covid: {
    allowContinuationWithoutProof: undefined,
  },
  startPageLayout: [],
  configuration: {
    device: {id: undefined},
    inactivityTimeout: undefined,
  },
};

const appConfigReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_APP_CONFIG, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      const startPageLayout = get(
        action.payload,
        APP_CONFIG_MAPPING.START_PAGE_LAYOUT.apiKey,
        []
      ).map((widget) => {
        let mappedWidget = {};
        const updateWidget = getUpdateObjectFn(widget, mappedWidget);

        updateWidget(APP_CONFIG_MAPPING.START_PAGE_LAYOUT.TEMPLATE);
        updateWidget(APP_CONFIG_MAPPING.START_PAGE_LAYOUT.LEFT);
        updateWidget(APP_CONFIG_MAPPING.START_PAGE_LAYOUT.TOP);
        updateWidget(APP_CONFIG_MAPPING.START_PAGE_LAYOUT.WIDTH);
        updateWidget(APP_CONFIG_MAPPING.START_PAGE_LAYOUT.HEIGHT);
        updateWidget(APP_CONFIG_MAPPING.START_PAGE_LAYOUT.TEXT_KEY);
        updateWidget(APP_CONFIG_MAPPING.START_PAGE_LAYOUT.DEFAULT_TEXT);
        updateWidget(APP_CONFIG_MAPPING.START_PAGE_LAYOUT.URL);
        updateWidget(APP_CONFIG_MAPPING.START_PAGE_LAYOUT.IMAGE);

        return mappedWidget;
      });

      set(state, APP_CONFIG_MAPPING.START_PAGE_LAYOUT.uiKey, startPageLayout);

      updateState(APP_CONFIG_MAPPING.CONFIGURATION.DEVICE);
      updateState(APP_CONFIG_MAPPING.CONFIGURATION.INACTIVITY_TIMEOUT);

      updateState(APP_CONFIG_MAPPING.SITE_SETTINGS.ID);
      updateState(APP_CONFIG_MAPPING.SITE_SETTINGS.NAME);
      updateState(APP_CONFIG_MAPPING.SITE_SETTINGS.CURRENCY);
      updateState(APP_CONFIG_MAPPING.SITE_SETTINGS.COUNTRY);
      updateState(APP_CONFIG_MAPPING.SITE_SETTINGS.EMAIL);
      updateState(APP_CONFIG_MAPPING.SITE_SETTINGS.PHONE);
      updateState(APP_CONFIG_MAPPING.SITE_SETTINGS.WEBSITE);
    })
    .addCase(SET_PROCESS_CONFIGURATION, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      updateState(APP_CONFIG_MAPPING.PROCESS_CONFIGURATION.ALLOW_COMPANY_ADDRESS);
      updateState(APP_CONFIG_MAPPING.PROCESS_CONFIGURATION.ALLOW_ADDITIONAL_GUESTS);
      updateState(
        APP_CONFIG_MAPPING.PROCESS_CONFIGURATION.NO_PMS_UPDATE_OF_INVOICE_ADDRESS
      );
      updateState(APP_CONFIG_MAPPING.PROCESS_CONFIGURATION.DOCUMENT_SCANNING);
    })
    .addCase(SET_COVID_CONFIGURATION, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      updateState(
        APP_CONFIG_MAPPING.COVID_CONFIGURATION.ALLOW_CONTINUATION_WITHOUT_PROOF
      );
    })
    .addCase(SET_PROCESS_OVERVIEW, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      updateState(APP_CONFIG_MAPPING.PROCESS_OVERVIEW.SHOW_KEYS_INFO_AT_CHECK_IN);
      updateState(APP_CONFIG_MAPPING.PROCESS_OVERVIEW.SHOW_KEYS_INFO_AT_CHECK_OUT);
      updateState(APP_CONFIG_MAPPING.PROCESS_OVERVIEW.TR_KEYS_INFO_CHECK_IN);
      updateState(APP_CONFIG_MAPPING.PROCESS_OVERVIEW.TR_KEYS_INFO_CHECK_OUT);

      const additionalInfo = get(
        action.payload,
        APP_CONFIG_MAPPING.PROCESS_OVERVIEW.ADDITIONAL_INFO.apiKey,
        []
      ).map((info) => {
        const mappedInfo = {};

        const updateAdditionalInfo = getUpdateObjectFn(info, mappedInfo);

        updateAdditionalInfo(APP_CONFIG_MAPPING.PROCESS_OVERVIEW.ADDITIONAL_INFO.POP_UP);
        updateAdditionalInfo(
          APP_CONFIG_MAPPING.PROCESS_OVERVIEW.ADDITIONAL_INFO.SHOW_AT_CHECKIN
        );
        updateAdditionalInfo(
          APP_CONFIG_MAPPING.PROCESS_OVERVIEW.ADDITIONAL_INFO.SHOW_AT_CHECKOUT
        );
        updateAdditionalInfo(
          APP_CONFIG_MAPPING.PROCESS_OVERVIEW.ADDITIONAL_INFO.TRANSLATION_CONTENT_KEY
        );
        updateAdditionalInfo(
          APP_CONFIG_MAPPING.PROCESS_OVERVIEW.ADDITIONAL_INFO.TRANSLATION_TITLE_KEY
        );
        updateAdditionalInfo(
          APP_CONFIG_MAPPING.PROCESS_OVERVIEW.ADDITIONAL_INFO.UNFOLDED_TEXT_BOX
        );

        return mappedInfo;
      });

      set(
        get(state, APP_CONFIG_MAPPING.PROCESS_OVERVIEW.uiKey),
        APP_CONFIG_MAPPING.PROCESS_OVERVIEW.ADDITIONAL_INFO.uiKey,
        additionalInfo
      );
    });
});

export {appConfigReducer};
