const translations = {
  form: {
    address: "Address",
    salutation: "Salutation",
    gender: "Gender",
    firstName: "First Name",
    lastName: "Last Name",
    streetNum: "Street / Number",
    postalCode: "ZIP",
    city: "City",
    country: "Country",
    companyName: "Company name",
    department: "Department",

    guestData: "Guest Data",
    recipient: "Recipient",
    dateOfBirth: "Date of birth",
    citizenShip: "Nationality",
    identificationDocumentTypeCode: "Identification Document",
    identificationDocumentNumber: "Passport / ID Card Number",
    idCard: "ID Card",
    passport: "Passport",
    identificationDocumentAuthority: "Issuing authority",
    identificationDocumentDateOfIssue: "Date of issue",

    additionalGuests: "Additional Guests",
    additionalGuest: "Additional Guest",
    addAdditionalGuest: "Add more Guests",

    contactSection: "Contact",
    contactEmail: "E-Mail Address",
    phone: "Phone Number",
    licensePlate: "License Plate",
    additionalInformationSection: "Additional information",
    reasonForTravel: "Travel Type",
    billingAddressSection: "Billing Address",
    addressType: "Address Type",
    individualAddress: "Individual",
    companyAddress: "Company",
    billingAddressIsPrivate: "Use my private address as the billing address",

    billingAddressItemsInfo: "Which Items should be booked on the Billing Address?",
    all: "All",
    accommodation: "Accommodation",
    breakfast: "Breakfast",
    furtherFoodAndDrinks: "Further Food & Drinks",
    other: "Other",
    otherDescription: "Write use shortly how you need your Invoice to be splitted",

    mandatoryByGDPR: "** Mandatory field, based on §§ 6.1 b,f GDPR",
    mandatory: "Mandatory fields - please fill in.",
    mandatoryField: "* Mandatory field",

    externalReservationCode: "Booking number",
    arrival: "Arrival date",
    departure: "Departure date",
    chooseArrival: "Choose Arrival Date",
    chooseDeparture: "Choose Departure Date",
    private: "Private",
    business: "Business",
    pleaseFillIn: "Please fill in",
    invalidDate: "Please enter a valid date.",

    idScanner: {
      scanInfo: "Only Scanned, not downloaded, not stored.",
      autoFillInfo: "Auto-fill with ID / Passport Scan",
      autoFillOptional: "*Auto-fill is optional",
      fillManually: "Please complete the form",
    },
  },
};

export {translations};
