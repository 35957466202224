import {
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import {POST_CHECKOUT_FINISH__SAGA} from "../../../constants";
import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  setReservationDataSagaAction,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postCheckOutFinish() {
  try {
    let store = yield select();

    let {code, name} = store.reservationReducer;

    const client = yield* getClientTypeFn();
    const checkOutFinishResponse = yield call([client, "postFlowFinish"], code, name);

    yield put(setReservationDataSagaAction({response: checkOutFinishResponse}));
  } catch (error) {
    yield put(apiErrorSagaAction({error: error, isAllowedRetry: true}));
  } finally {
    yield put(hideLoadingOverlay());
  }
}

export function* postCheckOutFinishSaga() {
  yield takeLatest(POST_CHECKOUT_FINISH__SAGA, postCheckOutFinish);
}
