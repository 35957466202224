const translations = {
  finish: {
    checkIn: {
      title: "Fast geschafft!",
      subtitle: "Alle Daten ausgefüllt.",
      stepsCompleted: "Alle Daten abgeschlossen",
      confirmText: "Jetzt einchecken",
      confirmWithKeys: "Jetzt einchecken und Ihre Schlüssel erhalten",
      inProgress: "Einen Moment bitte... \n\nSie werden eingecheckt...",
      reservationData: "Reservierungsdaten bestätigt",
      guestData: "Gastdaten vollständig",
      enjoy: "Genießen Sie Ihren Aufenthalt!",
    },
    checkOut: {
      title: "Ein letzter Schritt zum Check-Out",
      checkOutNow: "Jetzt auschecken",
      yesCheckOut: "Ja, auschecken",
      checkOutLater: "Nein, später fortfahren",
      subtitle: "Alle Daten ausgefüllt.",
      stepsCompleted: "Alle Schritte abgeschlossen",
      costOverviewChecked: "Kostenübersicht überprüft",
      paymentCompleted: "Bezahlung abgeschlossen",
      youSure: "Möchten Sie jetzt auschecken?",
      inProgress: "Einen Moment bitte... \n\nSie werden ausgecheckt...",
      hopeYouEnjoyed: "Wir hoffen, Sie hatten einen angenehmen Aufenthalt!",
      looseAccess:
        "Nach dem Check-Out verlieren Sie den Zugang zu den mobilen Schlüsseln",
      takeBelongings:
        "Bitte nehmen Sie all Ihr Gepäck aus dem Zimmer mit, da Sie danach keinen Zugang mehr haben werden.",
    },
  },
};

export {translations};
