import {
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import {POST_SIGNATURE_CHECK__SAGA} from "../../../constants";
import {apiErrorSagaAction} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postSignatureCheck(action) {
  const {resolve, reject} = action.payload;

  try {
    let store = yield select(),
      {code, name} = store.reservationReducer;

    const client = yield* getClientTypeFn();
    const signatureCheckResponse = yield call([client, "postSignatureCheck"], code, name);

    if (resolve) {
      resolve(signatureCheckResponse);
    }
  } catch (error) {
    yield put(apiErrorSagaAction({error: error, isAllowedRetry: true}));

    if (reject) {
      reject();
    }
  }
}

export function* postSignatureCheckSaga() {
  yield takeLatest(POST_SIGNATURE_CHECK__SAGA, postSignatureCheck);
}
