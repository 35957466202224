export const handleTerminalInputFocus = (event) => {
  addKeyboardPadding();

  const animationDuration = 500;

  setTimeout(() => {
    adjustVerticalScrollToInput(event);
  }, animationDuration);
};

export const handleTerminalInputBlur = () => {
  removeKeyboardPadding();
};

export const removeKeyboardPadding = () => {
  document.querySelector("body").classList.remove("keyboard-padding");
};

export const adjustVerticalScrollToInput = (event, inputHeight) => {
  const input = event?.target || event;
  const inputPosition = input.getBoundingClientRect();
  const page = document.querySelector(".page");
  const inputBottom = inputPosition.top + (inputHeight || inputPosition.height);

  if (!page) {
    return;
  }

  const {height: visibleWindowHeight, top: visibleWindowTop} =
    page.getBoundingClientRect();

  if (inputBottom > visibleWindowHeight || inputPosition.top < visibleWindowTop) {
    const topPos =
      page.scrollTop +
      inputPosition.top -
      (visibleWindowHeight - (inputHeight || inputPosition.height)) / 2 -
      visibleWindowTop;

    page.scrollTo({
      behavior: "smooth",
      top: topPos,
      left: page.pageXOffset,
    });
  }
};

export const addKeyboardPadding = () => {
  document.querySelector("body").classList.add("keyboard-padding");
};

export const attachTerminalInputEventListeners = () => {
  const inputs = Array.from(document.getElementsByTagName("INPUT"))?.filter(
    (input) => !input.closest(".dropdown")
  );

  for (let i = 0; i < inputs?.length; i++) {
    inputs[i].removeEventListener("focus", handleTerminalInputFocus);
    inputs[i].addEventListener("focus", handleTerminalInputFocus);
    inputs[i].removeEventListener("blur", handleTerminalInputBlur);
    inputs[i].addEventListener("blur", handleTerminalInputBlur);
  }
};
