const translations = {
  qrCode: {
    title: "QR-Code scannen",
    startScan: "Scan Starten",
    stopScan: "Scan Stoppen",
    msg:
      "Ihr Gerät unterstützt das Schreiben einer Zugangskarte nicht. Bitte gehen Sie zu einem der Check-In-Terminals, um Ihre Zugangskarte zu erhalten.",
    info: "Sie können den QR-Code nutzen um den Vorgang am Terminal zu beschleunigen.",
    scanSuccessful: "Scan erfolgreich",
    hint: "Bitte richten Sie die Kamera auf den QR-Code",
    additionalHint: "Tippen Sie auf den Play Button falls die Kamera nicht lädt",
  },
};

export {translations};
