const translations = {
  date: {
    dateFormat: "DD/MM/YYYY",
    timeFormat: "hh:mm",
    dateTimeWithSeparator: "DD/MM/YYYY {{separator}} h:mm a",
    dateMMM: "Do MMM YYYY",
    day: "Day",
    month: "Month",
    year: "Year",
    clock: "",
    from: "From",
    till: "Till",
    invalid: "Invalid date",
  },
};

export {translations};
