import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  setReservationDataSagaAction,
  showLoadingOverlay,
} from "../../actions";
import {call, put, select, takeEvery} from "redux-saga/effects";

import {COST_OVERVIEW_FINISH__SAGA} from "../../../constants";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* costOverviewFinish() {
  const store = yield select(),
    reservation = store.reservationReducer,
    {code, name} = reservation;
  yield put(showLoadingOverlay());

  try {
    const client = yield* getClientTypeFn();
    const costOverviewFinishResponse = yield call(
      [client, "costOverviewFinish"],
      code,
      name
    );

    yield put(setReservationDataSagaAction({response: costOverviewFinishResponse}));
  } catch (e) {
    yield put(hideLoadingOverlay());
    yield put(apiErrorSagaAction({error: e}));
  }
}

export function* costOverviewFinishSaga() {
  yield takeEvery(COST_OVERVIEW_FINISH__SAGA, costOverviewFinish);
}
