import {useEffect} from "react";

export const useOutsideAlert = (ref, exceptionRef, action) => {
  useEffect(() => {
    /**
     * if clicked outside of element
     */
    const handleClickOutside = (event) => {
      // Exception element which will not trigger the action
      if (
        !(
          exceptionRef &&
          exceptionRef.current &&
          exceptionRef.current.contains(event.target)
        )
      ) {
        if (ref.current && !ref.current.contains(event.target)) {
          action();
        }
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, action, exceptionRef]);
};
