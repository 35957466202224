/* eslint-disable no-restricted-globals */

import {matchPath} from "react-router-dom";

import {
  FLOWS,
  MODULE_TYPES,
  ROUTE_PATHS,
  STEPS,
} from "../constants";

export const checkInRules = {
  RESERVATION_OVERVIEW: {
    apiStepsToMatch: [STEPS.GUEST_REGISTRATION],
    urlPathsToMatch: [ROUTE_PATHS.RESERVATION_OVERVIEW.PATHNAME],
  },
  REGISTRATION: {
    apiStepsToMatch: [
      STEPS.GUEST_REGISTRATION,
      STEPS.SIGNATURE,
      STEPS.COVID_QR_CODE_SCAN,
    ],
    urlPathsToMatch: [
      ROUTE_PATHS.GUEST_REGISTRATION.CHILDREN.ADDRESS.PATHNAME,
      ROUTE_PATHS.GUEST_REGISTRATION.CHILDREN.MORE_DATA.PATHNAME,
      ROUTE_PATHS.PERSONAL_DATA.PATHNAME,
      ROUTE_PATHS.SIGNATURE.PATHNAME,
      ROUTE_PATHS.COVID_QR_CODE_SCAN.PATHNAME,
    ],
  },
  PAYMENT: {
    apiStepsToMatch: [STEPS.PAYMENT, STEPS.COST_OVERVIEW],
    urlPathsToMatch: null,
  },
  CHECKIN_FINISH: {
    apiStepsToMatch: [STEPS.CHECKIN_FINISH],
    urlPathsToMatch: null,
  },
  KEY_ENCODING: {
    apiStepsToMatch: [STEPS.KEY_ENCODING],
    urlPathsToMatch: null,
  },
  SUMMARY: {
    apiStepsToMatch: [
      STEPS.CHECKIN_SUMMARY,
      STEPS.PRE_CHECKIN_SUMMARY,
      STEPS.REGISTRATION_FORM_COMPLETED,
    ],
    urlPathsToMatch: null,
  },
};

export const checkOutRules = {
  PAYMENT: {
    apiStepsToMatch: [STEPS.PAYMENT, STEPS.COST_OVERVIEW],
    urlPathsToMatch: null,
  },
  CHECKOUT_FINISH: {
    apiStepsToMatch: [STEPS.CHECKOUT_FINISH],
    urlPathsToMatch: null,
  },
  CHECKOUT_SUMMARY: {
    apiStepsToMatch: [STEPS.CHECKOUT_SUMMARY],
    urlPathsToMatch: null,
  },
};

export const isStepInFlow = (flow, step) => {
  switch (flow) {
    case FLOWS.PRE_CHECK_IN:
    case FLOWS.CHECK_IN:
      return Object.values(checkInRules).some((rule) =>
        rule.apiStepsToMatch?.includes(step)
      );
    case FLOWS.CHECK_OUT:
      return Object.values(checkOutRules).some((rule) =>
        rule.apiStepsToMatch?.includes(step)
      );
    default:
      return false;
  }
};

export const getUrl = (subRoutePathname) => {
  const {bookingId, bookingName, type} = getBookingDetailsFromUrl();

  const startingPath = `/${type}/${bookingId}/${bookingName}`;

  for (const ROUTE of Object.values(ROUTE_PATHS)) {
    if (ROUTE.PATHNAME === subRoutePathname) {
      return startingPath + subRoutePathname;
    }

    const childCorrespondingToSubRoute =
      ROUTE.CHILDREN &&
      Object.values(ROUTE.CHILDREN).find(
        (childRoute) => childRoute.PATHNAME === subRoutePathname
      );

    if (childCorrespondingToSubRoute != null) {
      return startingPath + ROUTE.PATHNAME + subRoutePathname;
    }
  }

  return startingPath;
};

export const getBookingDetailsFromUrl = () => {
  const match = matchPath(window.location.pathname, {
    path: "/:type/:bookingId/:bookingName",
    exact: false,
    strict: false,
  });

  const {bookingId, bookingName, type} = match
    ? match.params
    : {bookingId: null, bookingName: null, type: null};

  return {bookingId, bookingName, type, match};
};

export const getStepFromUrl = () => {
  const match = matchPath(window.location.pathname, {
    path: "/:type/:bookingId/:bookingName/:step",
    exact: false,
    strict: false,
  });

  const {step} = match ? match.params : {step: null};

  return {step};
};

export const getModuleType = (shouldAvoidDefault = false) => {
  const match = matchPath(window.location.pathname, {
    path: "/:type",
    exact: false,
    strict: false,
  });

  const {type} = match
    ? match.params
    : {type: shouldAvoidDefault ? null : MODULE_TYPES.CHECK_IN};

  return type;
};

export const checkDeviceIdQueryParam = () => {
  const query = new URLSearchParams(window.location.search);
  let deviceId = query.get("device-id") || query.get("mac-address");

  if (!!deviceId) {
    try {
      localStorage.setItem("deviceId", deviceId);
    } catch (_) {
      // accept it.
    }
  }
};

export const getLanguageQueryParam = () => {
  const query = new URLSearchParams(window.location.search);
  return query.get("lng");
};

const getStoredBaseUrl = () => {
  try {
    return localStorage.getItem("baseUrl");
  } catch {
    return "";
  }
};

export const getStoredDeviceId = () => {
  try {
    return localStorage.getItem("deviceId");
  } catch {
    return "";
  }
};

export const getBaseUrl = () => {
  if (process.env.REACT_APP_DEVICE === "web") {
    return "/";
  }

  return process.env.REACT_APP_BASE_URL;
};

export const getBaseUrlFromIframe = () => {
  if (window !== parent) {
    return getStoredBaseUrl();
  }

  return window.location.origin;
};
