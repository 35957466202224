import "./CollapsedSection.scss";

import React, {
  useEffect,
  useRef,
  useState,
} from "react";

import PropTypes from "prop-types";

import {ReactComponent as ArrowDown} from "../../assets/images/Icon_arrow_down.svg";
import {ReactComponent as ArrowUp} from "../../assets/images/Icon_arrow_top.svg";
import {useWindowSize} from "../../utils/customHooks/windowResize.hook";

const CollapsedSection = ({title, children, isDemarcated = false}) => {
  const {isSmallDisplay} = useWindowSize();
  const [isOpen, setIsOpen] = useState(!isSmallDisplay && isDemarcated);
  const collapsedTextRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setHeight(collapsedTextRef.current?.getBoundingClientRect().height);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const toggleCollapsedSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`collapsed-section ${
        isDemarcated ? "collapsed-section--demarcated" : ""
      }`}>
      <div
        className="collapsed-section__heading"
        onClick={() => toggleCollapsedSection()}>
        <div className="heading-title">{title}</div>
        {isOpen ? (
          <ArrowUp className="heading-icon" />
        ) : (
          <ArrowDown className="heading-icon" />
        )}
      </div>
      <div className="collapsed-section__body" style={{height}}>
        <div ref={collapsedTextRef}>{children}</div>
      </div>
    </div>
  );
};

CollapsedSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isDemarcated: PropTypes.bool,
  children: PropTypes.any,
};

export {CollapsedSection};
