import {get} from "lodash";
import {
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import {
  COVID_MAPPING,
  POST_COVID_CHECK__SAGA,
} from "../../../constants";
import {
  getCovidCheckStatusesSagaAction,
  hideLoadingOverlay,
  showLoadingOverlay,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postCovidCheck(action) {
  const {resolve, reject, qrCode, guestId} = action.payload || {};
  yield put(showLoadingOverlay());

  try {
    let store = yield select(),
      {code, name} = store.reservationReducer;

    const client = yield* getClientTypeFn();
    const covidCheckResponse = yield call(
      [client, "postCovidCheck"],
      code,
      name,
      guestId,
      qrCode
    );

    yield put(hideLoadingOverlay());

    const covidVerificationStatus = get(
      covidCheckResponse.data,
      COVID_MAPPING.STATUS.apiKey
    );

    yield put(getCovidCheckStatusesSagaAction());

    if (resolve) {
      resolve(covidVerificationStatus);
    }
  } catch (error) {
    yield put(hideLoadingOverlay());

    if (reject) {
      reject();
    }
  }
}

export function* postCovidCheckSaga() {
  yield takeLatest(POST_COVID_CHECK__SAGA, postCovidCheck);
}
