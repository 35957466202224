import {
  put,
  select,
  takeLeading,
} from "redux-saga/effects";

import {
  FIND_BOOKING__SAGA,
  NOTIFICATION_TYPES,
} from "../../../constants";
import {
  getIsPersistentByApiErrorCode,
  getNotificationKeyByApiErrorCode,
  NOTIFICATION_MAPPINGS,
} from "../../../utils/notification.utils";
import {getModuleType} from "../../../utils/route.utils";
import {
  hideLoadingOverlay,
  purge,
  setIsCheckinViewed,
  setIsCheckoutViewed,
  setNotification,
} from "../../actions";
import {findBookingFn} from "./findBooking.generatorFunction";

function* findBooking(action) {
  const {bookingId, bookingName, reject, errorKey, errorType} = action.payload;

  const type = getModuleType();

  try {
    let store = yield select();

    const lastReservation = store.reservationReducer,
      {
        code: lastCode,
        name: lastName,
        isCheckinViewed,
        isCheckoutViewed,
      } = lastReservation;

    yield put(purge({whiteList: ["router", "appConfigReducer", "generalReducer"]}));

    yield* findBookingFn({bookingId, bookingName, type});

    store = yield select();

    const reservation = store.reservationReducer,
      {code, name} = reservation;

    if (lastCode === code && lastName === name) {
      if (isCheckinViewed) {
        yield put(setIsCheckinViewed());
      }
      if (isCheckoutViewed) {
        yield put(setIsCheckoutViewed());
      }
    }
  } catch (e) {
    yield put(hideLoadingOverlay());
    yield put(
      setNotification({
        type: errorType || NOTIFICATION_TYPES.ERROR,
        messageKey:
          errorKey ||
          getNotificationKeyByApiErrorCode(
            e.response?.data?.errorCode ||
              NOTIFICATION_MAPPINGS.GENERAL.TECHNICAL.ERROR_CODE
          ),
        isPersistent: getIsPersistentByApiErrorCode(
          e.response?.data?.errorCode ||
            NOTIFICATION_MAPPINGS.GENERAL.TECHNICAL.ERROR_CODE
        ),
      })
    );

    if (reject) {
      reject(e.response?.data);
    }
  }
}

export function* findBookingSaga() {
  yield takeLeading(FIND_BOOKING__SAGA, findBooking);
}
