const translations = {
  signature: {
    title: "Unterschreiben Sie zur Bestätigung Ihrer Informationen",
    subtitle:
      "Bitte bestätigen Sie Ihre persönlichen Daten mit Ihrer Unterschrift. Nutzen Sie Ihren Finger oder die Maus zum unterzeichnen.",
  },
  ccSignature: {
    title: "Identitätsnachweis",
    info:
      "Bitte bestätigen Sie Ihre persönlichen Daten mit Secure Customer Authentication",
  },
};

export {translations};
