const translations = {
  general: {
    checkIn: "Check-In",
    checkOut: "Check-Out",
    preCheckIn: "Pre Check-In",
    confirm: "Confirm",
    next: "Next",
    startpage: "Back to Home Screen",
    back: "Back",
    privacyPolicy: "Privacy Policy",
    privacyPolicyScan: "Privacy Policy - scan QR Code below to learn more:",
    leaveProcess: "Leave Process",

    later: "Not now",
    save: "Save",
    remove: "Remove",
    add: "Add",
    edit: "Edit",
    editData: "Edit Data",
    cancel: "Cancel",
    continue: "Continue",
    momentPlease: "One moment please...",
    tryAgain: "Try again",
    clickHere: "Click here",

    confirmGoHomePopupTitle: "Are you sure you want to leave the process now?",
    confirmGoHomePopupContent:
      "You will be redirected to the Home Screen. Your data will be saved, but you will have to search your booking again to continue.",
    leaveAndGoToHomeScreen: "Leave & go to Home Screen",
    backToProcess: "Back to process",

    updateAvailableTitle: "Update available",
    updateAvailableContent:
      "A new version of this application is available, please reload the page in order to use it.",
    updateAvailableButton: "Reload",
  },
};

export {translations};
