import i18next from "i18next";
import {get, set} from "lodash";
import {call, put, select, takeEvery} from "redux-saga/effects";

import {REGISTRATION_MAPPING, SAVE_REGISTRATION__SAGA} from "../../../constants";
import {ADDRESS_TYPE} from "../../../domains/BillingAddress/billingAddress.constants";
import {getUpdateObjectFn} from "../../../utils/state.utils";
import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  setReservationDataSagaAction,
  showLoadingOverlay,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* saveRegistration() {
  try {
    let store = yield select();

    let {code, name} = store.reservationReducer,
      registrationData = store.registrationReducer;

    yield put(showLoadingOverlay(i18next.t("personalData:processingSave")));

    if (
      code &&
      code.trim().length > 0 &&
      name &&
      name.trim().length > 0 &&
      registrationData &&
      Object.keys(registrationData).some(Boolean)
    ) {
      const client = yield* getClientTypeFn();

      let apiRegistrationData = {};

      const updateRegistration = getUpdateObjectFn(
        registrationData,
        apiRegistrationData,
        true
      );

      updateRegistration(REGISTRATION_MAPPING.ARRIVAL_DATE);
      updateRegistration(REGISTRATION_MAPPING.DEPARTURE_DATE);
      updateRegistration(REGISTRATION_MAPPING.TRAVEL_PURPOSE);

      const uiGuest = get(registrationData, REGISTRATION_MAPPING.GUEST.uiKey, {});
      const apiGuest = {};
      const updateGuest = getUpdateObjectFn(uiGuest, apiGuest, true);

      updateGuest(REGISTRATION_MAPPING.GUEST.SALUTATION);
      updateGuest(REGISTRATION_MAPPING.GUEST.SALUTATION_UUID);
      updateGuest(REGISTRATION_MAPPING.GUEST.GENDER);
      updateGuest(REGISTRATION_MAPPING.GUEST.FIRST_NAME);
      updateGuest(REGISTRATION_MAPPING.GUEST.LAST_NAME);
      updateGuest(REGISTRATION_MAPPING.GUEST.LICENSE_PLATE);
      updateGuest(REGISTRATION_MAPPING.GUEST.STREET);
      updateGuest(REGISTRATION_MAPPING.GUEST.POSTAL_CODE);
      updateGuest(REGISTRATION_MAPPING.GUEST.CITY);
      updateGuest(REGISTRATION_MAPPING.GUEST.COUNTRY_CODE);
      updateGuest(REGISTRATION_MAPPING.GUEST.DATE_OF_BIRTH);
      updateGuest(REGISTRATION_MAPPING.GUEST.CITIZENSHIP_CODE);
      updateGuest(REGISTRATION_MAPPING.GUEST.EMAIL);
      updateGuest(REGISTRATION_MAPPING.GUEST.PHONE);
      updateGuest(REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_NUMBER);
      updateGuest(REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_TYPE_CODE);
      updateGuest(REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE);
      updateGuest(REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_AUTHORITY);
      updateGuest(REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_IMAGE);

      set(apiRegistrationData, REGISTRATION_MAPPING.GUEST.apiKey, apiGuest);

      const stateInvoiceAddress = get(
        registrationData,
        REGISTRATION_MAPPING.INVOICE_ADDRESS.uiKey,
        null
      );
      const isInvoiceAddressDefined =
        stateInvoiceAddress && Object.values(stateInvoiceAddress).some(Boolean);
      const invoiceAddress = isInvoiceAddressDefined ? {} : null;

      if (isInvoiceAddressDefined) {
        const updateInvoiceAddress = getUpdateObjectFn(
          stateInvoiceAddress,
          invoiceAddress,
          true
        );

        updateInvoiceAddress(REGISTRATION_MAPPING.INVOICE_ADDRESS.COUNTRY_CODE);
        updateInvoiceAddress(REGISTRATION_MAPPING.INVOICE_ADDRESS.ADDRESS_TYPE);
        updateInvoiceAddress(REGISTRATION_MAPPING.INVOICE_ADDRESS.RECIPIENT);
        updateInvoiceAddress(REGISTRATION_MAPPING.INVOICE_ADDRESS.STREET);
        updateInvoiceAddress(REGISTRATION_MAPPING.INVOICE_ADDRESS.POSTAL_CODE);
        updateInvoiceAddress(REGISTRATION_MAPPING.INVOICE_ADDRESS.CITY);
        if (
          stateInvoiceAddress[REGISTRATION_MAPPING.INVOICE_ADDRESS.ADDRESS_TYPE.uiKey] ===
          ADDRESS_TYPE.COMPANY
        ) {
          updateInvoiceAddress(REGISTRATION_MAPPING.INVOICE_ADDRESS.COMPANY_NAME);
          updateInvoiceAddress(REGISTRATION_MAPPING.INVOICE_ADDRESS.DEPARTMENT);
        }
        updateInvoiceAddress(REGISTRATION_MAPPING.INVOICE_ADDRESS.ITEMS);
      }

      set(
        apiRegistrationData,
        REGISTRATION_MAPPING.INVOICE_ADDRESS.apiKey,
        invoiceAddress
      );

      updateRegistration(REGISTRATION_MAPPING.ACCOMPANYING_GUEST_COUNT);
      updateRegistration(REGISTRATION_MAPPING.TERMS_AND_CONDITIONS_ACCEPTED);

      const accompanyingGuests = get(
        registrationData,
        REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.uiKey,
        []
      ).map((accompanyingGuest) => {
        const mappedGuest = {};

        const updateAdditionalGuest = getUpdateObjectFn(
          accompanyingGuest,
          mappedGuest,
          true
        );

        updateAdditionalGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.UUID);
        updateAdditionalGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.SALUTATION_UUID);
        updateAdditionalGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.GENDER);
        updateAdditionalGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.FIRST_NAME);
        updateAdditionalGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.LAST_NAME);
        updateAdditionalGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.DATE_OF_BIRTH);
        updateAdditionalGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.PHONE);
        updateAdditionalGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.CITIZENSHIP_CODE);
        updateAdditionalGuest(
          REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.IDENTIFICATION_DOCUMENT_NUMBER
        );
        updateAdditionalGuest(
          REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.IDENTIFICATION_DOCUMENT_IMAGE
        );
        updateAdditionalGuest(
          REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.IDENTIFICATION_DOCUMENT_TYPE_CODE
        );
        updateAdditionalGuest(
          REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE
        );

        return mappedGuest;
      });

      set(
        apiRegistrationData,
        REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.apiKey,
        accompanyingGuests
      );

      const billingAddressChanged = get(
        registrationData,
        REGISTRATION_MAPPING.BILLING_ADDRESS_CHANGED.uiKey,
        false
      );

      set(
        apiRegistrationData,
        REGISTRATION_MAPPING.BILLING_ADDRESS_CHANGED.apiKey,
        billingAddressChanged
      );

      const registrationResp = yield call([client, "saveRegistration"], code, name, {
        Form: {
          ...apiRegistrationData,
        },
      });

      yield put(setReservationDataSagaAction({response: registrationResp}));
    } else {
      throw new Error();
    }
  } catch (e) {
    yield put(hideLoadingOverlay());
    yield put(apiErrorSagaAction({error: e, isAllowedRetry: true}));
  }
}

export function* saveRegistrationSaga() {
  yield takeEvery(SAVE_REGISTRATION__SAGA, saveRegistration);
}
