import {applyMiddleware, compose, createStore} from "redux";
import {persistReducer, persistStore} from "redux-persist";

import {DESTROY_SESSION} from "../constants";
import {createBrowserHistory} from "history";
import createSagaMiddleware from "redux-saga";
import {middleware as loggerMiddleware} from "../logging";
import {reducers} from "./reducers/reducers";
import {rootSaga} from "./sagas";
import {routerMiddleware} from "connected-react-router";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

export const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["loadingOverlayReducer", "notificationsReducer"],
};

const rootReducer = (state, action) => {
  const processedState = getProcessedState(state, action);

  return reducers(processedState, action);
};

const getProcessedState = (state, action) => {
  switch (action.type) {
    case DESTROY_SESSION: {
      let newState = {};

      const shouldPreserveSomeReducers =
        action.payload.whiteList && action.payload.whiteList.length > 0;

      if (shouldPreserveSomeReducers) {
        action.payload.whiteList.forEach((reducer) => {
          if (state.hasOwnProperty(reducer)) {
            newState[reducer] = state[reducer];
          }
        });
      }

      return newState;
    }
    default: {
      return state;
    }
  }
};

const persistedReducer = persistReducer(persistConfig, rootReducer),
  sagaMiddleware = createSagaMiddleware(),
  composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25})) ||
    compose,
  store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        compose(loggerMiddleware, sagaMiddleware)
      )
    )
  );

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export {store, persistor};
