const translations = {
  stepper: {
    overview: "Overview",
    guest_registration: "Guest data",
    signature: "Signature",
    payment: "Payment",
    issue_key: "Key",
    checkin_finish: "Complete check-in",
    checkout_finish: "Complete check-out",
    pre_checkin_summary: "Summary",
    checkin_summary: "Summary",
    checkout_summary: "Summary",
  },
};

export {translations};
