import {GET_WEB_KEY__SAGA, MOBILE_CARD_KEYS_MAPPING} from "../../../../constants";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {get, isNil} from "lodash";

import {getClientTypeFn} from "../../utils/getClientType.generatorFunction";
import {setWebKeys} from "../../../actions";

function* getWebKeys(action) {
  const {resolve = null, reject = null} = action.payload;

  try {
    const store = yield select(),
      {mobile} = store.keysReducer,
      {key = "", parameters = {}} = mobile,
      {accessibleDoors = ""} = parameters;

    if (!isNil(accessibleDoors) && accessibleDoors.length > 0 && key && key.length > 0) {
      const client = yield* getClientTypeFn();
      const webKeysResponse = yield call([client, "getDoors"], accessibleDoors, key);

      yield put(
        setWebKeys(
          get(webKeysResponse.data, MOBILE_CARD_KEYS_MAPPING.MOBILE.WEB_KEYS.apiKey)
        )
      );

      if (resolve) {
        resolve();
      }
    } else {
      if (reject) {
        reject();
      }
    }
  } catch (e) {
    if (reject) {
      reject();
    }
  }
}

export function* getWebKeysSaga() {
  yield takeEvery(GET_WEB_KEY__SAGA, getWebKeys);
}
