import {
  get,
  isArray,
  isString,
  set,
  some,
  uniq,
} from "lodash";
import moment from "moment";
import {getI18n} from "react-i18next";

import {createReducer} from "@reduxjs/toolkit";

import {
  DEFAULT_LANGUAGE,
  GENERAL_DATA_MAPPING,
  SET_CITIZENSHIPS,
  SET_COUNTRIES,
  SET_GENDERS,
  SET_GUEST_AUTH_CONFIGURATION,
  SET_ID_DOCUMENT_TYPES,
  SET_IS_TERMINAL,
  SET_LANG,
  SET_PROCESS_STEPS,
  SET_RESERVATION_FETCH_TIME,
  SET_ROOMS,
  SET_SALUTATIONS,
  SET_SITE_DATA,
} from "../../../constants";
import {isTerminal} from "../../../env";
import {getUpdateObjectFn} from "../../../utils/state.utils";

let initialState = {
  isTerminal: window.hasOwnProperty("KioskUtils") || isTerminal(),
  lang: getI18n()?.language || DEFAULT_LANGUAGE,
  bookingDataExpired: "",
  countries: [],
  genders: [],
  citizenships: [],
  salutations: [],
  idDocTypes: [],
  siteData: {
    contact: {
      website: undefined,
      email: undefined,
      phone: undefined,
    },
    siteLegalTextTranslations: [],
  },
  rooms: [],
  steps: [],
  guestAuthConfiguration: {
    byReservationNumberEnabled: undefined,
    byPersonalDataEnabled: undefined,
    byQrCodeEnabled: undefined,
    lastNameRequired: undefined,
    firstNameRequired: undefined,
    dateOfBirthRequired: undefined,
    arrivalDateRequired: undefined,
    departureDateRequired: undefined,
    emailRequired: undefined,
    reservationNumberRequired: undefined,
  },
};

const generalReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_COUNTRIES, (state, action) => {
      let countries = (action.payload || []).map((country) => {
        let mappedCountry = {};

        const updateCountry = getUpdateObjectFn(country, mappedCountry);

        updateCountry(GENERAL_DATA_MAPPING.COUNTRIES.CODE);
        updateCountry(GENERAL_DATA_MAPPING.COUNTRIES.NAME);

        return mappedCountry;
      });

      set(state, GENERAL_DATA_MAPPING.COUNTRIES.uiKey, countries);
    })
    .addCase(SET_GENDERS, (state, action) => {
      let genders = (action.payload || []).map((gender) => {
        let mappedGender = {};

        const updateGender = getUpdateObjectFn(gender, mappedGender);

        updateGender(GENERAL_DATA_MAPPING.GENDERS.VALUE);
        updateGender(GENERAL_DATA_MAPPING.GENDERS.TRANSLATION_KEY);

        return mappedGender;
      });

      set(state, GENERAL_DATA_MAPPING.GENDERS.uiKey, genders);
    })
    .addCase(SET_CITIZENSHIPS, (state, action) => {
      let citizenships = (action.payload || []).map((citizenship) => {
        let mappedCitizenship = {};

        const updateCitizenship = getUpdateObjectFn(citizenship, mappedCitizenship);

        updateCitizenship(GENERAL_DATA_MAPPING.CITIZENSHIPS.CODE);
        updateCitizenship(GENERAL_DATA_MAPPING.CITIZENSHIPS.NAME);

        return mappedCitizenship;
      });

      set(state, GENERAL_DATA_MAPPING.CITIZENSHIPS.uiKey, citizenships);
    })
    .addCase(SET_SALUTATIONS, (state, action) => {
      let salutations = (action.payload || []).map((salutation) => {
        let mappedSalutation = {};

        const updateSalutation = getUpdateObjectFn(salutation, mappedSalutation);

        updateSalutation(GENERAL_DATA_MAPPING.SALUTATIONS.ID);
        updateSalutation(GENERAL_DATA_MAPPING.SALUTATIONS.UUID);
        updateSalutation(GENERAL_DATA_MAPPING.SALUTATIONS.NAME);
        updateSalutation(GENERAL_DATA_MAPPING.SALUTATIONS.DISPLAY_NAME);
        updateSalutation(GENERAL_DATA_MAPPING.SALUTATIONS.GENDER);
        updateSalutation(GENERAL_DATA_MAPPING.SALUTATIONS.IS_DEFAULT);

        return mappedSalutation;
      });

      set(state, GENERAL_DATA_MAPPING.SALUTATIONS.uiKey, salutations);
    })
    .addCase(SET_ID_DOCUMENT_TYPES, (state, action) => {
      let idDocTypes = (action.payload || []).map((salutation) => {
        let mappedDocumentType = {};

        const updateIdDocType = getUpdateObjectFn(salutation, mappedDocumentType);

        updateIdDocType(GENERAL_DATA_MAPPING.ID_DOC_TYPES.ID);
        updateIdDocType(GENERAL_DATA_MAPPING.ID_DOC_TYPES.UUID);
        updateIdDocType(GENERAL_DATA_MAPPING.ID_DOC_TYPES.NAME);
        updateIdDocType(GENERAL_DATA_MAPPING.ID_DOC_TYPES.DISPLAY_NAME);
        updateIdDocType(GENERAL_DATA_MAPPING.ID_DOC_TYPES.CODE);

        return mappedDocumentType;
      });

      set(state, GENERAL_DATA_MAPPING.ID_DOC_TYPES.uiKey, idDocTypes);
    })
    .addCase(SET_SITE_DATA, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      updateState(GENERAL_DATA_MAPPING.SITE_DATA.WEBSITE);
      updateState(GENERAL_DATA_MAPPING.SITE_DATA.EMAIL);
      updateState(GENERAL_DATA_MAPPING.SITE_DATA.PHONE);

      let legalTextTranslations = get(
        action.payload,
        GENERAL_DATA_MAPPING.SITE_DATA.LEGAL_TEXT_TRANSLATIONS.apiKey,
        []
      ).map((legalTextTranslation) => {
        let mappedLegalTextTranslation = {};

        const updateLegalTextTranslation = getUpdateObjectFn(
          legalTextTranslation,
          mappedLegalTextTranslation
        );

        updateLegalTextTranslation(
          GENERAL_DATA_MAPPING.SITE_DATA.LEGAL_TEXT_TRANSLATIONS
            .LEGAL_TEXT_TRANSLATIONS_LANGUAGE_CODE
        );
        updateLegalTextTranslation(
          GENERAL_DATA_MAPPING.SITE_DATA.LEGAL_TEXT_TRANSLATIONS
            .LEGAL_TEXT_TRANSLATIONS_TERMS_AND_CONDITIONS_URL
        );

        return mappedLegalTextTranslation;
      });

      set(
        state,
        GENERAL_DATA_MAPPING.SITE_DATA.LEGAL_TEXT_TRANSLATIONS.uiKey,
        legalTextTranslations
      );
    })
    .addCase(SET_RESERVATION_FETCH_TIME, (state, action) => {
      const now = action.payload;
      const expirationDateTime = moment(now).add(15, "m").format("YYYY-MM-DD HH:mm");

      set(state, GENERAL_DATA_MAPPING.BOOKING_DATA_EXPIRED.uiKey, expirationDateTime);
    })
    .addCase(SET_LANG, (state, action) => {
      set(state, GENERAL_DATA_MAPPING.LANG.uiKey, action.payload);
    })
    .addCase(SET_IS_TERMINAL, (state, action) => {
      set(state, GENERAL_DATA_MAPPING.IS_TERMINAL.uiKey, action.payload);
    })
    .addCase(SET_ROOMS, (state, action) => {
      let rooms = (action.payload || []).map((room) => {
        let mappedRoom = {};

        const updateRoom = getUpdateObjectFn(room, mappedRoom);

        updateRoom(GENERAL_DATA_MAPPING.ROOMS.NAME);
        updateRoom(GENERAL_DATA_MAPPING.ROOMS.DESCRIPTION);

        return mappedRoom;
      });

      set(state, GENERAL_DATA_MAPPING.ROOMS.uiKey, rooms);
    })
    .addCase(SET_PROCESS_STEPS, (state, action) => {
      let steps = [];

      if (isArray(action.payload) && some(action.payload, isString)) {
        steps = uniq(action.payload);
      }

      set(state, GENERAL_DATA_MAPPING.STEPS.uiKey, steps);
    })
    .addCase(SET_GUEST_AUTH_CONFIGURATION, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      updateState(
        GENERAL_DATA_MAPPING.GUEST_AUTH_CONFIGURATION.BY_RESERVATION_NUMBER_ENABLED
      );
      updateState(GENERAL_DATA_MAPPING.GUEST_AUTH_CONFIGURATION.BY_PERSONAL_DATA_ENABLED);
      updateState(GENERAL_DATA_MAPPING.GUEST_AUTH_CONFIGURATION.BY_QR_CODE_ENABLED);
      updateState(GENERAL_DATA_MAPPING.GUEST_AUTH_CONFIGURATION.LAST_NAME_REQUIRED);
      updateState(GENERAL_DATA_MAPPING.GUEST_AUTH_CONFIGURATION.FIRST_NAME_REQUIRED);
      updateState(GENERAL_DATA_MAPPING.GUEST_AUTH_CONFIGURATION.DATE_OF_BIRTH_REQUIRED);
      updateState(GENERAL_DATA_MAPPING.GUEST_AUTH_CONFIGURATION.ARRIVAL_DATE_REQUIRED);
      updateState(GENERAL_DATA_MAPPING.GUEST_AUTH_CONFIGURATION.DEPARTURE_DATE_REQUIRED);
      updateState(GENERAL_DATA_MAPPING.GUEST_AUTH_CONFIGURATION.EMAIL_REQUIRED);
      updateState(
        GENERAL_DATA_MAPPING.GUEST_AUTH_CONFIGURATION.RESERVATION_NUMBER_REQUIRED
      );
    });
});

export {generalReducer};
