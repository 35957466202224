import "./DateRange.scss";

import React, {useCallback, useState} from "react";

import format from "date-fns/format";
import {de, enGB} from "date-fns/locale";
import moment from "moment";
import PropTypes from "prop-types";
import {DateRangePicker} from "react-date-range";
import {useTranslation} from "react-i18next";

import {ReactComponent as CalendarIcon} from "../../assets/images/Icon_calendar.svg";
import {DEFAULT_LANGUAGE} from "../../constants";
import {useWindowSize} from "../../utils/customHooks/windowResize.hook";
import {Button, buttonTypes} from "../Button";
import {Modal} from "../Modal";

const DateRange = ({
  arrivalDate,
  departureDate,
  onChange = () => {},
  minDate,
  maxDate,
  instructionsText = "",
  startDatePlaceholder = "",
  endDatePlaceholder = "",
  isInvalid,
}) => {
  const {isSmallDisplay} = useWindowSize();
  const {t} = useTranslation(["form", "general", "date"]);

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isTouched, setIsTouched] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [prevDates, setPrevDates] = useState({
    selection: {
      startDate: null,
      endDate: null,
    },
  });

  const styles = getComputedStyle(document.documentElement);
  const brandColor = styles.getPropertyValue("--primary-brand-color")?.trim();

  const displayInstructionStep = () => {
    if (!isFirstStep && instructionsText) {
      return instructionsText;
    }
    if (isSmallDisplay) {
      return `${
        isArrivalDateValid() ? getDayAndMonthName(startDate) : startDatePlaceholder
      } - ${isDepartureDateValid() ? getDayAndMonthName(endDate) : endDatePlaceholder}`;
    }
  };

  const handleDateRangeSelect = (dates) => {
    setStartDate(dates.selection.startDate);
    setEndDate(dates.selection.endDate);
    onChange(dates);
    if (isFirstStep) {
      setIsFirstStep(false);
    } else {
      setIsFirstStep(true);
    }
  };

  const handleInputClick = () => {
    if (!isTouched) setIsTouched(true);
    setIsCalendarVisible(true);
    setIsFirstStep(true);

    if (isArrivalDateValid() && isDepartureDateValid()) {
      setPrevDates({
        selection: {
          startDate: new Date(arrivalDate),
          endDate: new Date(departureDate),
        },
      });
    }
  };

  const hideCalendar = (shouldSetPrevValue = false) => {
    if (shouldSetPrevValue) {
      if (prevDates.selection.startDate && prevDates.selection.endDate) {
        setStartDate(prevDates.selection.startDate);
        setEndDate(prevDates.selection.endDate);
      } else {
        setStartDate(new Date());
        setEndDate(new Date());
      }
      onChange(prevDates);
    }
    setIsCalendarVisible(false);
    setIsFirstStep(true);
  };

  const getDayAndMonthName = (date) => {
    return format(date, "dd MMM", {
      locale: moment.locale() === DEFAULT_LANGUAGE ? de : enGB,
    });
  };

  const isArrivalDateValid = useCallback(
    () => arrivalDate && moment(arrivalDate).isValid(),
    [arrivalDate]
  );
  const isDepartureDateValid = useCallback(
    () => departureDate && moment(departureDate).isValid(),
    [departureDate]
  );

  return (
    <div className="daterange">
      <div className="daterange__fields-wrapper">
        <div
          className={`daterange__input ${isInvalid ? "error-focusable" : ""}`}
          onClick={handleInputClick}>
          <div>
            <span>
              {isArrivalDateValid()
                ? getDayAndMonthName(startDate)
                : startDatePlaceholder}
            </span>{" "}
            -{" "}
            <span>
              {isDepartureDateValid() ? getDayAndMonthName(endDate) : endDatePlaceholder}
            </span>
          </div>
          <CalendarIcon className="daterange__input-icon" />
        </div>
      </div>

      <Modal
        className={`daterange__modal ${!isSmallDisplay ? "daterange__modal--large" : ""}`}
        isVisible={isCalendarVisible}
        onHide={() => hideCalendar(true)}
        doesHideOnBlur>
        {!isSmallDisplay && (
          <div className="daterange__input-container">
            <div className="daterange__input" onClick={handleInputClick}>
              <div>
                <span
                  className={`${
                    isArrivalDateValid() ? "daterange__input-date--selected" : ""
                  }`}>
                  {isArrivalDateValid()
                    ? getDayAndMonthName(startDate)
                    : startDatePlaceholder}
                </span>{" "}
                -{" "}
                <span
                  className={
                    isDepartureDateValid() && isFirstStep
                      ? "daterange__input-date--selected"
                      : ""
                  }>
                  {isDepartureDateValid()
                    ? getDayAndMonthName(endDate)
                    : endDatePlaceholder}
                </span>
              </div>
              <CalendarIcon className="daterange__input-icon" />
            </div>
          </div>
        )}
        <DateRangePicker
          locale={moment.locale() === DEFAULT_LANGUAGE ? de : enGB}
          ranges={[
            {
              startDate: startDate,
              endDate: endDate,
              key: "selection",
            },
          ]}
          onChange={handleDateRangeSelect}
          months={isSmallDisplay ? 1 : 2}
          direction={isSmallDisplay ? "vertical" : "horizontal"}
          showMonthAndYearPickers={false}
          showSelectionPreview={false}
          dragSelectionEnabled={false}
          rangeColors={[brandColor]}
          showDateDisplay={false}
          scroll={{enabled: isSmallDisplay ? true : false}}
          minDate={new Date(minDate)}
          maxDate={new Date(maxDate)}
          className={`daterange__calendar ${
            isCalendarVisible ? "daterange__calendar--show" : ""
          }`}
        />
        <div className="daterange__footer">
          <div className="daterange__footer-text">{displayInstructionStep()}</div>
          <Button
            type={buttonTypes.primary}
            onClick={() => hideCalendar(false)}
            disabled={!isFirstStep}>
            {t("general:confirm")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

DateRange.propTypes = {
  arrivalDate: PropTypes.string,
  departureDate: PropTypes.string,
  onChange: PropTypes.func,
  minDate: PropTypes.instanceOf(moment).isRequired,
  maxDate: PropTypes.instanceOf(moment).isRequired,
  instructionsText: PropTypes.string,
  startDatePlaceholder: PropTypes.string,
  endDatePlaceholder: PropTypes.string,
  isInvalid: PropTypes.bool,
};

export {DateRange};
