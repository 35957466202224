const translations = {
  terms: {
    title: "Terms and condition",
    subtitle:
      "Please read and accept the Terms and Conditions to start our Check-In Process.",
    acceptTerms: "I have read and accept the terms and conditions",
  },
};

export {translations};
