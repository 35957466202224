import {createAction} from "@reduxjs/toolkit";

import {
  API_ERROR__SAGA,
  CONFIG__SAGA,
  COST_OVERVIEW_FINISH__SAGA,
  COVID_CHECK_FINISH__SAGA,
  DESTROY_SESSION,
  FIND_BOOKING__SAGA,
  FIND_BOOKING_DETAILS_BY_GUID__SAGA,
  FIND_BOOKING_URL__SAGA,
  GENERAL_DATA__SAGA,
  GET_COVID_CHECK_GUEST_STATUS__SAGA,
  GET_COVID_CHECK_STATUSES__SAGA,
  GET_LOOKUP_FIELDS__SAGA,
  GET_WEB_KEY__SAGA,
  GO_HOME__SAGA,
  HIDE_ALL_NOTIFICATIONS,
  HIDE_LOADING_OVERLAY,
  HIDE_NOTIFICATION,
  POST_CHECKIN_FINISH__SAGA,
  POST_CHECKOUT_FINISH__SAGA,
  POST_COVID_CHECK__SAGA,
  POST_ID_DOCUMENT__SAGA,
  POST_ISSUE_MOBILE_KEY__SAGA,
  POST_KEY_CARD_ADDITIONAL_RECEIVED__SAGA,
  POST_KEY_CARD_ADDITIONAL_WRITING__SAGA,
  POST_KEY_CARD_FINISH__SAGA,
  POST_KEY_CARD_RECEIVED__SAGA,
  POST_KEY_CARD_WRITING__SAGA,
  POST_LOOKUP_FIELDS__SAGA,
  POST_OPEN_DOOR__SAGA,
  POST_PAYMENT_INITIATE__SAGA,
  POST_PAYMENT_SPLIT__SAGA,
  POST_REGISTER_DEVICE__SAGA,
  POST_SIGNATURE_CHECK__SAGA,
  SAVE_REGISTRATION__SAGA,
  SEND_SIGNATURE__SAGA,
  SET_ADDITIONAL_KEY_CARDS_ALLOWED,
  SET_APP_CONFIG,
  SET_BILLING_ADDRESS_CHANGED,
  SET_BLE_KEYS,
  SET_CHECKOUT_AVAILABLE,
  SET_CITIZENSHIPS,
  SET_COUNTRIES,
  SET_COVID_CONFIGURATION,
  SET_CURRENT_FLOW,
  SET_CURRENT_NEXT,
  SET_FLOW,
  SET_FORM_FIELD_REQUIREMENTS,
  SET_GENDERS,
  SET_GUEST_AUTH_CONFIGURATION,
  SET_HAS_CHECKOUT_STARTED_FOR_NATIVE,
  SET_ID_DOCUMENT_TYPES,
  SET_INVOICE,
  SET_INVOICE_SPLIT_AVAILABLE,
  SET_ID_DOCUMENT_SCANS,
  SET_IS_CHECKIN_VIEWED,
  SET_IS_CHECKOUT_VIEWED,
  SET_IS_TERMINAL,
  SET_KEY_CARDS_DATA,
  SET_LANG,
  SET_LOOKUP_FIELDS,
  SET_MOBILE_KEY_DATA,
  SET_NEXT,
  SET_NOTIFICATION,
  SET_PAYMENT_INSTRUMENTS,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_PENDING,
  SET_PAYMENT_RESULT,
  SET_PROCESS_CONFIGURATION,
  SET_PROCESS_OVERVIEW,
  SET_PROCESS_STEPS,
  SET_RATE_PLAN,
  SET_REGISTERED_DEVICE,
  SET_REGISTRATION,
  SET_RESERVATION,
  SET_RESERVATION_DATA__SAGA,
  SET_RESERVATION_FETCH_TIME,
  SET_ROOMS,
  SET_SALUTATIONS,
  SET_SIGNATURE_CHALLENGE_DATA,
  SET_SIGNATURE_PROVIDER_INFO,
  SET_SIGNATURE_REDIRECT_URL,
  SET_SITE_DATA,
  SET_TRANSLATIONS,
  SET_WEB_KEYS,
  SHOW_CONFIRM_GO_HOME_MODAL,
  SHOW_HOTEL_SUPPORT,
  SHOW_KEY_SUPPORT,
  SHOW_LOADING_OVERLAY,
  IS_PRIVATE_INVOICE_ADDRESS,
  SET_ALLOW_EXTRA_KEYS,
} from "../../constants";

// APP CONFIG
export const getConfiguration = createAction(CONFIG__SAGA);
export const setAppConfiguration = createAction(SET_APP_CONFIG);

export const setProcessConfiguration = createAction(SET_PROCESS_CONFIGURATION);
export const setGuestAuthConfiguration = createAction(SET_GUEST_AUTH_CONFIGURATION);
export const setProcessOverview = createAction(SET_PROCESS_OVERVIEW);
export const setCovidConfiguration = createAction(SET_COVID_CONFIGURATION);

// SET ROUTING DATA
export const setFlow = createAction(SET_FLOW);
export const setNext = createAction(SET_NEXT);
export const setCurrentNext = createAction(SET_CURRENT_NEXT);
export const setCurrentFlow = createAction(SET_CURRENT_FLOW);
export const setHasCheckoutStartedForNative = createAction(
  SET_HAS_CHECKOUT_STARTED_FOR_NATIVE
);

// SET LOADING OVERLAY DATA
export const showLoadingOverlay = createAction(SHOW_LOADING_OVERLAY);
export const hideLoadingOverlay = createAction(HIDE_LOADING_OVERLAY);

// Show and hide support modal
export const toggleSupportModal = createAction(SHOW_HOTEL_SUPPORT);
export const toggleKeySupportModal = createAction(SHOW_KEY_SUPPORT);
export const toggleConfirmGoHomeModal = createAction(SHOW_CONFIRM_GO_HOME_MODAL);

// SET NOTIFICATION DATA
export const setNotification = createAction(SET_NOTIFICATION);
export const hideNotification = createAction(HIDE_NOTIFICATION);
export const hideAllNotifications = createAction(HIDE_ALL_NOTIFICATIONS);

// LOOKUP-FIELDS
export const getLookupFieldsSagaAction = createAction(GET_LOOKUP_FIELDS__SAGA);
export const postLookupFieldsSagaAction = createAction(POST_LOOKUP_FIELDS__SAGA);
export const setLookupFields = createAction(SET_LOOKUP_FIELDS);

// GET REGISTRATION DATA
export const findBookingSagaAction = createAction(FIND_BOOKING__SAGA);
export const findBookingDetailsByGuidSagaAction = createAction(
  FIND_BOOKING_DETAILS_BY_GUID__SAGA
);
export const findBookingUrlSagaAction = createAction(FIND_BOOKING_URL__SAGA);

// SET REGISTRATION DATA
export const setBillingAddressChanged = createAction(SET_BILLING_ADDRESS_CHANGED);
export const postIDDocument = createAction(POST_ID_DOCUMENT__SAGA);
export const setIDDocumentScans = createAction(SET_ID_DOCUMENT_SCANS);
export const setIsPrivateInvoiceAddress = createAction(IS_PRIVATE_INVOICE_ADDRESS);
export const setFormFieldRequirements = createAction(SET_FORM_FIELD_REQUIREMENTS);
export const setRegistration = createAction(SET_REGISTRATION);
export const saveRegistration = createAction(SAVE_REGISTRATION__SAGA);

// SEND SIGNATURE
export const sendSignatureSagaAction = createAction(SEND_SIGNATURE__SAGA);
export const setSignatureProviderInfo = createAction(SET_SIGNATURE_PROVIDER_INFO);
export const setSignatureRedirectUrl = createAction(SET_SIGNATURE_REDIRECT_URL);
export const setSignatureChallengeData = createAction(SET_SIGNATURE_CHALLENGE_DATA);
export const postSignatureCheckSagaAction = createAction(POST_SIGNATURE_CHECK__SAGA);

// COVID
export const postCovidCheckSagaAction = createAction(POST_COVID_CHECK__SAGA);
export const covidCheckFinishSagaAction = createAction(COVID_CHECK_FINISH__SAGA);
export const getCovidCheckStatusesSagaAction = createAction(
  GET_COVID_CHECK_STATUSES__SAGA
);
export const getCovidCheckGuestStatusSagaAction = createAction(
  GET_COVID_CHECK_GUEST_STATUS__SAGA
);

// SET RESERVATION
export const setReservation = createAction(SET_RESERVATION);
export const setReservationFetchTime = createAction(SET_RESERVATION_FETCH_TIME);
export const setCheckoutAvailable = createAction(SET_CHECKOUT_AVAILABLE);
export const setIsCheckinViewed = createAction(SET_IS_CHECKIN_VIEWED);
export const setIsCheckoutViewed = createAction(SET_IS_CHECKOUT_VIEWED);

// SET INVOICE
export const setInvoice = createAction(SET_INVOICE);
export const setInvoiceSplitAvailable = createAction(SET_INVOICE_SPLIT_AVAILABLE);

// GENERAL DATA
export const generalDataSagaAction = createAction(GENERAL_DATA__SAGA);
export const setCountries = createAction(SET_COUNTRIES);
export const setSiteData = createAction(SET_SITE_DATA);
export const setCitizenships = createAction(SET_CITIZENSHIPS);
export const setGenders = createAction(SET_GENDERS);
export const setSalutations = createAction(SET_SALUTATIONS);
export const setIdDocTypes = createAction(SET_ID_DOCUMENT_TYPES);
export const setLang = createAction(SET_LANG);
export const setIsTerminal = createAction(SET_IS_TERMINAL);

// SET PAYMENT DATA
export const setPaymentMethods = createAction(SET_PAYMENT_METHODS);
export const setPaymentResult = createAction(SET_PAYMENT_RESULT);
export const setPaymentInstruments = createAction(SET_PAYMENT_INSTRUMENTS);
export const postPaymentInitiateSagaAction = createAction(POST_PAYMENT_INITIATE__SAGA);
export const postPaymentSplitSagaAction = createAction(POST_PAYMENT_SPLIT__SAGA);
export const setPaymentPending = createAction(SET_PAYMENT_PENDING);

// COST OVERVIEW
export const costOverviewFinishSagaAction = createAction(COST_OVERVIEW_FINISH__SAGA);

// ISSUE-KEY
export const setKeyCardsData = createAction(SET_KEY_CARDS_DATA);
export const setAdditionalKeyCardsAllowed = createAction(
  SET_ADDITIONAL_KEY_CARDS_ALLOWED
);
export const setAllowExtraKeys = createAction(SET_ALLOW_EXTRA_KEYS);
export const postKeyCardFinishSagaAction = createAction(POST_KEY_CARD_FINISH__SAGA);
export const postKeyCardAdditionalReceivedSagaAction = createAction(
  POST_KEY_CARD_ADDITIONAL_RECEIVED__SAGA
);
export const postKeyCardReceivedSagaAction = createAction(POST_KEY_CARD_RECEIVED__SAGA);
export const postKeyCardAdditionalWritingSagaAction = createAction(
  POST_KEY_CARD_ADDITIONAL_WRITING__SAGA
);
export const postKeyCardWritingSagaAction = createAction(POST_KEY_CARD_WRITING__SAGA);
export const postOpenDoorSagaAction = createAction(POST_OPEN_DOOR__SAGA);

// ISSUE MOBILE KEY
export const postRegisterDeviceSagaAction = createAction(POST_REGISTER_DEVICE__SAGA);
export const postIssueMobileKeySagaAction = createAction(POST_ISSUE_MOBILE_KEY__SAGA);
export const setMobileKeyData = createAction(SET_MOBILE_KEY_DATA);
export const setRegisteredDevice = createAction(SET_REGISTERED_DEVICE);
export const setWebKeys = createAction(SET_WEB_KEYS);
export const setBleKeys = createAction(SET_BLE_KEYS);
export const getWebKeysSagaAction = createAction(GET_WEB_KEY__SAGA);

// ROOMS
export const setRooms = createAction(SET_ROOMS);
export const setRatePlan = createAction(SET_RATE_PLAN);

// PROCESS STEPS
export const setProcessSteps = createAction(SET_PROCESS_STEPS);

// FINISH
export const postCheckInFinishSagaAction = createAction(POST_CHECKIN_FINISH__SAGA);
export const postCheckOutFinishSagaAction = createAction(POST_CHECKOUT_FINISH__SAGA);

// PURGE
export const purge = createAction(DESTROY_SESSION);

// EFFECTS
export const setReservationDataSagaAction = createAction(SET_RESERVATION_DATA__SAGA);
export const apiErrorSagaAction = createAction(API_ERROR__SAGA);
export const goHomeAction = createAction(GO_HOME__SAGA);

// TRANSLATIONS
export const setTranslations = createAction(SET_TRANSLATIONS);
