import "./BookingNumber.scss";

import React, {useCallback} from "react";

import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import {Input} from "../../../components/Input";
import {
  FORM_REQUIREMENTS,
  LOOKUP_FIELDS_MAPPING,
} from "../../../constants";

const BookingNumber = React.memo(
  ({onChange = () => {}, onSearch = () => {}, bookingData, invalidData, isSearched}) => {
    const {t} = useTranslation(["findBooking", "general", "form"]);

    const handleKeyPress = useCallback(
      (event) => {
        if (event.key === "Enter") {
          onSearch();
        }
      },
      [onSearch]
    );

    return (
      <>
        <div className="booking__intro">
          <span>{t("bookingIntro")}</span>
        </div>

        <div className="booking__fields">
          <Input
            label={t("lastName")}
            value={bookingData[LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey]}
            isInvalid={
              !bookingData[LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey].trim() ||
              invalidData[LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey]
            }
            onChange={onChange}
            fieldKey={LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey}
            onKeyPress={handleKeyPress}
            requirement={FORM_REQUIREMENTS.REQUIRED}
            shouldDisplayErrorMessage={
              isSearched && !bookingData[LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey].trim()
            }
          />

          <Input
            autoCapitalize="off"
            label={t("bookingId")}
            value={bookingData[LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey]}
            isInvalid={
              !bookingData[LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey].trim() ||
              invalidData[LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey]
            }
            onChange={onChange}
            fieldKey={LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey}
            onKeyPress={handleKeyPress}
            requirement={FORM_REQUIREMENTS.REQUIRED}
            shouldDisplayErrorMessage={
              isSearched && !bookingData[LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey].trim()
            }
          />
        </div>
      </>
    );
  }
);

BookingNumber.propTypes = {
  onChange: PropTypes.func,
  bookingData: PropTypes.shape({
    [LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey]: PropTypes.string,
    [LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey]: PropTypes.string,
  }),
  onSearch: PropTypes.func,
  invalidData: PropTypes.shape({
    [LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey]: PropTypes.bool,
    [LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey]: PropTypes.bool,
  }),
  isSearched: PropTypes.bool,
};

export {BookingNumber};
