import {persistor, store} from "./store";

import {PersistGate} from "redux-persist/integration/react";
import PropTypes from "prop-types";
import React from "react";
import {Provider as StoreProvider} from "react-redux";

const Provider = (props) => {
  const {children = []} = props;
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </StoreProvider>
  );
};

Provider.propTypes = {
  children: PropTypes.any,
};

export {Provider};
