import PropTypes from "prop-types";
import React from "react";
import {useForm} from "../../utils/customHooks/form.hook";

const RequirementAsterisk = React.memo(({requirement}) => {
  const formHelper = useForm();

  return (
    <>
      {(formHelper.isRequiredByLaw(requirement) ||
        formHelper.isRequiredForForeigners(requirement)) &&
        "*"}
      {formHelper.isRequiredBySite(requirement) && "**"}
    </>
  );
});

RequirementAsterisk.propTypes = {
  requirement: PropTypes.string,
};

export {RequirementAsterisk};
