const translations = {
  registration: {
    registrationFormPagesTitle: "Erzählen Sie uns mehr über sich",
    registrationFormPagesSubtitle:
      "Ihre Daten sind sicher und werden nur für Ihren Aufenthalt benötigt.",
    contactFormTitle: "Zusätzliche Informationen",
    contactFormSubtitle:
      "Ihre Daten sind sicher und werden nur für Ihren Aufenthalt benötigt.",
    title: "Gastdaten vervollständigen",
    address: "Meldedresse",
    guest: "Gast",
    moreGuests: "Weitere Gäste",
    travelType: "Was ist der Grund für Ihre Reise?",
    addBillingAddress: "Abweichende Rechnungsadresse",
    additionalGuestsChange:
      "Wenn Sie mit weniger oder mehr Personen anreisen, als in Ihrer Reservierung hinterlegt, kontaktieren Sie bitte das Hotel.",
    legalAge: "Sie müssen mindestens {{age}} Jahre alt sein",
  },
};

export {translations};
