const translations = {
  finish: {
    checkIn: {
      title: "Almost done!",
      subtitle: "All data completed.",
      inProgress: "One moment please... \n\nYour check-in is performed...",
      stepsCompleted: "All data completed",
      confirmText: "Check In Now",
      confirmWithKeys: "Check in now and get your keys",
      reservationData: "Reservation Data confirmed",
      guestData: "Guest Data completed",
      enjoy: "Enjoy your stay!",
    },
    checkOut: {
      title: "One step left to Check-Out",
      checkOutNow: "Check-Out now",
      yesCheckOut: "Yes, Check-Out",
      checkOutLater: "No, come back later",
      subtitle: "All data completed.",
      stepsCompleted: "All steps completed",
      costOverviewChecked: "Cost Overview checked",
      paymentCompleted: "Payment completed",
      youSure: "Do you want to Check-Out now?",
      inProgress: "One moment please... \n\nYour check-out is performed...",
      hopeYouEnjoyed: "We hope you enjoyed \n\nyour stay!",
      looseAccess: "You will loose the access to Mobile Keys after Check-Out",
      takeBelongings:
        "Please take all your belongings from the room as you will lose access to the keys.",
    },
  },
};

export {translations};
