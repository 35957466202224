import "./Button.scss";

import React from "react";

import PropTypes from "prop-types";

const buttonTypes = {
  primary: "primary",
  secondary: "secondary",
  accent: "accent",
  inactive: "inactive",
};

const Button = ({
  onClick = () => {},
  children,
  buttonType = buttonTypes.primary,
  className = "",
  disabled = false,
  ...args
}) => {
  const getButtonType = () => {
    if (disabled) {
      return buttonTypes.inactive;
    }
    return (
      Object.values(buttonTypes).find((bt) => bt === buttonType) || buttonTypes.primary
    );
  };

  return (
    <button
      className={`button button--${getButtonType()} ${className}`}
      onClick={(...args) => !disabled && onClick && onClick(args)}
      {...args}>
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  buttonType: PropTypes.oneOf(Object.values(buttonTypes)),
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export {Button, buttonTypes};
