import {push} from "connected-react-router";
import {get} from "lodash";
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

/* eslint-disable no-restricted-globals */
import {
  NOTIFICATION_TYPES,
  PAYMENT_MAPPING,
  PAYMENT_PROVIDERS,
  PAYMENT_STATUS,
  POST_PAYMENT_INITIATE__SAGA,
} from "../../../constants";
import {
  getNotificationNameByText,
  notificationMessages,
} from "../../../utils/notification.utils";
import {getModuleType} from "../../../utils/route.utils";
import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  setNotification,
  setPaymentInstruments,
  setPaymentMethods,
  setPaymentResult,
  setReservationDataSagaAction,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* setPaymentData(payment) {
  yield put(setPaymentMethods(get(payment, PAYMENT_MAPPING.PAYMENT_METHODS.apiKey, [])));
  yield put(
    setPaymentInstruments(get(payment, PAYMENT_MAPPING.PAYMENT_INSTRUMENTS.apiKey, []))
  );
  yield put(setPaymentResult(get(payment, PAYMENT_MAPPING.PAYMENT_RESULT.apiKey, {})));
  yield put(hideLoadingOverlay());
}

function* postPaymentInitiate(action) {
  const {payload} = action;
  const {resolve, reject} = payload || {};
  const type = getModuleType();
  const store = yield select(),
    reservation = store.reservationReducer,
    {code, name} = reservation,
    payment = store.paymentReducer,
    paymentConfiguration = payment.paymentResult?.paymentConfiguration || {},
    provider = paymentConfiguration?.provider || "";

  try {
    let body = {
      browserInformation: {
        colorDepth: 24,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        acceptHeader:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
        userAgent: window.navigator.userAgent,
        userHostAddress: null,
        origin: window.origin,
      },
      successUrl: `${window.location.origin}/${type}/${code}/${name}/payment/finish`,
      failureUrl: `${window.location.origin}/${type}/${code}/${name}/payment/finish`,
      cancelUrl: `${window.location.origin}/${type}/${code}/${name}/payment/finish?canceled=true`,
    };

    if (provider === PAYMENT_PROVIDERS.ADYEN) {
      body = yield* addAdyenData(body, payload);
    }

    const client = yield* getClientTypeFn();
    const payInitResp = yield call([client, "postPaymentInitiate"], code, name, body);

    const payment = get(payInitResp.data, PAYMENT_MAPPING.apiKey);
    const paymentResult = get(payment, PAYMENT_MAPPING.PAYMENT_RESULT.apiKey);
    const paymentStatus = get(
      paymentResult,
      PAYMENT_MAPPING.PAYMENT_RESULT.PAYMENT_STATUS.apiKey
    );
    const paymentId = get(
      paymentResult,
      PAYMENT_MAPPING.PAYMENT_RESULT.PAYMENT_UUID.apiKey
    );

    if (paymentStatus) {
      switch (paymentStatus) {
        case PAYMENT_STATUS.INITIATED: {
          yield* setPaymentData(payment);
          break;
        }
        case PAYMENT_STATUS.SUCCESS: {
          yield put(setReservationDataSagaAction({response: payInitResp}));
          yield put(
            setNotification({
              type: NOTIFICATION_TYPES.SUCCESS,
              messageKey: getNotificationNameByText(notificationMessages.success.payment),
              duration: 5000,
            })
          );
          break;
        }
        case PAYMENT_STATUS.PENDING: {
          yield* setPaymentData(payment);
          yield put(push(`/${type}/${code}/${name}/payment/finish?id=${paymentId}`));
          break;
        }
        case PAYMENT_STATUS.CANCELED: {
          yield* setPaymentData(payment);
          yield put(push(`/${type}/${code}/${name}/payment/finish?id=${paymentId}`));
          break;
        }
        case PAYMENT_STATUS.FAILURE: {
          yield* setPaymentData(payment);
          yield put(push(`/${type}/${code}/${name}/payment/finish?id=${paymentId}`));
          break;
        }
        default: {
          break;
        }
      }
    } else {
      yield put(setReservationDataSagaAction({response: payInitResp}));
    }

    if (resolve) {
      resolve();
    }
  } catch (e) {
    yield put(hideLoadingOverlay());
    yield put(apiErrorSagaAction({error: e}));

    if (reject) {
      reject(e.response);
    }
  }
}

const addAdyenData = (body, payload) => {
  const data = payload.data;

  if (!data) {
    return body;
  }

  let paymentMethods = get(data, PAYMENT_MAPPING.PAYMENT_METHODS.apiKey);

  if (paymentMethods.storedPaymentMethodId) {
    paymentMethods.paymentInstrumentCode = paymentMethods.storedPaymentMethodId;
  }

  return {
    ...body,
    paymentInstrumentType: paymentMethods.type || "",
    creditCardData: {...paymentMethods},
  };
};

export function* postPaymentInitiateSaga() {
  yield takeEvery(POST_PAYMENT_INITIATE__SAGA, postPaymentInitiate);
}
