import {Redirect, Route, Switch} from "react-router-dom";

import PropTypes from "prop-types";
import React from "react";

const RenderRoutes = ({Component, children, pathname, path}) => {
  const startingPath = `${path}${pathname}`;

  return (
    <Route path={`${startingPath}`}>
      {Component && <Component />}

      {!Component && children && children.length > 0 && (
        <>
          <Switch>
            <Route exact path={`${startingPath}`}>
              <Redirect to={{pathname: `${startingPath}${children[0].pathname || ""}`}} />
            </Route>

            {children.map(({pathname: childPathname, Component: ChildComponent}) => (
              <Route exact path={`${startingPath}${childPathname}`} key={childPathname}>
                <ChildComponent />
              </Route>
            ))}

            <Route>
              <Redirect to={`${startingPath}`} />
            </Route>
          </Switch>
        </>
      )}
    </Route>
  );
};

RenderRoutes.propTypes = {
  Component: PropTypes.elementType,
  children: PropTypes.arrayOf(
    PropTypes.shape({pathname: PropTypes.string, Component: PropTypes.elementType})
  ),
  pathname: PropTypes.string,
  path: PropTypes.string,
};

export {RenderRoutes};
