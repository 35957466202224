import {LANGUAGES} from "./global.constants";

export const PAYMENT_METHODS = {
  groups: [{name: "Credit Card", types: ["visa", "mc", "amex"]}],
  paymentMethods: [
    {
      brands: ["visa", "mc", "amex"],
      details: [
        {key: "encryptedCardNumber", type: "cardToken"},
        {
          key: "encryptedSecurityCode",
          type: "cardToken",
        },
        {key: "encryptedExpiryMonth", type: "cardToken"},
        {
          key: "encryptedExpiryYear",
          type: "cardToken",
        },
        {key: "holderName", optional: true, type: "text"},
      ],
      name: "Credit Card",
      type: "scheme",
    },
    // {name: "PayPal", supportsRecurring: true, type: "paypal"},
  ],
};

export const ORIGIN_KEY = {
  DEVELOPMENT:
    "pub.v2.8015834230560160.aHR0cDovL2xvY2FsaG9zdDozMDAw.ITRlrMdnFvDj6wq6h7HeTpOaL0CHIjuXYKZvMLfqui0",
  STAGING:
    "pub.v2.8015834230560160.aHR0cHM6Ly9ndWVzdGFwcC5iZXR0ZXJzcGFjZTM2MC5pbw.E03r7iBoyczmsmb10V8uKF7NMe0KY-4EzoO-0Q5sSgM",
  PRODUCTION:
    "pub.v2.8015834230560160.aHR0cHM6Ly9hcHAuYmV0dGVyc3BhY2UzNjAuY29t.0WHFkmMnVVjO1xZdAmiu_n3m4ZQ8RkyyYNQq6GVuS4Y",
};

export const PROD_JS = `<script src="https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.3/adyen.js" integrity="sha384-sW7LTx7Q+1PgLUEUyxTGnmFsfMuZHDpIoIz2Tds1KroBZiT29OnTXZmV+nGhRxvO"crossorigin="anonymous"></script>`,
  PROD_CSS = `<link rel="stylesheet" href="https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.3/adyen.css" integrity="sha384-l5/gSrWMFWCKnEqoG1F21fvhDesLnZt/JlXjkA0FWp6E68Pc/9mxg+nPvvx+uB4G" crossorigin="anonymous">`,
  DEV_JS = `<script src="https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.6.3/adyen.js" integrity="sha384-yJSUmaii24aKOI7fvTEvOL4FDDS6V6TaNicpl82vIn5RK1ApcZM94gsejXdCqVfn" crossorigin="anonymous"></script>`,
  DEV_CSS = `<link rel="stylesheet" href="https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.6.3/adyen.css" integrity="sha384-l5/gSrWMFWCKnEqoG1F21fvhDesLnZt/JlXjkA0FWp6E68Pc/9mxg+nPvvx+uB4G" crossorigin="anonymous">`;

export const onReturnToInvoiceClick = "onReturnToInvoiceClick";
export const onPaymentError = "onPaymentError";
export const onPaymentSuccess = "onPaymentSuccess";

export const PAYMENT_PROVIDERS = {
  ADYEN: "Adyen",
  BS_PAYONE: "BsPayone",
  FIRST_DATA: "FirstData",
  SAFERPAY: "Saferpay",
  DATA_TRANS: "DataTrans",
  CONCARDIS: "Concardis",
};

export const PAYMENT_STATUS = {
  INITIATED: "INITIATED",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  CANCELED: "CANCELED",
};

export const PAYMENT_PRIVACY_LINKS = {
  [PAYMENT_PROVIDERS.CONCARDIS]: {
    [LANGUAGES.DE]: "http://concardis.com/datenschutz",
    [LANGUAGES.EN]: "https://www.concardis.com/de-en/protecting-your-data",
  },
  [PAYMENT_PROVIDERS.SAFERPAY]: {
    [LANGUAGES.DE]:
      "https://www.six-payment-services.com/de/home/learn-more/payment-security.html",
    [LANGUAGES.EN]:
      "https://www.six-payment-services.com/en/home/learn-more/payment-security.html",
  },
};

export const SITES_TO_LOAD_TEST_PAYENGINE_ON_PROD = [
  {
    merchantId: "merchant_qgfvu7o9lq",
    siteId: "62ab9248-ebe3-42f3-a44a-aa0eb3bb2454",
  },
  {
    merchantId: "merchant_qgfvu7o9lq",
    siteId: "5b0b5691-63c4-4c01-9169-8a3b7a9a0d00",
  },
];
