import {call, put, select, takeLatest} from "redux-saga/effects";
import {isNil, omitBy} from "lodash";

import {POST_ID_DOCUMENT__SAGA, REGISTRATION_MAPPING} from "../../../constants";
import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  setIDDocumentScans,
  setRegistration,
  showLoadingOverlay,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postIDDocument(action) {
  const {images, isMainGuest, accompanyingGuestIndex} = action.payload;

  try {
    const hasImages = images && Array.isArray(images) && images.length > 0;
    if (hasImages) {
      let store = yield select();

      let registrationData = store.registrationReducer,
        {guest, accompanyingGuests, idDocumentScans} = registrationData;

      yield put(showLoadingOverlay());

      const client = yield* getClientTypeFn();

      const resp = yield call([client, "postIDDocument"], {
        [REGISTRATION_MAPPING.ID_DOCUMENT.FIRST_SCANNED_IMAGE.apiKey]: hasImages
          ? images[0]
          : "",
        [REGISTRATION_MAPPING.ID_DOCUMENT.SECOND_SCANNED_IMAGE.apiKey]:
          images.length > 1 ? images[1] : "",
      });

      if (resp?.data) {
        const filteredResponse = omitBy(resp.data, (value, key) => {
          return (
            isNil(value) ||
            value === "Unknown" ||
            key === REGISTRATION_MAPPING.GUEST.NATIONALITY.apiKey
          );
        });

        if (isMainGuest) {
          yield put(
            setRegistration({
              data: {
                [REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.GUEST.uiKey]: {
                  ...guest,
                  ...filteredResponse,
                },
                isPayloadUsingUiKeys: true,
              },
            })
          );
          yield put(
            setIDDocumentScans({
              ...idDocumentScans,
              [REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.GUEST.uiKey]:
                idDocumentScans[REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.GUEST.uiKey] + 1,
            })
          );
        } else if (accompanyingGuestIndex >= 0) {
          const newAccompanyingGuests = accompanyingGuests.map((ag, index) => {
            if (index === accompanyingGuestIndex) {
              return {...ag, ...filteredResponse};
            } else {
              return ag;
            }
          });
          yield put(
            setRegistration({
              data: {
                [REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.ACCOMPANYING_GUESTS.uiKey]:
                  newAccompanyingGuests,
                isPayloadUsingUiKeys: true,
              },
            })
          );
          yield put(
            setIDDocumentScans({
              ...idDocumentScans,
              [REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.ACCOMPANYING_GUESTS.uiKey]: {
                ...idDocumentScans[
                  REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.ACCOMPANYING_GUESTS.uiKey
                ],
                [accompanyingGuestIndex]: idDocumentScans[
                  REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.ACCOMPANYING_GUESTS.uiKey
                ][accompanyingGuestIndex]
                  ? idDocumentScans[
                      REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.ACCOMPANYING_GUESTS.uiKey
                    ][accompanyingGuestIndex] + 1
                  : 1,
              },
            })
          );
        }
      }
    }

    yield put(hideLoadingOverlay());
  } catch (e) {
    yield put(hideLoadingOverlay());
    yield put(apiErrorSagaAction({error: e, isAllowedRetry: true}));
  }
}

export function* postIDDocumentSaga() {
  yield takeLatest(POST_ID_DOCUMENT__SAGA, postIDDocument);
}
