import {COVID_MAPPING, GET_COVID_CHECK_STATUSES__SAGA} from "../../../constants";
import {apiErrorSagaAction, hideLoadingOverlay, showLoadingOverlay} from "../../actions";
import {call, put, select, takeLatest} from "redux-saga/effects";

import {get} from "lodash";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* getCovidCheckStatuses(action) {
  const {resolve, reject} = action.payload || {};
  yield put(showLoadingOverlay());

  try {
    let store = yield select(),
      {code, name} = store.reservationReducer;

    const client = yield* getClientTypeFn();
    const covidCheckResponse = yield call([client, "getCovidCheckStatuses"], code, name);

    yield put(hideLoadingOverlay());

    let covidCheckMappedResponse = {};

    (covidCheckResponse.data || []).forEach((covidCheck) => {
      covidCheckMappedResponse[get(covidCheck, COVID_MAPPING.ID.apiKey)] = get(
        covidCheck,
        COVID_MAPPING.STATUS.apiKey
      );
    });

    if (resolve) {
      resolve(covidCheckMappedResponse);
    }
  } catch (error) {
    yield put(hideLoadingOverlay());
    yield put(apiErrorSagaAction({error}));

    if (reject) {
      reject();
    }
  }
}

export function* getCovidCheckStatusesSaga() {
  yield takeLatest(GET_COVID_CHECK_STATUSES__SAGA, getCovidCheckStatuses);
}
