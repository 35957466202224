import {Capacitor, Plugins} from "@capacitor/core";

const {Device} = Plugins;
const isDeviceAvailable = Capacitor.isPluginAvailable("Device");
export const isNativeApp = Capacitor.isNative;

export const getDeviceLanguage = () => {
  if (!isDeviceAvailable) {
    return;
  }

  return Device.getLanguageCode();
};
