import "./LoadingOverlay.scss";

import React from "react";

import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {Loader} from "../Loader";

const LoadingOverlay = () => {
  const {t} = useTranslation(["general"]);

  const loadingReducer = useSelector(({loadingOverlayReducer}) => loadingOverlayReducer),
    {isVisible, message} = loadingReducer;

  return (
    <div className={`loading-overlay ${isVisible ? "loading-overlay--visible" : ""}`}>
      <div className="loading-overlay__description">{message || t("momentPlease")}</div>
      <div className="loading-overlay__icon">
        <Loader />
      </div>
    </div>
  );
};

export {LoadingOverlay};
