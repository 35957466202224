import "./Banner.scss";

import React from "react";

import PropTypes from "prop-types";

import {ReactComponent as ThumbsUp} from "../../assets/images/Icon_thumbs_up.svg";

const bannerTypes = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  DEFAULT: "DEFAULT",
};
const Banner = ({Icon = null, type = bannerTypes.SUCCESS, className, children}) => {
  const getTypeClass = () => {
    switch (type) {
      case bannerTypes.SUCCESS:
        return "banner--success";
      case bannerTypes.WARNING:
        return "banner--warning";
      default:
        return "banner--default";
    }
  };

  return (
    <div className={`banner ${getTypeClass()} ${className || ""}`}>
      <div className="banner__icon">
        {Icon && <Icon />} {!Icon && type === bannerTypes.SUCCESS && <ThumbsUp />}
      </div>
      <div className="banner__content">{children}</div>
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.any,
  Icon: PropTypes.object,
  type: PropTypes.oneOf(Object.values(bannerTypes)),
  className: PropTypes.string,
};

export {Banner, bannerTypes};
