import "./Card.scss";

import React from "react";

import PropTypes from "prop-types";

import {useWindowSize} from "../../utils/customHooks/windowResize.hook";
import {
  positions,
  sizes,
  Title,
} from "../Title";

const cardType = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
  DEFAULT: "DEFAULT",
};

const Card = ({title, Icon, children, type, className = ""}) => {
  const {isSmallDisplay} = useWindowSize();

  const getClassModifierByType = () => {
    switch (type) {
      case cardType.SUCCESS:
        return "--success";
      case cardType.WARNING:
        return "--warning";
      case cardType.DEFAULT:
        return "--default";
      default:
        return "--error";
    }
  };

  return (
    <div className={`card card${getClassModifierByType()} ${className}`}>
      {Icon && cardType.ERROR && (
        <div className="card__icon">
          <Icon />
        </div>
      )}
      <Title
        value={title}
        size={isSmallDisplay ? sizes.default : sizes.large}
        position={positions.center}
      />
      {Icon && !cardType.ERROR && (
        <div className="card__icon">
          <Icon />
        </div>
      )}
      {children && <div className="card__content">{children}</div>}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  Icon: PropTypes.object,
  children: PropTypes.any,
  type: PropTypes.oneOf(Object.values(cardType)),
  className: PropTypes.string,
};

export {Card, cardType};
