import {
  LOOKUP_FIELDS_MAPPING,
  SET_ADDITIONAL_LOOKUP_FIELDS,
  SET_LOOKUP_FIELDS,
} from "../../../constants";

import {createReducer} from "@reduxjs/toolkit";
import {getUpdateObjectFn} from "../../../utils/state.utils";
import {set} from "lodash";

let initialState = {
  requirements: {
    firstName: true,
    lastName: true,
    dateOfBirth: true,
    arrivalDate: true,
    departureDate: true,
    email: true,
    externalReservationCode: true,
  },
  additional: {
    firstName: true,
    lastName: true,
    dateOfBirth: true,
    arrivalDate: true,
    departureDate: true,
    email: true,
    externalReservationCode: true,
  },
};

const lookupFieldsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_LOOKUP_FIELDS, (state, action) => {
      const requirements = {};
      const updateRequirements = getUpdateObjectFn(action.payload, requirements);

      updateRequirements(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.FIRST_NAME);
      updateRequirements(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.LAST_NAME);
      updateRequirements(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DATE_OF_BIRTH);
      updateRequirements(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.ARRIVAL_DATE);
      updateRequirements(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.DEPARTURE_DATE);
      updateRequirements(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EMAIL);
      updateRequirements(LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EXTERNAL_RESERVATION_CODE);

      set(state, LOOKUP_FIELDS_MAPPING.MAIN_GUEST.uiKey, requirements);
    })
    .addCase(SET_ADDITIONAL_LOOKUP_FIELDS, (state, action) => {
      const additionalGuests = {};

      const updateAdditionalGuests = getUpdateObjectFn(action.payload, additionalGuests);

      updateAdditionalGuests(LOOKUP_FIELDS_MAPPING.ADDITIONAL_GUESTS.FIRST_NAME);
      updateAdditionalGuests(LOOKUP_FIELDS_MAPPING.ADDITIONAL_GUESTS.LAST_NAME);
      updateAdditionalGuests(LOOKUP_FIELDS_MAPPING.ADDITIONAL_GUESTS.DATE_OF_BIRTH);
      updateAdditionalGuests(LOOKUP_FIELDS_MAPPING.ADDITIONAL_GUESTS.ARRIVAL_DATE);
      updateAdditionalGuests(LOOKUP_FIELDS_MAPPING.ADDITIONAL_GUESTS.DEPARTURE_DATE);
      updateAdditionalGuests(LOOKUP_FIELDS_MAPPING.ADDITIONAL_GUESTS.EMAIL);
      updateAdditionalGuests(
        LOOKUP_FIELDS_MAPPING.ADDITIONAL_GUESTS.EXTERNAL_RESERVATION_CODE
      );

      set(state, LOOKUP_FIELDS_MAPPING.ADDITIONAL_GUESTS.uiKey, additionalGuests);
    });
});

export {lookupFieldsReducer};
