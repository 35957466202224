const translations = {
  general: {
    checkIn: "Check-In",
    checkOut: "Check-Out",
    preCheckIn: "Pre Check-In",
    confirm: "Bestätigen",
    next: "Weiter",
    startpage: "Zurück zur Startseite",
    back: "Zurück",
    privacyPolicy: "Datenschutzbestimmungen",
    privacyPolicyScan:
      "Datenschutzerklärung - scannen Sie den QR-Code unten, um mehr zu erfahren:",
    leaveProcess: "Prozess verlassen",

    later: "Später",
    save: "Speichern",
    remove: "Entfernen",
    add: "Hinzufügen",
    editData: "Daten bearbeiten",
    cancel: "Abbrechen",
    continue: "Weiter",
    momentPlease: "Einen Moment bitte...",
    tryAgain: "Erneut versuchen",
    clickHere: "Klicken Sie hier",

    confirmGoHomePopupTitle: "Sind Sie sicher, dass Sie den Prozess verlassen möchten?",
    confirmGoHomePopupContent:
      "Sie werden zurück zur Startseite geleitet. Ihre Daten werden gespeichert. Um fortzufahren, müssen Sie jedoch Ihre Buchung erneut suchen.",
    leaveAndGoToHomeScreen: "Seite verlassen und zur Startseite wechseln",
    backToProcess: "Zurück zum Prozess",

    updateAvailableTitle: "Update verfügbar",
    updateAvailableContent:
      "Eine neue Version dieser Anwendung ist verfügbar, bitte laden Sie die Seite neu, um sie zu verwenden.",
    updateAvailableButton: "Neu laden",
  },
};

export {translations};
