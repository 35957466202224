import {get, set} from "lodash";

import {createReducer} from "@reduxjs/toolkit";

import {
  SET_BILLING_ADDRESS_CHANGED,
  IS_PRIVATE_INVOICE_ADDRESS,
  REGISTRATION_MAPPING,
  SET_ID_DOCUMENT_SCANS,
  SET_FORM_FIELD_REQUIREMENTS,
  SET_REGISTRATION,
} from "../../../constants";
import {getUpdateObjectFn} from "../../../utils/state.utils";

let initialState = {
  termsAndConditionsAccepted: false,
  travelPurpose: null,
  isPrivateInvoiceAddress: false,
  billingAddressChanged: false,
  arrivalDate: null,
  departureDate: null,
  accompanyingGuestCount: null,
  accompanyingGuests: [],
  invoiceAddress: {
    countryCode: undefined,
    recipient: undefined,
    firstName: undefined,
    lastName: undefined,
    street: undefined,
    postalCode: undefined,
    city: undefined,
  },
  invoiceAddressOriginal: {
    countryCode: undefined,
    recipient: undefined,
    firstName: undefined,
    lastName: undefined,
    street: undefined,
    postalCode: undefined,
    city: undefined,
  },
  guest: {
    salutation: undefined,
    salutationUuid: undefined,
    gender: undefined,
    firstName: undefined,
    lastName: undefined,
    street: undefined,
    postalCode: undefined,
    city: undefined,
    countryCode: undefined,
    dateOfBirth: undefined,
    citizenshipCode: undefined,
    email: undefined,
    phone: undefined,
    identificationDocumentNumber: undefined,
    identificationDocumentTypeCode: undefined,
    identificationDocumentDateOfIssue: undefined,
    identificationDocumentImage: undefined,
  },
  formFieldRequirements: {
    salutationUuid: undefined,
    gender: undefined,
    firstName: undefined,
    lastName: undefined,
    licensePlate: undefined,
    dateOfBirth: undefined,
    phone: undefined,
    email: undefined,
    street: undefined,
    postalCode: undefined,
    city: undefined,
    countryCode: undefined,
    citizenshipCode: undefined,
    identificationDocumentNumber: undefined,
    identificationDocumentTypeCode: undefined,
    identificationDocumentImage: undefined,
    identificationDocumentDateOfIssue: undefined,
    identificationDocumentAuthority: undefined,
    accompanyingGuest: {
      salutationUuid: undefined,
      gender: undefined,
      firstName: undefined,
      lastName: undefined,
      dateOfBirth: undefined,
      phone: undefined,
      citizenshipCode: undefined,
      identificationDocumentNumber: undefined,
      identificationDocumentTypeCode: undefined,
      identificationDocumentImage: undefined,
      identificationDocumentDateOfIssue: undefined,
      identificationDocumentAuthority: undefined,
    },
  },
  idDocumentScans: {
    guest: 0,
    accompanyingGuests: {},
  },
};

const registrationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_REGISTRATION, (state, action) => {
      const {
        shouldAvoidEmptyingValues,
        data,
        isPayloadUsingUiKeys,
        shouldSetOriginalInvoiceAddress,
      } = action.payload;

      if (!data) {
        return state;
      }

      const setIfAllowed = (mapping, defaultValue) => {
        const key = isPayloadUsingUiKeys ? mapping.uiKey : mapping.apiKey;
        const shouldUpdateKey = data.hasOwnProperty(key);

        const valueToSet = get(data, key, defaultValue);

        if (shouldUpdateKey && (valueToSet || !shouldAvoidEmptyingValues)) {
          set(state, mapping.uiKey, valueToSet);
        }
      };

      setIfAllowed(REGISTRATION_MAPPING.ACCOMPANYING_GUEST_COUNT);
      setIfAllowed(REGISTRATION_MAPPING.TERMS_AND_CONDITIONS_ACCEPTED);
      setIfAllowed(REGISTRATION_MAPPING.ARRIVAL_DATE);
      setIfAllowed(REGISTRATION_MAPPING.DEPARTURE_DATE);
      setIfAllowed(REGISTRATION_MAPPING.TRAVEL_PURPOSE);

      const accompanyingGuestsKey = isPayloadUsingUiKeys
        ? REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.uiKey
        : REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.apiKey;

      if (data.hasOwnProperty(accompanyingGuestsKey)) {
        const accompanyingGuests = get(data, accompanyingGuestsKey, []).map((guest) => {
          let mappedGuest = {};

          const updateGuest = getUpdateObjectFn(guest, mappedGuest);

          updateGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.UUID);
          updateGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.SALUTATION_UUID);
          updateGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.GENDER);
          updateGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.FIRST_NAME);
          updateGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.LAST_NAME);
          updateGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.DATE_OF_BIRTH);
          updateGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.PHONE);
          updateGuest(REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.CITIZENSHIP_CODE);
          updateGuest(
            REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.IDENTIFICATION_DOCUMENT_NUMBER
          );
          updateGuest(
            REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.IDENTIFICATION_DOCUMENT_TYPE_CODE
          );
          updateGuest(
            REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.IDENTIFICATION_DOCUMENT_IMAGE
          );
          updateGuest(
            REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE
          );

          return mappedGuest;
        });

        set(state, REGISTRATION_MAPPING.ACCOMPANYING_GUESTS.uiKey, accompanyingGuests);
      }

      const invoiceAddressKey = isPayloadUsingUiKeys
        ? REGISTRATION_MAPPING.INVOICE_ADDRESS.uiKey
        : REGISTRATION_MAPPING.INVOICE_ADDRESS.apiKey;

      if (data.hasOwnProperty(invoiceAddressKey)) {
        const sourceInvoiceAddress = get(data, invoiceAddressKey);
        const invoiceAddress = {};

        const isInvoiceAddressDefined =
          sourceInvoiceAddress &&
          Object.entries(sourceInvoiceAddress).some(
            ([key, value]) =>
              Boolean(value) &&
              ![
                REGISTRATION_MAPPING.INVOICE_ADDRESS.RECIPIENT[
                  isPayloadUsingUiKeys ? "uiKey" : "apiKey"
                ],
                REGISTRATION_MAPPING.INVOICE_ADDRESS.ADDRESS_TYPE[
                  isPayloadUsingUiKeys ? "uiKey" : "apiKey"
                ],
              ].includes(key)
          );

        if (isInvoiceAddressDefined) {
          const updateInvoiceAddressIfAllowed = (mapping, defaultValue) => {
            const key = isPayloadUsingUiKeys ? mapping.uiKey : mapping.apiKey;

            let valueToSet = get(sourceInvoiceAddress, key, defaultValue);

            if (
              sourceInvoiceAddress.hasOwnProperty(key) &&
              (valueToSet || !shouldAvoidEmptyingValues)
            ) {
              if (typeof valueToSet === "string") {
                valueToSet = valueToSet.replace(/\n/g, " ");
              }
              set(invoiceAddress, mapping.uiKey, valueToSet);
            }
          };

          updateInvoiceAddressIfAllowed(
            REGISTRATION_MAPPING.INVOICE_ADDRESS.COUNTRY_CODE
          );
          updateInvoiceAddressIfAllowed(REGISTRATION_MAPPING.INVOICE_ADDRESS.RECIPIENT);
          updateInvoiceAddressIfAllowed(
            REGISTRATION_MAPPING.INVOICE_ADDRESS.ADDRESS_TYPE
          );
          updateInvoiceAddressIfAllowed(REGISTRATION_MAPPING.INVOICE_ADDRESS.STREET);
          updateInvoiceAddressIfAllowed(REGISTRATION_MAPPING.INVOICE_ADDRESS.POSTAL_CODE);
          updateInvoiceAddressIfAllowed(REGISTRATION_MAPPING.INVOICE_ADDRESS.CITY);
          updateInvoiceAddressIfAllowed(REGISTRATION_MAPPING.INVOICE_ADDRESS.ITEMS);
          updateInvoiceAddressIfAllowed(
            REGISTRATION_MAPPING.INVOICE_ADDRESS.COMPANY_NAME
          );
          updateInvoiceAddressIfAllowed(REGISTRATION_MAPPING.INVOICE_ADDRESS.DEPARTMENT);

          set(state, REGISTRATION_MAPPING.INVOICE_ADDRESS.uiKey, invoiceAddress);
        } else {
          set(state, REGISTRATION_MAPPING.INVOICE_ADDRESS.uiKey, null);
        }

        if (shouldSetOriginalInvoiceAddress) {
          set(
            state,
            REGISTRATION_MAPPING.INVOICE_ADDRESS_ORIGINAL.uiKey,
            isInvoiceAddressDefined ? invoiceAddress : null
          );
        }
      }

      const guestKey = isPayloadUsingUiKeys
        ? REGISTRATION_MAPPING.GUEST.uiKey
        : REGISTRATION_MAPPING.GUEST.apiKey;

      if (data.hasOwnProperty(guestKey)) {
        const sourceGuest = get(data, guestKey);
        const stateGuest = get(state, REGISTRATION_MAPPING.GUEST.uiKey, {});

        const updateGuestIfAllowed = (mapping, defaultValue) => {
          const key = isPayloadUsingUiKeys ? mapping.uiKey : mapping.apiKey;

          const valueToSet = get(sourceGuest, key, defaultValue);

          if (
            sourceGuest.hasOwnProperty(key) &&
            (valueToSet || !shouldAvoidEmptyingValues)
          ) {
            set(stateGuest, mapping.uiKey, valueToSet);
          }
        };

        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.SALUTATION);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.UUID);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.SALUTATION_UUID);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.GENDER);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.FIRST_NAME);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.LAST_NAME);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.LICENSE_PLATE);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.STREET);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.POSTAL_CODE);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.CITY);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.COUNTRY_CODE);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.DATE_OF_BIRTH);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.CITIZENSHIP_CODE);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.EMAIL);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.PHONE);
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_NUMBER);
        updateGuestIfAllowed(
          REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_TYPE_CODE
        );
        updateGuestIfAllowed(
          REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE
        );
        updateGuestIfAllowed(
          REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_AUTHORITY
        );
        updateGuestIfAllowed(REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_IMAGE);

        set(state, REGISTRATION_MAPPING.GUEST.uiKey, stateGuest);
      }
    })
    .addCase(SET_FORM_FIELD_REQUIREMENTS, (state, action) => {
      const formFieldRequirements = {};

      const updateFormFieldRequirements = getUpdateObjectFn(
        action.payload,
        formFieldRequirements
      );

      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.SALUTATION_UUID
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.GENDER
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.FIRST_NAME
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.LAST_NAME
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.LICENSE_PLATE
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.DATE_OF_BIRTH
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.PHONE
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.EMAIL
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.STREET
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.TRAVEL_PURPOSE
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.POSTAL_CODE
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.CITY
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.COUNTRY_CODE
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.CITIZENSHIP_CODE
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.IDENTIFICATION_DOCUMENT_NUMBER
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST
          .IDENTIFICATION_DOCUMENT_TYPE_CODE
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST.IDENTIFICATION_DOCUMENT_IMAGE
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST
          .IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE
      );
      updateFormFieldRequirements(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.GUEST
          .IDENTIFICATION_DOCUMENT_AUTHORITY
      );

      const accompanyingGuests = {};
      const updateAccompanyingGuests = getUpdateObjectFn(
        get(
          action.payload,
          REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS.apiKey
        ),
        accompanyingGuests
      );

      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS.SALUTATION_UUID
      );
      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS.GENDER
      );
      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS.FIRST_NAME
      );
      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS.LAST_NAME
      );
      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS.DATE_OF_BIRTH
      );
      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS.PHONE
      );
      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS.CITIZENSHIP_CODE
      );
      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS
          .IDENTIFICATION_DOCUMENT_NUMBER
      );
      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS
          .IDENTIFICATION_DOCUMENT_TYPE_CODE
      );
      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS
          .IDENTIFICATION_DOCUMENT_IMAGE
      );
      updateAccompanyingGuests(
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS
          .IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE
      );

      set(
        formFieldRequirements,
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS.uiKey,
        accompanyingGuests
      );
      set(
        state,
        REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.uiKey,
        formFieldRequirements
      );
    })
    .addCase(IS_PRIVATE_INVOICE_ADDRESS, (state, action) => {
      set(
        state,
        REGISTRATION_MAPPING.IS_PRIVATE_INVOICE_ADDRESS.uiKey,
        action.payload || false
      );
    })
    .addCase(SET_BILLING_ADDRESS_CHANGED, (state, action) => {
      set(
        state,
        REGISTRATION_MAPPING.BILLING_ADDRESS_CHANGED.uiKey,
        action.payload || false
      );
    })
    .addCase(SET_ID_DOCUMENT_SCANS, (state, action) => {
      set(
        state,
        REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.uiKey,
        action.payload || state[REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.uiKey]
      );
    });
});

export {registrationReducer};
