import {
  MOBILE_CARD_KEYS_MAPPING,
  SET_ADDITIONAL_KEY_CARDS_ALLOWED,
  SET_ALLOW_EXTRA_KEYS,
  SET_BLE_KEYS,
  SET_KEY_CARDS_DATA,
  SET_MOBILE_KEY_DATA,
  SET_REGISTERED_DEVICE,
  SET_WEB_KEYS,
} from "../../../constants";

import {createReducer} from "@reduxjs/toolkit";
import {getUpdateObjectFn} from "../../../utils/state.utils";
import {set} from "lodash";

let initialState = {
  card: {
    allowed: 0,
    received: 0,
    written: 0,
    additionalAllowed: false,
    allowExtraKeys: false,
  },
  mobile: {
    provider: "",
    key: "",
    note: null,
    vendor: "",
    isEmptyKeyList: false,
    allowed: false,
    parameters: {},
    webKeys: [],
    bleKeys: [],
  },
  registeredDevice: {
    name: "",
    invitationCode: "",
  },
};

const keysReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_KEY_CARDS_DATA, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      updateState(MOBILE_CARD_KEYS_MAPPING.CARD.ALLOWED, 0);
      updateState(MOBILE_CARD_KEYS_MAPPING.CARD.RECEIVED, 0);
      updateState(MOBILE_CARD_KEYS_MAPPING.CARD.WRITTEN, 0);
    })
    .addCase(SET_ADDITIONAL_KEY_CARDS_ALLOWED, (state, action) => {
      set(state, MOBILE_CARD_KEYS_MAPPING.CARD.ADDITIONAL_ALLOWED, action.payload);
    })
    .addCase(SET_ALLOW_EXTRA_KEYS, (state, action) => {
      set(state, MOBILE_CARD_KEYS_MAPPING.CARD.ALLOW_EXTRA_KEYS.uiKey, action.payload);
    })
    .addCase(SET_MOBILE_KEY_DATA, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      updateState(MOBILE_CARD_KEYS_MAPPING.MOBILE.PROVIDER, "");
      updateState(MOBILE_CARD_KEYS_MAPPING.MOBILE.KEY, "");
      updateState(MOBILE_CARD_KEYS_MAPPING.MOBILE.NOTE, null);
      updateState(MOBILE_CARD_KEYS_MAPPING.MOBILE.VENDOR, "");
      updateState(MOBILE_CARD_KEYS_MAPPING.MOBILE.ACCESSIBLE_DOORS, "");
      updateState(MOBILE_CARD_KEYS_MAPPING.MOBILE.OPEN_DOORS, "");
      updateState(MOBILE_CARD_KEYS_MAPPING.MOBILE.ALLOWED, false);
    })
    .addCase(SET_WEB_KEYS, (state, action) => {
      const webKeys = (action.payload || []).map((key) => {
        const mappedKey = {};

        const updateKey = getUpdateObjectFn(key, mappedKey);

        updateKey(MOBILE_CARD_KEYS_MAPPING.MOBILE.WEB_KEYS.ID);
        updateKey(MOBILE_CARD_KEYS_MAPPING.MOBILE.WEB_KEYS.TITLE);

        return mappedKey;
      });

      set(state, MOBILE_CARD_KEYS_MAPPING.MOBILE.WEB_KEYS.uiKey, webKeys);
    })
    .addCase(SET_BLE_KEYS, (state, action) => {
      set(state, MOBILE_CARD_KEYS_MAPPING.MOBILE.BLE_KEYS.uiKey, action.payload);
    })
    .addCase(SET_REGISTERED_DEVICE, (state, action) => {
      const updateState = getUpdateObjectFn(action.payload, state);

      updateState(MOBILE_CARD_KEYS_MAPPING.REGISTERED_DEVICE.NAME, "");
      updateState(MOBILE_CARD_KEYS_MAPPING.REGISTERED_DEVICE.INVITATION_CODE.apiKey, "");
    });
});

export {keysReducer};
