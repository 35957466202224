import {
  FLOWS,
  MODULE_TYPES,
  ROUTES_MAPPING,
  SET_CURRENT_FLOW,
  SET_CURRENT_NEXT,
  SET_FLOW,
  SET_HAS_CHECKOUT_STARTED_FOR_NATIVE,
  SET_NEXT,
} from "../../../constants";

import {createReducer} from "@reduxjs/toolkit";
import {set} from "lodash";

let initialState = {
  flow: "",
  next: "",
  currentNext: "",
  currentFlow: "",
  hasCheckOutStartedForNative: false,
};

const routesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_FLOW, (state, action) => {
      let type =
        action.payload === FLOWS.CHECK_IN
          ? MODULE_TYPES.CHECK_IN
          : action.payload === FLOWS.CHECK_OUT
          ? MODULE_TYPES.CHECK_OUT
          : MODULE_TYPES.PRE_CHECK_IN;

      set(state, ROUTES_MAPPING.FLOW.uiKey, action.payload);
      set(state, ROUTES_MAPPING.TYPE.uiKey, type);
    })
    .addCase(SET_NEXT, (state, action) => {
      set(state, ROUTES_MAPPING.NEXT.uiKey, action.payload);
    })
    .addCase(SET_CURRENT_NEXT, (state, action) => {
      set(state, ROUTES_MAPPING.CURRENT_NEXT.uiKey, action.payload);
    })
    .addCase(SET_CURRENT_FLOW, (state, action) => {
      set(state, ROUTES_MAPPING.CURRENT_FLOW.uiKey, action.payload);
    })
    .addCase(SET_HAS_CHECKOUT_STARTED_FOR_NATIVE, (state, action) => {
      set(state, ROUTES_MAPPING.HAS_CHECKOUT_STARTED_FOR_NATIVE.uiKey, action.payload);
    });
});

export {routesReducer};
