const translations = {
  payment: {
    title: "Payment",
    subtitle: "Please finish the payment process to continue",
    onProcessPayment: "Your payment is processed...\nPlease wait.",
    declinedByProvider: "The transaction has been declined.\nPlease try again.",
    paymentIncomplete: "Your payment could not be completed.\nPlease try again.",
    errorTitle: "Payment failed",
    successTitle: "Payment completed successfully",
    pay: "Pay",
    returnToInvoice: "Return to invoice",
  },
};

export {translations};
