import {GO_HOME__SAGA, ROUTE_PATHS} from "../../../constants";
import {hideAllNotifications, purge, toggleConfirmGoHomeModal} from "../../actions";
import {put, takeEvery} from "@redux-saga/core/effects";

import {push} from "connected-react-router";

function* goHome({payload}) {
  const hasConfirmModal = payload?.hasConfirmModal;

  if (hasConfirmModal) {
    yield put(toggleConfirmGoHomeModal(true));
  } else {
    if (window.KioskUtils?.goHome) {
      window.KioskUtils.goHome();
    } else {
      yield put(push(ROUTE_PATHS.HOME.PATHNAME));
    }

    yield put(hideAllNotifications());
    yield put(purge({whiteList: ["router", "appConfigReducer"]}));
  }
}

export function* goHomeSaga() {
  yield takeEvery(GO_HOME__SAGA, goHome);
}
