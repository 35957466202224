import "./ButtonGroup.scss";

import React from "react";

import PropTypes from "prop-types";

const ButtonGroup = ({children, className = "", isStacked, ...args}) => {
  return (
    <div
      className={`button-group ${isStacked ? "button-group--stacked" : ""} ${className}`}
      {...args}>
      {children}
    </div>
  );
};

ButtonGroup.propTypes = {
  children: PropTypes.any,
  isStacked: PropTypes.bool,
  className: PropTypes.string,
};

export {ButtonGroup};
