import "./localization/i18next";
import "./utils/mobileStatusbar.utils";

import React, {useCallback, useEffect} from "react";
import {
  defineComponents,
  DocumentReaderService,
} from "@regulaforensics/vp-frontend-document-components-beta";

import {checkDeviceIdQueryParam, getBaseUrl} from "./utils/route.utils";

import {Plugins} from "@capacitor/core";
import {Provider} from "./Provider.component";
import {Root} from "./domains/Router/Root";
import {isNativeApp} from "./utils/mobileDevice.utils";
import {isPhone} from "./utils/environment.utils";

const App = () => {
  const {Keyboard} = Plugins;

  const setAppHeightCssProp = useCallback(() => {
    document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`);
  }, []);

  useEffect(() => {
    window.RegulaDocumentSDK = new DocumentReaderService();

    defineComponents().then(async () => {
      await window.RegulaDocumentSDK.prepare();
    });

    window.addEventListener("resize", setAppHeightCssProp);
    window.addEventListener("orientationchange", setAppHeightCssProp);

    if (isNativeApp) {
      Keyboard.addListener("keyboardDidHide", setAppHeightCssProp);
    }

    setAppHeightCssProp();

    return () => {
      window.removeEventListener("resize", setAppHeightCssProp);
      window.removeEventListener("orientationchange", setAppHeightCssProp);
    };
  }, [setAppHeightCssProp, Keyboard]);

  useEffect(() => {
    checkDeviceIdQueryParam();

    if (isPhone()) {
      document.querySelector("body").classList.add("mobile-env");
    } else {
      document.querySelector("body").classList.remove("mobile-env");
    }
  }, []);

  return (
    <>
      <link rel="stylesheet" href={`${getBaseUrl()}api/v2/stylesheet`} />
      <Provider>
        <Root />
      </Provider>
    </>
  );
};

export {App};
