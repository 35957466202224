import {STEPS} from "./global.constants";

export const ROUTE_PATHS = {
  HOME: {PATHNAME: "/", API_FIRST_STEPS: [STEPS.IDENTIFY]},
  AUTHENTICATION: {PATHNAME: "/search", API_FIRST_STEPS: []},
  RESERVATION_OVERVIEW: {
    PATHNAME: "/reservation-details",
    API_FIRST_STEPS: [STEPS.GUEST_REGISTRATION],
  },
  GUEST_REGISTRATION: {
    PATHNAME: "/registration",
    API_FIRST_STEPS: [],
    CHILDREN: {
      ADDRESS: {PATHNAME: "/address", API_FIRST_STEPS: []},
      MORE_DATA: {PATHNAME: "/more-data", API_FIRST_STEPS: []},
    },
  },
  PERSONAL_DATA: {PATHNAME: "/personal-data", API_FIRST_STEPS: []},
  SIGNATURE: {PATHNAME: "/signature", API_FIRST_STEPS: [STEPS.SIGNATURE]},
  COVID_QR_CODE_SCAN: {PATHNAME: "/covid", API_FIRST_STEPS: [STEPS.COVID_QR_CODE_SCAN]},
  COST_OVERVIEW: {
    PATHNAME: "/invoice",
    API_FIRST_STEPS: [STEPS.PAYMENT, STEPS.COST_OVERVIEW],
    CHILDREN: {SPLIT: {PATHNAME: "/split", API_FIRST_STEPS: []}},
  },
  PAYMENT: {
    PATHNAME: "/payment",
    API_FIRST_STEPS: [],
    CHILDREN: {
      PAYMENT_SELECT: {PATHNAME: "/select", API_FIRST_STEPS: []},
      PAYMENT_FINISH: {PATHNAME: "/finish", API_FIRST_STEPS: []},
    },
  },
  KEY: {PATHNAME: "/key", API_FIRST_STEPS: [STEPS.KEY_ENCODING]},
  KEY_OVERVIEW: {PATHNAME: "/key-overview", API_FIRST_STEPS: []},
  CHECKIN_FINISH: {PATHNAME: "/finish", API_FIRST_STEPS: [STEPS.CHECKIN_FINISH]},
  CHECKOUT_FINISH: {PATHNAME: "/finish", API_FIRST_STEPS: [STEPS.CHECKOUT_FINISH]},
  PRE_CHECKIN_SUMMARY: {
    PATHNAME: "/summary",
    API_FIRST_STEPS: [STEPS.PRE_CHECKIN_SUMMARY],
  },
  CHECKIN_SUMMARY: {PATHNAME: "/summary", API_FIRST_STEPS: [STEPS.CHECKIN_SUMMARY]},
  CHECKOUT_SUMMARY: {PATHNAME: "/summary", API_FIRST_STEPS: [STEPS.CHECKOUT_SUMMARY]},
  REGISTRATION_FORM_COMPLETED: {
    PATHNAME: "/registration-finish",
    API_FIRST_STEPS: [STEPS.REGISTRATION_FORM_COMPLETED],
  },
  CHECKOUT_START: {
    PATHNAME: "/start",
    API_FIRST_STEPS: [],
  },
};
