import i18next from "i18next";
import {get} from "lodash";
import {now} from "moment";
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";

import {
  POST_LOOKUP_FIELDS__SAGA,
  RESERVATION_MAPPING,
} from "../../../constants";
import {getUpdateObjectFn} from "../../../utils/state.utils";
import {
  apiErrorSagaAction,
  generalDataSagaAction,
  hideLoadingOverlay,
  setReservation,
  setReservationDataSagaAction,
  setReservationFetchTime,
  showLoadingOverlay,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postLookupFields(action) {
  const {searchData, reject} = action.payload;

  try {
    yield put(showLoadingOverlay(i18next.t("findBooking:loadingSearchResults")));
    const client = yield* getClientTypeFn();
    const lookupResponse = yield call([client, "postLookupFields"], searchData);

    yield put(setReservationFetchTime(now()));

    const reservation = {};

    const updateReservation = getUpdateObjectFn(
      get(lookupResponse.data, RESERVATION_MAPPING.apiKey),
      reservation,
      true
    );

    updateReservation(RESERVATION_MAPPING.CODE, "");
    updateReservation(RESERVATION_MAPPING.NAME, "");

    yield put(setReservation(reservation));
    yield put(setReservationDataSagaAction({response: lookupResponse}));
    yield put(generalDataSagaAction());
  } catch (e) {
    yield put(hideLoadingOverlay());

    yield put(apiErrorSagaAction({error: e, isAllowedRetry: true}));

    if (reject) {
      reject(e.response?.data);
    }
  }
}

export function* postLookupFieldsSaga() {
  yield takeEvery(POST_LOOKUP_FIELDS__SAGA, postLookupFields);
}
