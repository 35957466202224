const translations = {
  signature: {
    title: "Sign to Confirm Your Information",
    subtitle:
      "Please confirm your personal data with your signature. Use your finger or mouse to sign.",
  },
  ccSignature: {
    title: "Confirm your identity",
    info: "Please confirm your personal data using Secure Customer Authentication",
  },
};

export {translations};
