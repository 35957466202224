import {get} from "lodash";
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";

import {
  GET_LOOKUP_FIELDS__SAGA,
  LOOKUP_FIELDS_MAPPING,
} from "../../../constants";
import {
  apiErrorSagaAction,
  setLookupFields,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

// todo: refactor sagas that use the Promise pattern to avoid it, e.g. by moving the logic straight to components or by dividing into SAGA__ERROR, SAGA__SUCCESS, etc.
function* getLookupFields(action) {
  const {resolve, reject} = action.payload;

  try {
    const client = yield* getClientTypeFn();
    const lookupResponse = yield call([client, "getLookupFields"]);

    yield put(
      setLookupFields(
        get(lookupResponse.data, LOOKUP_FIELDS_MAPPING.MAIN_GUEST.apiKey, {})
      )
    );

    if (resolve) {
      resolve();
    }
  } catch (e) {
    yield put(apiErrorSagaAction({error: e, isAllowedRetry: true}));

    if (reject) {
      reject(e);
    }
  }
}

export function* getLookupFieldsSaga() {
  yield takeEvery(GET_LOOKUP_FIELDS__SAGA, getLookupFields);
}
