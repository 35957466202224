import "./InactivityOverlay.scss";

import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Modal} from "../Modal";
import PropTypes from "prop-types";
import {ReactComponent as TouchIcon} from "../../assets/images/Icon_monitor_touch.svg";
import {goHomeAction} from "../../store/actions";
import {usePreviousValue} from "../../utils/customHooks/previousValue.hook";
import {useTranslation} from "react-i18next";

const DEFAULT_IDLE_VALUE_MS = 180000;
const DEFAULT_IDLE_COUNTDOWN_S = 60;
let timer, counter;

const InactivityOverlay = ({idleValueMs = DEFAULT_IDLE_VALUE_MS}) => {
  const {t} = useTranslation(["inactivity", "general"]),
    dispatch = useDispatch();
  const general = useSelector(({generalReducer}) => generalReducer),
    {isTerminal} = general;
  const idleCountdown = useSelector(
    ({appConfigReducer}) =>
      appConfigReducer.configuration?.inactivityTimeout || DEFAULT_IDLE_COUNTDOWN_S
  );

  const [isVisible, setIsVisible] = useState(false),
    [seconds, setSeconds] = useState(idleCountdown);

  const isPreviouslyVisible = usePreviousValue(isVisible),
    previousSeconds = usePreviousValue(seconds);

  const startTimer = useCallback(() => {
    timer = setTimeout(() => {
      if (window.location.pathname !== "/") {
        setIsVisible(true);
      }
    }, idleValueMs);
  }, [idleValueMs]);

  const stopTimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  }, []);

  const startCounter = useCallback(() => {
    setSeconds(idleCountdown);

    counter = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
  }, [idleCountdown]);

  const reset = useCallback(() => {
    if (counter) {
      clearInterval(counter);
      counter = null;
    }

    stopTimer();
    setIsVisible(false);
  }, [stopTimer]);

  const navigateToStartPage = useCallback(() => {
    reset();
    dispatch(goHomeAction());
  }, [reset, dispatch]);

  const handleClickScreen = useCallback(() => {
    if (!isTerminal) {
      return;
    }
    reset();
    startTimer();
  }, [isTerminal, reset, startTimer]);

  useEffect(() => {
    if (!isTerminal) {
      return;
    }

    startTimer();
  }, [isTerminal, startTimer]);

  useEffect(() => {
    document.addEventListener("click", handleClickScreen);

    return () => {
      document.removeEventListener("click", handleClickScreen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVisible && !isPreviouslyVisible) {
      stopTimer();
      startCounter();
    }
  }, [isVisible, isPreviouslyVisible, stopTimer, startCounter]);

  useEffect(() => {
    if (seconds === 0 && previousSeconds !== 0) {
      navigateToStartPage();
    }
  }, [seconds, previousSeconds, navigateToStartPage]);

  return (
    <>
      <Modal
        isVisible={isVisible}
        title={t("info", {seconds})}
        className="inactivity-overlay"
        doesHideOnBlur>
        <div className="inactivity-overlay__container">
          <TouchIcon className="inactivity-overlay__icon" />
          {t("toContinue")}
        </div>
      </Modal>
    </>
  );
};

InactivityOverlay.propTypes = {
  idleValueMs: PropTypes.number,
};

export {InactivityOverlay};
