const positions = {left: "left", center: "center"};

const sizes = {
  small: "small",
  default: "default",
  large: "large",
  extraLarge: "extra-large",
};

export {positions, sizes};
