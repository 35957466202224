import {get} from "lodash";
import {call, put, takeEvery} from "redux-saga/effects";

import {
  GENERAL_DATA__SAGA,
  GENERAL_DATA_MAPPING,
  TRANSLATIONS_MAPPING,
} from "../../../constants";
import {
  setCitizenships,
  setCountries,
  setGenders,
  setIdDocTypes,
  setSalutations,
  setSiteData,
  setTranslations,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* generalData() {
  try {
    const client = yield* getClientTypeFn();
    const translationsResp = yield call([client, "getApplicationTranslations"]);

    yield put(setTranslations(get(translationsResp?.data, TRANSLATIONS_MAPPING.apiKey)));

    const countriesResp = yield call([client, "getCountries"]);

    if (countriesResp.data) {
      const countries = get(
        countriesResp.data,
        GENERAL_DATA_MAPPING.COUNTRIES.apiKey,
        []
      );

      yield put(setCountries(countries));
    }

    const singleSiteDataResp = yield call([client, "getSingleSiteData"]);

    if (singleSiteDataResp.data) {
      yield put(setSiteData(singleSiteDataResp.data));
    }

    const citizenshipsResp = yield call([client, "getCitizenships"]);

    if (citizenshipsResp.data) {
      const citizenships = get(
        citizenshipsResp.data,
        GENERAL_DATA_MAPPING.CITIZENSHIPS.apiKey,
        []
      );

      yield put(setCitizenships(citizenships));
    }

    const gendersResp = yield call([client, "getGenders"]);

    if (gendersResp.data) {
      yield put(setGenders(gendersResp.data));
    }

    const salutationResp = yield call([client, "getSalutations"]);

    if (salutationResp.data) {
      const salutations = salutationResp.data || [];

      yield put(setSalutations(salutations));
    }

    const idDocTypeResp = yield call([client, "getIdDocumentTypes"]);

    if (idDocTypeResp.data) {
      const idDocTypes = idDocTypeResp.data || [];
      yield put(setIdDocTypes(idDocTypes));
    }
  } catch (error) {}
}

export function* generalDataSaga() {
  yield takeEvery(GENERAL_DATA__SAGA, generalData);
}
