import {flatMapDeep, merge} from "lodash";

import {ReactComponent as CheckInFailedIcon} from "../assets/images/checkin_failed.svg";
import {ReactComponent as CheckoutNotPossibleIcon} from "../assets/images/checkout_not_possible.svg";
import {ReactComponent as GenericErrorIcon} from "../assets/images/generic_error.svg";
import {ReactComponent as CheckoutNotArrivalYetIcon} from "../assets/images/not_arrival_yet.svg";
import {ReactComponent as InconsistentPaymentIcon} from "../assets/images/payment_inconsistent.svg";
import {ReactComponent as CheckoutInvoiceIcon} from "../assets/images/please_finish_payment.svg";
import {ReactComponent as CheckInNoRoomAssignedIcon} from "../assets/images/room_not_ready.svg";
import {NOTIFICATION_TYPES} from "../constants";
import {translations} from "../localization/en/notification";

export const notificationMessages = translations.notification;

const getNotificationNameInSource = (
  notificationText,
  source,
  currentAnalyzedPath = []
) => {
  for (let entry of Object.entries(source)) {
    const [name, value] = entry;

    if (value === notificationText) {
      return `${currentAnalyzedPath.join("")}${name}`;
    }

    if (typeof value === "string") {
      continue;
    }

    currentAnalyzedPath.push(`${name}.`);

    const resultFromRecursion = getNotificationNameInSource(
      notificationText,
      value,
      currentAnalyzedPath
    );

    if (resultFromRecursion) {
      return resultFromRecursion;
    } else {
      currentAnalyzedPath.pop();
    }
  }
};

export const getNotificationObjectByText = (text) => {
  return Object.values(plainNotificationMappingsWithoutCategories).find((mapping) => {
    return mapping.NOTIFICATION_KEY === getNotificationNameByText(text);
  });
};

export const getNotificationNameByText = (text) => {
  const notificationName = getNotificationNameInSource(text, translations.notification);

  return `notification:${notificationName}`;
};

const NOTIFICATION_MAPPINGS = {
  // todo: review. Some candidates for deprecation exist.
  CHECK_IN: {
    CHECKIN_NOT_AVAILABLE: {
      ERROR_CODE: "CHECKIN_NOT_AVAILABLE",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.checkInNotAvailable
      ),
      ICON: CheckInFailedIcon,
      IS_PERSISTENT: true,
    },
    CHECKIN_NO_ROOM_ASSIGNED: {
      ERROR_CODE: "CHECKIN_NO_ROOM_ASSIGNED",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.checkInNoRoomAssigned
      ),
      ICON: CheckInFailedIcon,
      IS_PERSISTENT: true,
    },
    CHECKIN_NO_ROOM_ASSIGNABLE: {
      ERROR_CODE: "CHECKIN_NO_ROOM_ASSIGNABLE",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.checkinRoomNotReady
      ),
      TYPE: NOTIFICATION_TYPES.WARNING,
      ICON: CheckInNoRoomAssignedIcon,
      IS_PERSISTENT: true,
    },
    CHECKIN_ROOM_NOT_READY: {
      ERROR_CODE: "CHECKIN_ROOM_NOT_READY",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.checkinRoomNotReady
      ),
      TYPE: NOTIFICATION_TYPES.WARNING,
      ICON: CheckInNoRoomAssignedIcon,
      IS_PERSISTENT: true,
    },
  },

  CHECKOUT: {
    CHECKOUT_WRONG_TIME: {
      ERROR_CODE: "CHECKOUT_WRONG_TIME",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.checkoutWrongTime
      ),
      ICON: CheckoutNotArrivalYetIcon,
      IS_PERSISTENT: true,
    },
    CHECKOUT_NOT_AVAILABLE: {
      ERROR_CODE: "CHECKOUT_NOT_AVAILABLE",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.checkoutNotAvailable
      ),
      ICON: CheckoutNotPossibleIcon,
      IS_PERSISTENT: true,
    },
    CHECKOUT_OPEN_INVOICE: {
      ERROR_CODE: "CHECKOUT_OPEN_INVOICE",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.checkOutOpenInvoice
      ),
      ICON: CheckoutInvoiceIcon,
      TYPE: NOTIFICATION_TYPES.WARNING,
      IS_PERSISTENT: true,
    },
  },

  GENERAL: {
    TECHNICAL_PMS: {
      ERROR_CODE: "TECHNICAL_PMS",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.technical
      ),
      ICON: GenericErrorIcon,
      IS_PERSISTENT: true,
    },
    TECHNICAL: {
      ERROR_CODE: "TECHNICAL",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.technical
      ),
      ICON: GenericErrorIcon,
      IS_PERSISTENT: true,
    },
    TECHNICAL_ERROR_BY_PAYMENT_PROVIDER: {
      ERROR_CODE: "TECHNICAL_PAYMENT",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.technical
      ),
      ICON: GenericErrorIcon,
      IS_PERSISTENT: true,
    },
    PAYMENT_INCONSISTENT: {
      ERROR_CODE: "ERROR_PAYMENT_IN_INCONSISTENT_STATE",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.inconsistentPayment
      ),
      ICON: InconsistentPaymentIcon,
      IS_PERSISTENT: true,
    },
    TECHNICAL_ERROR_BY_DOOR_LOCK_PROVIDER: {
      ERROR_CODE: "TECHNICAL_DOORLOCK",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.technical
      ),
      ICON: GenericErrorIcon,
      IS_PERSISTENT: true,
    },
  },

  AUTHENTICATION: {
    NAME_NOT_FOUND: {
      ERROR_CODE: "INVALID_NAME",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.invalidAuthName
      ),
      IS_PERSISTENT: false,
    },
    INCORRECT_CODE: {
      ERROR_CODE: "INVALID_CODE",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.invalidAuthBookingNumber
      ),
      IS_PERSISTENT: false,
    },
    NO_RESERVATION_FOUND: {
      ERROR_CODE: "NO_RESERVATION_FOUND",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.reservationNotFound
      ),
      IS_PERSISTENT: false,
    },
    RESERVATION_NOT_FOUND: {
      ERROR_CODE: "RESERVATION_NOT_FOUND",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.reservationNotFound
      ),
      IS_PERSISTENT: false,
    },
    MORE_THAN_ONE_RESERVATION_WITH_THE_SAME_DATA: {
      ERROR_CODE: "RESERVATION_COULD_NOT_BE_IDENTIFIED",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.moreThanOneReservationWithTheSameData
      ),
      IS_PERSISTENT: false,
    },
  },

  UI_ERRORS: {
    CAMERA_ACCESS_DENIED: {
      ERROR_CODE: "NotAllowedError",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.cameraAccessDenied
      ),
      IS_PERSISTENT: false,
    },
    NO_VIDEO_INPUT_DEVICE: {
      ERROR_CODE: "NoVideoInputDevicesError",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.noVideoInputDevicesError
      ),
      IS_PERSISTENT: false,
    },
    BLUETOOTH_NOT_GRANTED: {
      ERROR_CODE: "BLUETOOTH_NOT_GRANTED",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.bluetoothNotGranted
      ),
      IS_PERSISTENT: false,
    },
    BLUETOOTH_NOT_ENABLED: {
      ERROR_CODE: "BLUETOOTH_NOT_ENABLED",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.bluetoothNotEnabled
      ),
      IS_PERSISTENT: false,
    },
    DOORLOCK_TIME_EXPIRED: {
      ERROR_CODE: "DOORLOCK_TIME_EXPIRED",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.error.mobileKey
      ),
      IS_PERSISTENT: false,
    },
    INVALID_QR_CODE: {
      ERROR_CODE: "InvalidQR",
      NOTIFICATION_KEY: getNotificationNameByText(
        translations.notification.warning.noValidQrCode
      ),
      IS_PERSISTENT: false,
    },
  },
};

const plainNotificationMappingsWithoutCategories = {};

Object.values(NOTIFICATION_MAPPINGS).forEach((mapping) =>
  merge(plainNotificationMappingsWithoutCategories, mapping)
);

Object.freeze(NOTIFICATION_MAPPINGS);

export const isKnownErrorCode = (code) => {
  return Object.values(NOTIFICATION_MAPPINGS).some((notification) => {
    return flatMapDeep(notification).some((prop) => prop.ERROR_CODE === code);
  });
};

export const getNotificationObjectByApiErrorCode = (apiErrorCode) => {
  return Object.values(plainNotificationMappingsWithoutCategories).find((mapping) => {
    return mapping.ERROR_CODE === apiErrorCode;
  });
};

export const getNotificationKeyByApiErrorCode = (apiErrorCode) => {
  return Object.values(plainNotificationMappingsWithoutCategories).find((mapping) => {
    return mapping.ERROR_CODE === apiErrorCode;
  })?.NOTIFICATION_KEY;
};

export const getIsPersistentByApiErrorCode = (apiErrorCode) => {
  return Object.values(plainNotificationMappingsWithoutCategories).find((mapping) => {
    return mapping.ERROR_CODE === apiErrorCode;
  })?.IS_PERSISTENT;
};

export {NOTIFICATION_MAPPINGS, plainNotificationMappingsWithoutCategories};
