const translations = {
  personalData: {
    title: "Bitte überprüfen Sie Ihre Informationen",
    subtitle: "Im nächsten Schritt werden die Daten bestätigt",
    address: "Adresse:",
    billingAddress: "Rechnungsadresse:",
    billingEqualsAddress: "Gastadresse",
    additionalGuests: "Weitere Gäste",
    notAdditionalGuests: "Keine weiteren Gäste registriert",
    guest: "Gastdaten",
    contact: "Kontakt:",
    additionalInformation: "Zusätzliche Informationen:",
    travelPurpose: "Reiseart:",
    processingSave: "Einen Moment bitte... \n\nIhre Daten werden verarbeitet...",
  },
};

export {translations};
