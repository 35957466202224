export const RESERVATION_MAPPING = {
  apiKey: "reservation",

  UUID: {
    uiKey: "uuid",
    apiKey: "uuid",
  },
  CODE: {
    uiKey: "code",
    apiKey: "code",
  },
  NAME: {
    uiKey: "name",
    apiKey: "lastName",
  },
  GUEST_COUNT: {
    uiKey: "guestCount",
    apiKey: "guestCount",
  },
  ARRIVAL_DATE: {
    uiKey: "arrivalDate",
    apiKey: "arrivalDate",
  },
  DEPARTURE_DATE: {
    uiKey: "departureDate",
    apiKey: "departureDate",
  },
  OVERNIGHT_STAYS: {
    uiKey: "overnightStays",
    apiKey: "overnightStays",
  },
  ROOM_CATEGORY_NAME: {
    uiKey: "roomCategoryName",
    apiKey: "roomCategory",
  },
  RATE_PLAN_NAME: {
    uiKey: "ratePlanName",
    apiKey: "ratePlanName",
  },
  RATE_PLAN_CODE: {
    uiKey: "ratePlanCode",
    apiKey: "ratePlanCode",
  },
  CHECK_OUT_AVAILABLE: {
    uiKey: "checkOutAvailable",
    apiKey: "checkOutAvailable",
  },
};

export const ROUTES_MAPPING = {
  FLOW: {
    uiKey: "flow",
    apiKey: "flow",
  },
  NEXT: {
    uiKey: "next",
    apiKey: "next",
  },
  CURRENT_NEXT: {
    uiKey: "currentNext",
  },
  CURRENT_FLOW: {
    uiKey: "currentFlow",
  },
  TYPE: {
    uiKey: "type",
  },
  HAS_CHECKOUT_STARTED_FOR_NATIVE: {
    uiKey: "hasCheckOutStartedForNative",
  },
};

export const APP_CONFIG_MAPPING = {
  START_PAGE_LAYOUT: {
    uiKey: "startPageLayout",
    apiKey: "layouts.0.main.widgets",

    TEMPLATE: {
      uiKey: "template",
      apiKey: "template",
    },
    LEFT: {
      uiKey: "left",
      apiKey: "position.left",
    },
    TOP: {
      uiKey: "top",
      apiKey: "position.top",
    },
    WIDTH: {
      uiKey: "width",
      apiKey: "position.width",
    },
    HEIGHT: {
      uiKey: "height",
      apiKey: "position.height",
    },
    TEXT_KEY: {
      uiKey: "contentKey",
      apiKey: "parameters.text_key",
    },
    DEFAULT_TEXT: {
      uiKey: "title",
      apiKey: "parameters.default_text",
    },
    URL: {
      uiKey: "link",
      apiKey: "parameters.url",
    },
    IMAGE: {
      uiKey: "image",
      apiKey: "parameters.image",
    },
  },

  CONFIGURATION: {
    uiKey: "configuration",
    apiKey: null,

    DEVICE: {
      uiKey: "configuration.device",
      apiKey: "device",
    },
    INACTIVITY_TIMEOUT: {
      uiKey: "configuration.inactivityTimeout",
      apiKey: "inactivityTimeout",
    },
  },

  SITE_SETTINGS: {
    uiKey: "siteSettings",
    apiKey: "site",

    ID: {
      uiKey: "siteSettings.id",
      apiKey: "site.id",
    },
    NAME: {
      uiKey: "siteSettings.name",
      apiKey: "site.name",
    },
    CURRENCY: {
      uiKey: "siteSettings.currency",
      apiKey: "site.currency",
    },
    COUNTRY: {
      uiKey: "siteSettings.address.country",
      apiKey: "site.address.country",
    },
    EMAIL: {
      uiKey: "siteSettings.contact.email",
      apiKey: "site.contact.email",
    },
    PHONE: {
      uiKey: "siteSettings.contact.phone",
      apiKey: "site.contact.phone",
    },
    WEBSITE: {
      uiKey: "siteSettings.contact.website",
      apiKey: "site.contact.website",
    },
  },

  PROCESS_CONFIGURATION: {
    uiKey: "process",
    apiKey: "processConfiguration",

    ALLOW_COMPANY_ADDRESS: {
      uiKey: "process.allowBillingAddress",
      apiKey: "allowBillingAddress",
    },
    ALLOW_ADDITIONAL_GUESTS: {
      uiKey: "process.allowAdditionalGuests",
      apiKey: "allowAdditionalGuests",
    },
    NO_PMS_UPDATE_OF_INVOICE_ADDRESS: {
      uiKey: "process.enableAutomaticBillingAddress",
      apiKey: "enableAutomaticBillingAddress",
    },
    DOCUMENT_SCANNING: {
      uiKey: "process.documentScanning",
      apiKey: "documentScanning",
    },
  },

  COVID_CONFIGURATION: {
    uiKey: "covid",
    apiKey: "covidConfiguration",

    ALLOW_CONTINUATION_WITHOUT_PROOF: {
      uiKey: "covid.allowContinuationWithoutProof",
      apiKey: "allowContinuationWithoutProof",
    },
  },

  PROCESS_OVERVIEW: {
    uiKey: "processOverview",
    apiKey: "processOverview",

    ADDITIONAL_INFO: {
      uiKey: "additionalInfo",
      apiKey: "additionalInfo",

      POP_UP: {
        uiKey: "isPopUp",
        apiKey: "popUp",
      },
      SHOW_AT_CHECKIN: {
        uiKey: "shouldShowAtCheckIn",
        apiKey: "showAtCheckIn",
      },
      SHOW_AT_CHECKOUT: {
        uiKey: "shouldShowAtCheckOut",
        apiKey: "showAtCheckOut",
      },
      TRANSLATION_CONTENT_KEY: {
        uiKey: "trContentKey",
        apiKey: "trContentKey",
      },
      TRANSLATION_TITLE_KEY: {
        uiKey: "trTitleKey",
        apiKey: "trTitleKey",
      },
      UNFOLDED_TEXT_BOX: {
        uiKey: "isUnfoldedTextBox",
        apiKey: "unfoldedTextBox",
      },
    },

    SHOW_KEYS_INFO_AT_CHECK_IN: {
      uiKey: "processOverview.showKeysInfoAtCheckIn",
      apiKey: "showKeysInfoAtCheckIn",
    },
    SHOW_KEYS_INFO_AT_CHECK_OUT: {
      uiKey: "processOverview.showKeysInfoAtCheckOut",
      apiKey: "showKeysInfoAtCheckOut",
    },
    TR_KEYS_INFO_CHECK_IN: {
      uiKey: "processOverview.trKeysInfoCheckIn",
      apiKey: "trKeysInfoCheckIn",
    },
    TR_KEYS_INFO_CHECK_OUT: {
      uiKey: "processOverview.trKeysInfoCheckOut",
      apiKey: "trKeysInfoCheckOut",
    },
  },
};

export const FOLIOS_MAPPING = {
  INVOICES: {
    uiKey: "invoices",
    apiKey: "folios",

    ID: {
      uiKey: "id",
      apiKey: "id",
    },
    UUID: {
      uiKey: "uuid",
      apiKey: "uuid",
    },
    IS_BUSINESS: {
      uiKey: "isBusiness",
      apiKey: "isBusiness",
    },
    COMPANY_NAME: {
      uiKey: "company",
      apiKey: "company",
    },
    DEPARTMENT: {
      uiKey: "department",
      apiKey: "department",
    },
    RECIPIENT: {
      uiKey: "recipient",
      apiKey: "recipient",
    },
    STREET: {
      uiKey: "street",
      apiKey: "street",
    },
    ADDRESS_LINES: {
      uiKey: "addressLines",
      apiKey: "addressLines",
    },
    POSTAL_CODE: {
      uiKey: "postalCode",
      apiKey: "postalCode",
    },
    CITY: {
      uiKey: "city",
      apiKey: "city",
    },
    COUNTRY_CODE: {
      uiKey: "countryCode",
      apiKey: "countryCode",
    },
    DUE_AMOUNT: {
      uiKey: "dueAmount",
      apiKey: "dueAmount",
    },
    PAYABLE_AMOUNT: {
      uiKey: "payableAmount",
      apiKey: "payableAmount",
    },
    RECEIVED_AMOUNT: {
      uiKey: "receivedAmount",
      apiKey: "receivedAmount",
    },
    GROSS_AMOUNT: {
      uiKey: "grossAmount",
      apiKey: "grossAmount",
    },
    NET_AMOUNT: {
      uiKey: "netAmount",
      apiKey: "netAmount",
    },
    CURRENCY_CODE: {
      uiKey: "currencyCode",
      apiKey: "currencyCode",
    },

    POSITIONS: {
      uiKey: "positions",
      apiKey: "positions",

      ID: {
        uiKey: "id",
        apiKey: "id",
      },
      UUID: {
        uiKey: "uuid",
        apiKey: "uuid",
      },
      NAME: {
        uiKey: "name",
        apiKey: "name",
      },
      DATE: {
        uiKey: "date",
        apiKey: "date",
      },
      ITEM_PRICE: {
        uiKey: "priceData.itemPrice",
        apiKey: "priceData.itemPrice",
      },
      QUANTITY: {
        uiKey: "quantity",
        apiKey: "quantity",
      },
    },
  },

  INVOICES_SPLIT_AVAILABLE: {
    uiKey: "invoiceSplitAvailable",
    apiKey: "payment.invoiceSplitAvailable",
  },
};

export const GENERAL_DATA_MAPPING = {
  IS_TERMINAL: {
    uiKey: "isTerminal",
    apiKey: null,
  },

  LANG: {
    uiKey: "lang",
    apiKey: null,
  },

  BOOKING_DATA_EXPIRED: {
    uiKey: "bookingDataExpired",
    apiKey: null,
  },

  COUNTRIES: {
    uiKey: "countries",
    apiKey: "items",

    CODE: {
      uiKey: "code",
      apiKey: "code",
    },
    NAME: {
      uiKey: "name",
      apiKey: "name",
    },
  },

  CITIZENSHIPS: {
    uiKey: "citizenships",
    apiKey: "items",

    CODE: {
      uiKey: "code",
      apiKey: "code",
    },
    NAME: {
      uiKey: "name",
      apiKey: "name",
    },
  },

  GENDERS: {
    uiKey: "genders",

    VALUE: {
      uiKey: "value",
      apiKey: "value",
    },

    TRANSLATION_KEY: {
      uiKey: "translationKey",
      apiKey: "translationKey",
    },
  },

  SALUTATIONS: {
    uiKey: "salutations",
    apiKey: null,

    ID: {
      uiKey: "id",
      apiKey: "id",
    },
    UUID: {
      uiKey: "uuid",
      apiKey: "uuid",
    },
    NAME: {
      uiKey: "name",
      apiKey: "name",
    },
    DISPLAY_NAME: {
      uiKey: "displayName",
      apiKey: "displayName",
    },
    GENDER: {
      uiKey: "gender",
      apiKey: "gender",
    },
    IS_DEFAULT: {
      uiKey: "isDefault",
      apiKey: "isDefault",
    },
  },

  ID_DOC_TYPES: {
    uiKey: "idDocTypes",
    apiKey: null,

    ID: {
      uiKey: "id",
      apiKey: "id",
    },
    UUID: {
      uiKey: "uuid",
      apiKey: "uuid",
    },
    CODE: {
      uiKey: "code",
      apiKey: "code",
    },
    NAME: {
      uiKey: "name",
      apiKey: "name",
    },
    DISPLAY_NAME: {
      uiKey: "displayName",
      apiKey: "displayName",
    },
  },

  SITE_DATA: {
    uiKey: "siteData",
    apiKey: null,

    CONTACT: {
      uiKey: "siteData.contact",
      apiKey: "contact",
    },
    WEBSITE: {
      uiKey: "siteData.contact.website",
      apiKey: "contact.website",
    },
    EMAIL: {
      uiKey: "siteData.contact.email",
      apiKey: "contact.email",
    },
    PHONE: {
      uiKey: "siteData.contact.phone",
      apiKey: "contact.phone",
    },
    LEGAL_TEXT_TRANSLATIONS: {
      uiKey: "siteData.siteLegalTextTranslations",
      apiKey: "siteLegalTextTranslations",

      LEGAL_TEXT_TRANSLATIONS_LANGUAGE_CODE: {
        uiKey: "languageCode",
        apiKey: "languageCode",
      },
      LEGAL_TEXT_TRANSLATIONS_TERMS_AND_CONDITIONS_URL: {
        uiKey: "termsAndConditionsUrl",
        apiKey: "termsAndConditionsUrl",
      },
    },
  },

  STEPS: {
    uiKey: "steps",
    apiKey: "processSteps",
  },

  ROOMS: {
    uiKey: "rooms",
    apiKey: "rooms",

    DESCRIPTION: {
      uiKey: "description",
      apiKey: "description",
    },
    NAME: {
      uiKey: "name",
      apiKey: "name",
    },
  },

  GUEST_AUTH_CONFIGURATION: {
    uiKey: "guestAuthConfiguration",
    apiKey: "guestAuthConfiguration",

    BY_RESERVATION_NUMBER_ENABLED: {
      uiKey: "guestAuthConfiguration.byReservationNumberEnabled",
      apiKey: "byReservationNumberEnabled",
    },
    BY_PERSONAL_DATA_ENABLED: {
      uiKey: "guestAuthConfiguration.byPersonalDataEnabled",
      apiKey: "byPersonalDataEnabled",
    },
    BY_QR_CODE_ENABLED: {
      uiKey: "guestAuthConfiguration.byQrCodeEnabled",
      apiKey: "byQrCodeEnabled",
    },
    LAST_NAME_REQUIRED: {
      uiKey: "guestAuthConfiguration.lastNameRequired",
      apiKey: "lastNameRequired",
    },
    FIRST_NAME_REQUIRED: {
      uiKey: "guestAuthConfiguration.firstNameRequired",
      apiKey: "firstNameRequired",
    },
    DATE_OF_BIRTH_REQUIRED: {
      uiKey: "guestAuthConfiguration.dateOfBirthRequired",
      apiKey: "dateOfBirthRequired",
    },
    ARRIVAL_DATE_REQUIRED: {
      uiKey: "guestAuthConfiguration.arrivalDateRequired",
      apiKey: "arrivalDateRequired",
    },
    DEPARTURE_DATE_REQUIRED: {
      uiKey: "guestAuthConfiguration.departureDateRequired",
      apiKey: "departureDateRequired",
    },
    EMAIL_REQUIRED: {
      uiKey: "guestAuthConfiguration.emailRequired",
      apiKey: "emailRequired",
    },
    RESERVATION_NUMBER_REQUIRED: {
      uiKey: "guestAuthConfiguration.reservationNumberRequired",
      apiKey: "reservationNumberRequired",
    },
  },
};

export const LOOKUP_FIELDS_MAPPING = {
  BOOKING_ID: {
    uiKey: "bookingId",
  },
  BOOKING_NAME: {
    uiKey: "lastName",
  },

  MAIN_GUEST: {
    uiKey: "requirements",
    apiKey: "reservationLookupFieldRequirements",

    FIRST_NAME: {
      uiKey: "firstName",
      apiKey: "firstName",
    },
    LAST_NAME: {
      uiKey: "lastName",
      apiKey: "lastName",
    },
    DATE_OF_BIRTH: {
      uiKey: "dateOfBirth",
      apiKey: "dateOfBirth",
    },
    ARRIVAL_DATE: {
      uiKey: "arrivalDate",
      apiKey: "arrivalDate",
    },
    DEPARTURE_DATE: {
      uiKey: "departureDate",
      apiKey: "departureDate",
    },
    EMAIL: {
      uiKey: "email",
      apiKey: "email",
    },
    EXTERNAL_RESERVATION_CODE: {
      uiKey: "externalReservationCode",
      apiKey: "externalReservationCode",
    },
  },
};

export const MOBILE_CARD_KEYS_MAPPING = {
  uiKey: "",
  apiKey: "keys",

  CARD: {
    uiKey: "card",
    apiKey: "keys.keyCards",

    ALLOWED: {
      uiKey: "card.allowed",
      apiKey: "allowed",
    },
    RECEIVED: {
      uiKey: "card.received",
      apiKey: "received",
    },
    WRITTEN: {
      uiKey: "card.written",
      apiKey: "written",
    },
    ADDITIONAL_ALLOWED: {
      uiKey: "card.additionalAllowed",
      apiKey: "additionalAllowed",
    },
    ALLOW_EXTRA_KEYS: {
      uiKey: "card.allowExtraKeys",
      apiKey: "allowExtraKeys",
    },
  },

  MOBILE: {
    uiKey: "mobile",
    apiKey: "",

    PROVIDER: {
      uiKey: "mobile.provider",
      apiKey: "provider",
    },
    KEY: {
      uiKey: "mobile.mobileKey.key",
      apiKey: "mobileKey.key",
    },
    NOTE: {
      uiKey: "mobile.mobileKey.note",
      apiKey: "mobileKey.note",
    },
    VENDOR: {
      uiKey: "mobile.mobileKey.vendor",
      apiKey: "mobileKey.vendor",
    },
    ACCESSIBLE_DOORS: {
      uiKey: "mobile.mobileKey.parameters.accessible_doors",
      apiKey: "mobileKey.parameters.accessible_doors",
    },
    OPEN_DOORS: {
      uiKey: "mobile.mobileKey.parameters.open_doors",
      apiKey: "mobileKey.parameters.open_doors",
    },
    ALLOWED: {
      uiKey: "mobile.allowed",
      apiKey: "mobileKeysAllowed",
    },

    IS_EMPTY_KEY_LIST: {
      uiKey: "isEmptyKeyList",
      apiKey: null,
    },

    WEB_KEYS: {
      uiKey: "mobile.webKeys",
      apiKey: "data",

      ID: {
        uiKey: "id",
        apiKey: "id",
      },
      TITLE: {
        uiKey: "title",
        apiKey: "title",
      },
    },

    BLE_KEYS: {
      uiKey: "mobile.bleKeys",
      apiKey: "doors",

      ID: {
        uiKey: "id",
        apiKey: "id",
      },
      NAME: {
        uiKey: "name",
        apiKey: "name",
      },
      IS_OPEN: {
        uiKey: "isOpen",
        apiKey: "isOpen",
      },
      IS_ERROR: {
        uiKey: "isError",
        apiKey: "isError",
      },
      IS_WAITING: {
        uiKey: "isWaiting",
        apiKey: "isWaiting",
      },
    },
  },

  REGISTERED_DEVICE: {
    uiKey: "registeredDevice",
    apiKey: "registeredDevice",

    NAME: {
      uiKey: "registeredDevice.name",
      apiKey: "name",
    },
    INVITATION_CODE: {
      uiKey: "registeredDevice.parameters.invitationCode",
      apiKey: "parameters.invitation_code",
    },
  },
};

export const PAYMENT_MAPPING = {
  apiKey: "payment",

  PAYMENT_RESULT: {
    uiKey: "paymentResult",
    apiKey: "paymentResult",

    PAYMENT_UUID: {
      uiKey: "paymentUuid",
      apiKey: "paymentUuid",
    },
    REDIRECT_URL: {
      uiKey: "redirectUrl",
      apiKey: "redirectUrl",
    },
    PAYMENT_STATUS: {
      uiKey: "paymentStatus",
      apiKey: "paymentStatus",
    },
    ACTION: {
      uiKey: "action",
      apiKey: "action",
    },
    CODE: {
      uiKey: "code",
      apiKey: "code",
    },
    TOKEN: {
      uiKey: "token",
      apiKey: "token",
    },
    REDIRECT_PARAMETERS: {
      uiKey: "redirectParameters",
      apiKey: "redirectParameters",

      KEY: {
        uiKey: "key",
        apiKey: "key",
      },
      VALUE: {
        uiKey: "value",
        apiKey: "value",
      },
    },
    PAYMENT_CONFIGURATION: {
      CONFIGURATION: {
        uiKey: "paymentConfiguration.configuration",
        apiKey: "paymentConfiguration.configuration",
      },
      MERCHANT_ID: {
        uiKey: "paymentConfiguration.merchantId",
        apiKey: "paymentConfiguration.merchantId",
      },
      PROVIDER: {
        uiKey: "paymentConfiguration.provider",
        apiKey: "paymentConfiguration.provider",
      },
    },
  },

  PAYMENT_PENDING: {
    uiKey: "paymentPending",
    apiKey: null,
  },
  PAYMENT_METHODS: {
    uiKey: "paymentMethods",
    apiKey: "paymentMethods",
  },

  PAYMENT_INSTRUMENTS: {
    uiKey: "paymentInstruments",
    apiKey: "paymentInstruments",

    ID: {
      uiKey: "paymentInstruments.id",
      apiKey: "paymentInstruments.id",
    },
  },
};

export const REGISTRATION_MAPPING = {
  apiKey: "guestRegistration.registrationForm",

  TERMS_AND_CONDITIONS_ACCEPTED: {
    uiKey: "termsAndConditionsAccepted",
    apiKey: "termsAndConditionsAccepted",
  },

  IS_PRIVATE_INVOICE_ADDRESS: {
    uiKey: "isPrivateInvoiceAddress",
  },

  BILLING_ADDRESS_CHANGED: {
    uiKey: "billingAddressChanged",
    apiKey: "billingAddressChanged",
  },

  ACCOMPANYING_GUEST_COUNT: {
    uiKey: "accompanyingGuestCount",
    apiKey: "accompanyingGuestCount",
  },
  ARRIVAL_DATE: {
    uiKey: "arrivalDate",
    apiKey: "arrivalDate",
  },
  DEPARTURE_DATE: {
    uiKey: "departureDate",
    apiKey: "departureDate",
  },
  TRAVEL_PURPOSE: {
    uiKey: "travelPurpose",
    apiKey: "travelPurpose",
  },

  ACCOMPANYING_GUESTS: {
    uiKey: "accompanyingGuests",
    apiKey: "accompanyingGuests",

    UUID: {
      uiKey: "uuid",
      apiKey: "uuid",
    },
    SALUTATION_UUID: {
      uiKey: "salutationUuid",
      apiKey: "salutationUuid",
    },
    GENDER: {
      uiKey: "gender",
      apiKey: "gender",
    },
    FIRST_NAME: {
      uiKey: "firstName",
      apiKey: "firstName",
    },
    LAST_NAME: {
      uiKey: "lastName",
      apiKey: "lastName",
    },
    DATE_OF_BIRTH: {
      uiKey: "dateOfBirth",
      apiKey: "dateOfBirth",
    },
    PHONE: {
      uiKey: "phone",
      apiKey: "phone",
    },
    CITIZENSHIP_CODE: {
      uiKey: "citizenshipCode",
      apiKey: "citizenshipCode",
    },
    IDENTIFICATION_DOCUMENT_NUMBER: {
      uiKey: "identificationDocumentNumber",
      apiKey: "identificationDocumentNumber",
    },
    IDENTIFICATION_DOCUMENT_TYPE_CODE: {
      uiKey: "identificationDocumentTypeCode",
      apiKey: "identificationDocumentTypeCode",
    },
    IDENTIFICATION_DOCUMENT_IMAGE: {
      uiKey: "identificationDocumentImage",
      apiKey: "identificationDocumentImage",
    },
    IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE: {
      uiKey: "identificationDocumentDateOfIssue",
      apiKey: "identificationDocumentDateOfIssue",
    },
  },

  INVOICE_ADDRESS_ORIGINAL: {
    uiKey: "invoiceAddressOriginal",
  },

  INVOICE_ADDRESS: {
    uiKey: "invoiceAddress",
    apiKey: "billingAddress",

    ADDRESS_TYPE: {
      uiKey: "addressType",
      apiKey: "billingAddressType",
    },
    COMPANY_NAME: {
      uiKey: "company",
      apiKey: "company",
    },
    DEPARTMENT: {
      uiKey: "department",
      apiKey: "department",
    },
    COUNTRY_CODE: {
      uiKey: "countryCode",
      apiKey: "countryCode",
    },
    RECIPIENT: {
      uiKey: "recipient",
      apiKey: "recipient",
    },
    STREET: {
      uiKey: "street",
      apiKey: "street",
    },
    POSTAL_CODE: {
      uiKey: "postalCode",
      apiKey: "postalCode",
    },
    CITY: {
      uiKey: "city",
      apiKey: "city",
    },
    ITEMS: {
      uiKey: "items",
      apiKey: "items",
    },
  },

  GUEST: {
    uiKey: "guest",
    apiKey: "guest",

    UUID: {
      uiKey: "uuid",
      apiKey: "uuid",
    },
    SALUTATION: {
      uiKey: "salutation",
      apiKey: "salutation",
    },
    SALUTATION_UUID: {
      uiKey: "salutationUuid",
      apiKey: "salutationUuid",
    },
    GENDER: {
      uiKey: "gender",
      apiKey: "gender",
    },
    FIRST_NAME: {
      uiKey: "firstName",
      apiKey: "firstName",
    },
    LAST_NAME: {
      uiKey: "lastName",
      apiKey: "lastName",
    },
    STREET: {
      uiKey: "street",
      apiKey: "street",
    },
    POSTAL_CODE: {
      uiKey: "postalCode",
      apiKey: "postalCode",
    },
    CITY: {
      uiKey: "city",
      apiKey: "city",
    },
    COUNTRY_CODE: {
      uiKey: "countryCode",
      apiKey: "countryCode",
    },
    DATE_OF_BIRTH: {
      uiKey: "dateOfBirth",
      apiKey: "dateOfBirth",
    },
    CITIZENSHIP_CODE: {
      uiKey: "citizenshipCode",
      apiKey: "citizenshipCode",
    },
    NATIONALITY: {
      apiKey: "nationality",
    },
    EMAIL: {
      uiKey: "email",
      apiKey: "email",
    },
    PHONE: {
      uiKey: "phone",
      apiKey: "phone",
    },
    LICENSE_PLATE: {
      uiKey: "licensePlate",
      apiKey: "licensePlate",
    },
    IDENTIFICATION_DOCUMENT_NUMBER: {
      uiKey: "identificationDocumentNumber",
      apiKey: "identificationDocumentNumber",
    },
    IDENTIFICATION_DOCUMENT_TYPE_CODE: {
      uiKey: "identificationDocumentTypeCode",
      apiKey: "identificationDocumentTypeCode",
    },
    IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE: {
      uiKey: "identificationDocumentDateOfIssue",
      apiKey: "identificationDocumentDateOfIssue",
    },
    IDENTIFICATION_DOCUMENT_AUTHORITY: {
      uiKey: "identificationDocumentAuthority",
      apiKey: "identificationDocumentAuthority",
    },
    IDENTIFICATION_DOCUMENT_IMAGE: {
      uiKey: "identificationDocumentImage",
      apiKey: "identificationDocumentImage",
    },
  },

  FORM_FIELD_REQUIREMENTS: {
    uiKey: "formFieldRequirements",
    apiKey: "guestRegistration.registrationFormFieldRequirements",

    GUEST: {
      SALUTATION_UUID: {
        uiKey: "salutationUuid",
        apiKey: "salutationUuid",
      },
      GENDER: {
        uiKey: "gender",
        apiKey: "gender",
      },
      FIRST_NAME: {
        uiKey: "firstName",
        apiKey: "firstName",
      },
      LAST_NAME: {
        uiKey: "lastName",
        apiKey: "lastName",
      },
      LICENSE_PLATE: {
        uiKey: "licensePlate",
        apiKey: "licensePlate",
      },
      DATE_OF_BIRTH: {
        uiKey: "dateOfBirth",
        apiKey: "dateOfBirth",
      },
      PHONE: {
        uiKey: "phone",
        apiKey: "phone",
      },
      EMAIL: {
        uiKey: "email",
        apiKey: "email",
      },
      STREET: {
        uiKey: "street",
        apiKey: "street",
      },
      TRAVEL_PURPOSE: {
        uiKey: "travelPurpose",
        apiKey: "travelPurpose",
      },
      POSTAL_CODE: {
        uiKey: "postalCode",
        apiKey: "postalCode",
      },
      CITY: {
        uiKey: "city",
        apiKey: "city",
      },
      COUNTRY_CODE: {
        uiKey: "countryCode",
        apiKey: "countryCode",
      },
      CITIZENSHIP_CODE: {
        uiKey: "citizenshipCode",
        apiKey: "citizenshipCode",
      },
      IDENTIFICATION_DOCUMENT_NUMBER: {
        uiKey: "identificationDocumentNumber",
        apiKey: "identificationDocumentNumber",
      },
      IDENTIFICATION_DOCUMENT_TYPE_CODE: {
        uiKey: "identificationDocumentTypeCode",
        apiKey: "identificationDocumentTypeCode",
      },
      IDENTIFICATION_DOCUMENT_IMAGE: {
        uiKey: "identificationDocumentImage",
        apiKey: "identificationDocumentImage",
      },
      IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE: {
        uiKey: "identificationDocumentDateOfIssue",
        apiKey: "identificationDocumentDateOfIssue",
      },
      IDENTIFICATION_DOCUMENT_AUTHORITY: {
        uiKey: "identificationDocumentAuthority",
        apiKey: "identificationDocumentAuthority",
      },
    },

    ACCOMPANYING_GUESTS: {
      uiKey: "accompanyingGuest",
      apiKey: "accompanyingGuest",

      SALUTATION_UUID: {
        uiKey: "salutationUuid",
        apiKey: "salutationUuid",
      },
      GENDER: {
        uiKey: "gender",
        apiKey: "gender",
      },
      FIRST_NAME: {
        uiKey: "firstName",
        apiKey: "firstName",
      },
      LAST_NAME: {
        uiKey: "lastName",
        apiKey: "lastName",
      },
      DATE_OF_BIRTH: {
        uiKey: "dateOfBirth",
        apiKey: "dateOfBirth",
      },
      PHONE: {
        uiKey: "phone",
        apiKey: "phone",
      },
      CITIZENSHIP_CODE: {
        uiKey: "citizenshipCode",
        apiKey: "citizenshipCode",
      },
      IDENTIFICATION_DOCUMENT_NUMBER: {
        uiKey: "identificationDocumentNumber",
        apiKey: "identificationDocumentNumber",
      },
      IDENTIFICATION_DOCUMENT_TYPE_CODE: {
        uiKey: "identificationDocumentTypeCode",
        apiKey: "identificationDocumentTypeCode",
      },
      IDENTIFICATION_DOCUMENT_IMAGE: {
        uiKey: "identificationDocumentImage",
        apiKey: "identificationDocumentImage",
      },
      IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE: {
        uiKey: "identificationDocumentDateOfIssue",
        apiKey: "identificationDocumentDateOfIssue",
      },
    },
  },

  ID_DOCUMENT: {
    FIRST_SCANNED_IMAGE: {
      apiKey: "firstScannedImage",
    },
    SECOND_SCANNED_IMAGE: {
      apiKey: "secondScannedImage",
    },
  },

  ID_DOCUMENT_SCANS: {
    uiKey: "idDocumentScans",

    GUEST: {
      uiKey: "guest",
    },

    ACCOMPANYING_GUESTS: {
      uiKey: "accompanyingGuests",
    },
  },
};

export const SIGNATURE_MAPPING = {
  apiKey: "guestRegistration.registrationFormSignature",

  PROVIDER: {
    uiKey: "provider",
    apiKey: "provider",
  },
  EXTERNAL_SYSTEM_USERNAME: {
    uiKey: "externalSystemUsername",
    apiKey: "externalSystemUsername",
  },
  ACS_URL: {
    uiKey: "acsUrl",
    apiKey: "acsUrl",
  },
  BASE64_ENCODED_CHALLENGE_REQUEST: {
    uiKey: "base64EncodedChallengeRequest",
    apiKey: "base64EncodedChallengeRequest",
  },
  CHALLENGE_WINDOW_SIZE: {
    uiKey: "challengeWindowSize",
    apiKey: "challengeWindowSize",
  },
  REDIRECT_URL: {
    apiKey: "redirectUrl",
  },
  SIGNATURE_PROVIDER: {
    apiKey: "signatureProvider",
  },
  CHALLENGE_DATA: {
    apiKey: "challengeData",
  },
};

export const UNCLASSIFIED_FIELDS_MAPPING = {
  RECEIPT: {
    apiKey: "receipt",
  },
};

export const TRANSLATIONS_MAPPING = {
  apiKey: "translations",
};

export const COVID_MAPPING = {
  ID: {
    uiKey: "uuId",
    apiKey: "id",
  },
  QR_CODE: {
    uiKey: "qrCode",
    apiKey: "qrCode",
  },
  STATUS: {
    uiKey: "status",
    apiKey: "covidVerificationStatus",
  },
};

export const TRAVEL_TYPE_KEYS = {
  TRAVEL_PURPOSE: REGISTRATION_MAPPING.TRAVEL_PURPOSE.uiKey,
};

export const ADDRESS_FORM_KEYS = {
  SALUTATION_UUID: REGISTRATION_MAPPING.GUEST.SALUTATION_UUID.uiKey,
  FIRST_NAME: REGISTRATION_MAPPING.GUEST.FIRST_NAME.uiKey,
  LAST_NAME: REGISTRATION_MAPPING.GUEST.LAST_NAME.uiKey,
  STREET: REGISTRATION_MAPPING.GUEST.STREET.uiKey,
  POSTAL_CODE: REGISTRATION_MAPPING.GUEST.POSTAL_CODE.uiKey,
  CITY: REGISTRATION_MAPPING.GUEST.CITY.uiKey,
  COUNTRY_CODE: REGISTRATION_MAPPING.GUEST.COUNTRY_CODE.uiKey,
  DATE_OF_BIRTH: REGISTRATION_MAPPING.GUEST.DATE_OF_BIRTH.uiKey,
  CITIZENSHIP_CODE: REGISTRATION_MAPPING.GUEST.CITIZENSHIP_CODE.uiKey,
  IDENTIFICATION_DOCUMENT_NUMBER:
    REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_NUMBER.uiKey,
  IDENTIFICATION_DOCUMENT_TYPE_CODE:
    REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_TYPE_CODE.uiKey,
  IDENTIFICATION_DOCUMENT_AUTHORITY:
    REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_AUTHORITY.uiKey,
  IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE:
    REGISTRATION_MAPPING.GUEST.IDENTIFICATION_DOCUMENT_DATE_OF_ISSUE.uiKey,
  ADDITIONAL_GUEST:
    REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.ACCOMPANYING_GUESTS.uiKey,
  GENDER: REGISTRATION_MAPPING.GUEST.GENDER.uiKey,
};

export const ADDITIONAL_INFORMATION_KEYS = {
  EMAIL: REGISTRATION_MAPPING.GUEST.EMAIL.uiKey,
  PHONE: REGISTRATION_MAPPING.GUEST.PHONE.uiKey,
  LICENSE_PLATE: REGISTRATION_MAPPING.GUEST.LICENSE_PLATE.uiKey,
};

export const BILLING_ADDRESS_FORM_KEYS = {
  ADDRESS_TYPE: REGISTRATION_MAPPING.INVOICE_ADDRESS.ADDRESS_TYPE.uiKey,
  RECIPIENT: REGISTRATION_MAPPING.INVOICE_ADDRESS.RECIPIENT.uiKey,
  COMPANY_NAME: REGISTRATION_MAPPING.INVOICE_ADDRESS.COMPANY_NAME.uiKey,
  DEPARTMENT: REGISTRATION_MAPPING.INVOICE_ADDRESS.DEPARTMENT.uiKey,
  CITY: REGISTRATION_MAPPING.INVOICE_ADDRESS.CITY.uiKey,
  POSTAL_CODE: REGISTRATION_MAPPING.INVOICE_ADDRESS.POSTAL_CODE.uiKey,
  COUNTRY_CODE: REGISTRATION_MAPPING.INVOICE_ADDRESS.COUNTRY_CODE.uiKey,
  STREET: REGISTRATION_MAPPING.INVOICE_ADDRESS.STREET.uiKey,
  ITEMS: REGISTRATION_MAPPING.INVOICE_ADDRESS.ITEMS.uiKey,
};

export const ID_DOCUMENT_SCANS_MAPPING = {
  GUEST: REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.GUEST.uiKey,
  ACCOMPANYING_GUESTS: REGISTRATION_MAPPING.ID_DOCUMENT_SCANS.ACCOMPANYING_GUESTS.uiKey,
};
