export const SET_FLOW = "SET_FLOW";
export const SET_NEXT = "SET_NEXT";
export const SET_CURRENT_NEXT = "SET_CURRENT_NEXT";
export const SET_CURRENT_FLOW = "SET_CURRENT_FLOW";
export const SET_HAS_CHECKOUT_STARTED_FOR_NATIVE = "SET_HAS_CHECKOUT_STARTED_FOR_NATIVE";

export const FIND_BOOKING__SAGA = "FIND_BOOKING__SAGA";
export const FIND_BOOKING_URL__SAGA = "FIND_BOOKING_URL__SAGA";
export const FIND_BOOKING_DETAILS_BY_GUID__SAGA = "FIND_BOOKING_DETAILS_BY_GUID__SAGA";
export const SET_RESERVATION_FETCH_TIME = "SET_RESERVATION_FETCH_TIME";
export const SET_RESERVATION = "SET_RESERVATION";
export const SET_CHECKOUT_AVAILABLE = "SET_CHECKOUT_AVAILABLE";
export const SET_IS_CHECKIN_VIEWED = "SET_IS_CHECKIN_VIEWED";
export const SET_IS_CHECKOUT_VIEWED = "SET_IS_CHECKOUT_VIEWED";

export const IS_PRIVATE_INVOICE_ADDRESS = "IS_PRIVATE_INVOICE_ADDRESS";
export const SET_BILLING_ADDRESS_CHANGED = "SET_BILLING_ADDRESS_CHANGED";
export const SET_ID_DOCUMENT_SCANS = "SET_ID_DOCUMENT_SCANS";
export const SET_REGISTRATION = "SET_REGISTRATION";
export const SET_FORM_FIELD_REQUIREMENTS = "SET_FORM_FIELD_REQUIREMENTS";
export const SAVE_REGISTRATION__SAGA = "SAVE_REGISTRATION__SAGA";

export const SET_INVOICE = "SET_INVOICE";
export const SET_INVOICE_SPLIT_AVAILABLE = "SET_INVOICE_SPLIT_AVAILABLE";

export const SHOW_LOADING_OVERLAY = "SHOW_LOADING_OVERLAY";
export const HIDE_LOADING_OVERLAY = "HIDE_LOADING_OVERLAY";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const HIDE_ALL_NOTIFICATIONS = "HIDE_ALL_NOTIFICATIONS";
export const SHOW_HOTEL_SUPPORT = "SHOW_HOTEL_SUPPORT";
export const SHOW_KEY_SUPPORT = "SHOW_KEY_SUPPORT";
export const SHOW_CONFIRM_GO_HOME_MODAL = "SHOW_CONFIRM_GO_HOME_MODAL";

export const GET_LOOKUP_FIELDS__SAGA = "GET_LOOKUP_FIELDS__SAGA";
export const POST_LOOKUP_FIELDS__SAGA = "POST_LOOKUP_FIELDS__SAGA";
export const SET_LOOKUP_FIELDS = "SET_LOOKUP_FIELDS";
export const SET_ADDITIONAL_LOOKUP_FIELDS = "SET_ADDITIONAL_LOOKUP_FIELDS";

export const CONFIG__SAGA = "CONFIG__SAGA";
export const SET_APP_CONFIG = "SET_APP_CONFIG";
export const SET_PROCESS_CONFIGURATION = "SET_PROCESS_CONFIGURATION";
export const SET_COVID_CONFIGURATION = "SET_COVID_CONFIGURATION";
export const SET_PROCESS_OVERVIEW = "SET_PROCESS_OVERVIEW";
export const SET_GUEST_AUTH_CONFIGURATION = "SET_GUEST_AUTH_CONFIGURATION";
export const GENERAL_DATA__SAGA = "GENERAL_DATA__SAGA";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_SITE_DATA = "SET_SITE_DATA";
export const SET_CITIZENSHIPS = "SET_CITIZENSHIPS";
export const SET_GENDERS = "SET_GENDERS";
export const SET_SALUTATIONS = "SET_SALUTATIONS";
export const SET_ID_DOCUMENT_TYPES = "SET_ID_DOCUMENT_TYPES";
export const SET_LANG = "SET_LANG";
export const SET_IS_TERMINAL = "SET_IS_TERMINAL";
export const API_ERROR__SAGA = "API_ERROR__SAGA";
export const GO_HOME__SAGA = "GO_HOME__SAGA";

export const COVID_CHECK_FINISH__SAGA = "COVID_CHECK_FINISH__SAGA";
export const POST_COVID_CHECK__SAGA = "POST_COVID_CHECK__SAGA";
export const GET_COVID_CHECK_STATUSES__SAGA = "GET_COVID_CHECK_STATUSES__SAGA";
export const GET_COVID_CHECK_GUEST_STATUS__SAGA = "GET_COVID_CHECK_GUEST_STATUS__SAGA";

export const POST_PAYMENT_INITIATE__SAGA = "POST_PAYMENT_INITIATE__SAGA";
export const POST_PAYMENT_SPLIT__SAGA = "POST_PAYMENT_SPLIT__SAGA";
export const SET_PAYMENT_RESULT = "SET_PAYMENT_RESULT";
export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";
export const SET_PAYMENT_INSTRUMENTS = "SET_PAYMENT_INSTRUMENTS";
export const SET_PAYMENT_PENDING = "SET_PAYMENT_PENDING";

export const COST_OVERVIEW_FINISH__SAGA = "COST_OVERVIEW_FINISH__SAGA";

export const SET_SIGNATURE_PROVIDER_INFO = "SET_SIGNATURE_PROVIDER_INFO";
export const SET_SIGNATURE_REDIRECT_URL = "SET_SIGNATURE_REDIRECT_URL";
export const SET_SIGNATURE_CHALLENGE_DATA = "SET_SIGNATURE_CHALLENGE_DATA";
export const POST_SIGNATURE_CHECK__SAGA = "POST_SIGNATURE_CHECK__SAGA";
export const POST_ID_DOCUMENT__SAGA = "POST_ID_DOCUMENT__SAGA";
export const SEND_SIGNATURE__SAGA = "SEND_SIGNATURE__SAGA";

export const SET_KEY_CARDS_DATA = "SET_KEY_CARDS_DATA";
export const SET_ADDITIONAL_KEY_CARDS_ALLOWED = "SET_ADDITIONAL_KEY_CARDS_ALLOWED";
export const SET_ALLOW_EXTRA_KEYS = "SET_ALLOW_EXTRA_KEYS";
export const POST_KEY_CARD_ADDITIONAL_RECEIVED__SAGA =
  "POST_KEY_CARD_ADDITIONAL_RECEIVED__SAGA";
export const POST_KEY_CARD_RECEIVED__SAGA = "POST_KEY_CARD_RECEIVED__SAGA";
export const POST_KEY_CARD_WRITING__SAGA = "POST_KEY_CARD_WRITING__SAGA";
export const POST_KEY_CARD_ADDITIONAL_WRITING__SAGA =
  "POST_KEY_CARD_ADDITIONAL_WRITING__SAGA";
export const POST_KEY_CARD_FINISH__SAGA = "POST_KEY_CARD_FINISH__SAGA";
export const POST_ISSUE_MOBILE_KEY__SAGA = "POST_ISSUE_MOBILE_KEY__SAGA";
export const POST_REGISTER_DEVICE__SAGA = "POST_REGISTER_DEVICE__SAGA";
export const SET_MOBILE_KEY_DATA = "SET_MOBILE_KEY_DATA";

export const GET_WEB_KEY__SAGA = "GET_WEB_KEY__SAGA";
export const SET_REGISTERED_DEVICE = "SET_REGISTERED_DEVICE";
export const SET_WEB_KEYS = "SET_WEB_KEYS";
export const SET_BLE_KEYS = "SET_BLE_KEYS";
export const POST_OPEN_DOOR__SAGA = "POST_OPEN_DOOR__SAGA";

export const SET_ROOMS = "SET_ROOMS";
export const SET_RATE_PLAN = "SET_RATE_PLAN";

export const POST_CHECKIN_FINISH__SAGA = "CHECKIN_FINISH__SAGA";
export const POST_CHECKOUT_FINISH__SAGA = "CHECKOUT_FINISH__SAGA";

export const SET_PROCESS_STEPS = "SET_PROCESS_STEPS";

export const DESTROY_SESSION = "DESTROY_SESSION";

export const SET_RESERVATION_DATA__SAGA = "SET_RESERVATION_DATA__SAGA";

export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
