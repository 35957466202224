import {
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import {POST_CHECKIN_FINISH__SAGA} from "../../../constants";
import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  setReservationDataSagaAction,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postCheckInFinish(action) {
  const {resolve, reject} = action.payload;

  try {
    let store = yield select();

    let {code, name} = store.reservationReducer;

    const client = yield* getClientTypeFn();
    const checkInFinishResponse = yield call([client, "postFlowFinish"], code, name);

    if (resolve) {
      resolve(checkInFinishResponse);
    }

    yield put(setReservationDataSagaAction({response: checkInFinishResponse}));
  } catch (error) {
    yield put(hideLoadingOverlay());
    yield put(apiErrorSagaAction({error: error, isAllowedRetry: true}));

    if (reject) {
      reject(error.response);
    }
  }
}

export function* postCheckInFinishSaga() {
  yield takeLatest(POST_CHECKIN_FINISH__SAGA, postCheckInFinish);
}
