const translations = {
  key: {
    title: "Key",
    takeAndPlace: "Take the key card and place it on the scanner",
    placeConfirm: "Yes, my card is on the scanner",
    writing: "Please wait until your key card has been fully encoded",
    additionalCard: "Would you like to receive another key card?",
    additional: "Additional key card",
    all: "You have received all available cards.",
    renew: "Request again",

    goToTerminal: "Please go to the Terminal and get your key",
    cantFindTerminal: "Can’t find the Terminal?",
    clickHere: "Click here",
    inFrontOfTerminal: "I’m in front of the Terminal",
    getYourKeys: "Get Your Keys",
    only3Steps: "Only 3 steps away from your key card…",
    steps: {
      step1: "Choose <strong>Check-in on Terminal</strong> screen",
      step2: "Select <strong>“Scan QR-code”</strong> option",
      step3: "Scan <strong>Your QR-code</strong>:",
    },
    keyReceived: "I received my key",
    problem: "I have problem with my key",

    howTo: "How to receive your room key:",
    takeCard: "Please take a key card from the stack at the terminal",
    nextStep: "Next step",
    holdKey: "Touch and hold your key card on the key encoder",
    startEncoding: "Start encoding my key",
    codingKey: "Coding your key…",
    success: "Your room key was successfully encoded. You can remove the key card now.",
    getAdditionalKey: "Get Additional Key card",
    goToOverview: "Go to Check-In Overview",
    error: "Ops.. something went wrong",
    question: "Key can’t be encoded?",
    cannotEncodeKey: "Key can’t be encoded",
    createKeyCard: "Create Key Card",

    modalForPhysicalVsMobileKeyChoice: {
      title: "Choose your Key",
      terminalText:
        "<0>Create Key Card on the Terminal in the Hotel Reception</0>\n\n(available also later at the Reception)",
      terminalButton: "Create Room Key Card now",
      or: "or",
      mobileText: "<0>use the Mobile Key</0>\n\n(opening doors with your Smartphone)",
      mobileButton: "I will use Mobile Key",
    },
  },
};

export {translations};
