import {appConfigReducer} from "./appConfig";
import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {folioReducer} from "./folio";
import {generalReducer} from "./general";
import {keysReducer} from "./keys";
import {loadingOverlayReducer} from "./loadingOverlay";
import {lookupFieldsReducer} from "./lookupFields";
import {notificationsReducer} from "./notifications";
import {paymentReducer} from "./payment";
import {registrationReducer} from "./registration";
import {reservationReducer} from "./reservation";
import {routesReducer} from "./routes";
import {signatureReducer} from "./signature";
import {supportReducer} from "./support";
import {translationsReducer} from "./translations";

const history = createBrowserHistory();
export const reducers = combineReducers({
  appConfigReducer,
  router: connectRouter(history),
  routesReducer,
  generalReducer,
  lookupFieldsReducer,
  supportReducer,
  signatureReducer,
  loadingOverlayReducer,
  notificationsReducer,
  registrationReducer,
  reservationReducer,
  folioReducer,
  paymentReducer,
  keysReducer,
  translationsReducer,
});
