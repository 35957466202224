const translations = {
  date: {
    dateFormat: "DD.MM.YYYY",
    timeFormat: "HH:mm",
    dateTimeWithSeparator: "DD.MM.YYYY {{separator}} HH:mm",
    dateMMM: "Do MMM YYYY",
    day: "Tag",
    month: "Monat",
    year: "Jahr",
    clock: "Uhr",
    from: "Von",
    till: "Bis",
    invalid: "Ungültiges Datum",
  },
};

export {translations};
