import {
  APP_CONFIG_MAPPING,
  FOLIOS_MAPPING,
  GENERAL_DATA_MAPPING,
  MOBILE_CARD_KEYS_MAPPING,
  PAYMENT_MAPPING,
  REGISTRATION_MAPPING,
  RESERVATION_MAPPING,
  ROUTES_MAPPING,
  SET_RESERVATION_DATA__SAGA,
  SIGNATURE_MAPPING,
} from "../../../constants";
import {get, isNil} from "lodash";
import {
  hideLoadingOverlay,
  setAdditionalKeyCardsAllowed,
  setAllowExtraKeys,
  setCheckoutAvailable,
  setCovidConfiguration,
  setCurrentNext,
  setFlow,
  setFormFieldRequirements,
  setHasCheckoutStartedForNative,
  setInvoice,
  setInvoiceSplitAvailable,
  setKeyCardsData,
  setMobileKeyData,
  setNext,
  setPaymentInstruments,
  setPaymentMethods,
  setPaymentResult,
  setProcessConfiguration,
  setProcessOverview,
  setProcessSteps,
  setRatePlan,
  setRegistration,
  setReservation,
  setReservationFetchTime,
  setRooms,
  setSignatureChallengeData,
  setSignatureProviderInfo,
  setSignatureRedirectUrl,
} from "../../actions";
import {put, takeEvery} from "redux-saga/effects";

import {now} from "moment/moment";

function* setReservationData({payload: {response}}) {
  const data = response.data;

  yield put(setNext(""));
  yield put(setCurrentNext(""));

  yield put(setReservationFetchTime(now()));

  const registrationData = get(data, REGISTRATION_MAPPING.apiKey, {});
  yield put(
    setRegistration({
      data: registrationData,
      shouldAvoidEmptyingValues: true,
      shouldSetOriginalInvoiceAddress: true,
    })
  );

  yield put(
    setFormFieldRequirements(
      get(data, REGISTRATION_MAPPING.FORM_FIELD_REQUIREMENTS.apiKey, {})
    )
  );

  yield put(
    setCheckoutAvailable(get(data, RESERVATION_MAPPING.CHECK_OUT_AVAILABLE.apiKey, false))
  );

  const processSteps = get(data, GENERAL_DATA_MAPPING.STEPS.apiKey);

  if (!isNil(processSteps)) {
    yield put(setProcessSteps(processSteps || []));
  }

  const payment = get(data, PAYMENT_MAPPING.apiKey);

  if (!isNil(payment)) {
    yield put(setInvoice(get(payment, FOLIOS_MAPPING.INVOICES.apiKey)));
    yield put(
      setInvoiceSplitAvailable(
        get(data, FOLIOS_MAPPING.INVOICES_SPLIT_AVAILABLE.apiKey, false)
      )
    );
    yield put(
      setPaymentMethods(get(payment, PAYMENT_MAPPING.PAYMENT_METHODS.apiKey, {}))
    );
    const paymentResult = get(payment, PAYMENT_MAPPING.PAYMENT_RESULT.apiKey);

    if (!isNil(paymentResult)) {
      yield put(setPaymentResult(paymentResult));
    }

    yield put(
      setPaymentInstruments(get(payment, PAYMENT_MAPPING.PAYMENT_INSTRUMENTS.apiKey, {}))
    );
  }

  const registrationFormSignature = get(data, SIGNATURE_MAPPING.apiKey);

  if (!isNil(registrationFormSignature)) {
    yield put(
      setSignatureRedirectUrl(
        get(registrationFormSignature, SIGNATURE_MAPPING.REDIRECT_URL.apiKey, "")
      )
    );
    yield put(
      setSignatureProviderInfo(
        get(registrationFormSignature, SIGNATURE_MAPPING.SIGNATURE_PROVIDER.apiKey, {})
      )
    );
    yield put(
      setSignatureChallengeData(
        get(registrationFormSignature, SIGNATURE_MAPPING.CHALLENGE_DATA.apiKey, {})
      )
    );
  }

  const keys = get(data, MOBILE_CARD_KEYS_MAPPING.apiKey, null);

  if (!isNil(keys)) {
    yield put(setKeyCardsData(get(keys, MOBILE_CARD_KEYS_MAPPING.CARD.apiKey, {})));
    yield put(
      setAdditionalKeyCardsAllowed(get(keys, MOBILE_CARD_KEYS_MAPPING.CARD.apiKey, {}))
    );
    yield put(
      setAdditionalKeyCardsAllowed(
        get(keys, MOBILE_CARD_KEYS_MAPPING.CARD.ADDITIONAL_ALLOWED.apiKey)
      )
    );
    yield put(
      setAllowExtraKeys(
        get(keys, MOBILE_CARD_KEYS_MAPPING.CARD.ALLOW_EXTRA_KEYS.apiKey, false)
      )
    );
    yield put(setMobileKeyData(keys));
  }

  yield put(setRooms([get(data, GENERAL_DATA_MAPPING.ROOMS.apiKey)?.[0] || {}]));

  const reservation = get(data, RESERVATION_MAPPING.apiKey);

  if (!isNil(reservation)) {
    yield put(setReservation(reservation));
    yield put(setRatePlan(reservation));
  }

  yield put(
    setProcessConfiguration(
      get(data, APP_CONFIG_MAPPING.PROCESS_CONFIGURATION.apiKey, {})
    )
  );
  yield put(
    setProcessOverview(get(data, APP_CONFIG_MAPPING.PROCESS_OVERVIEW.apiKey, {}))
  );
  yield put(
    setCovidConfiguration(get(data, APP_CONFIG_MAPPING.COVID_CONFIGURATION.apiKey, {}))
  );

  yield put(setFlow(get(data, ROUTES_MAPPING.FLOW.apiKey)));
  yield put(setNext(get(data, ROUTES_MAPPING.NEXT.apiKey)));
  yield put(setHasCheckoutStartedForNative(false));

  yield put(hideLoadingOverlay());
}

export function* setReservationDataSaga() {
  yield takeEvery(SET_RESERVATION_DATA__SAGA, setReservationData);
}
