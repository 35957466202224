import {get} from "lodash";
import {
  call,
  put,
  takeLeading,
} from "redux-saga/effects";

import {
  FIND_BOOKING_DETAILS_BY_GUID__SAGA,
  NOTIFICATION_TYPES,
  RESERVATION_MAPPING,
} from "../../../constants";
import {
  getNotificationKeyByApiErrorCode,
  NOTIFICATION_MAPPINGS,
} from "../../../utils/notification.utils";
import {
  findBookingSagaAction,
  hideLoadingOverlay,
  setNotification,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* findBookingDetailsByGuid(action) {
  const {guid} = action.payload;

  try {
    const client = yield* getClientTypeFn();
    const res = yield call([client, "getReservationByGuid"], guid);

    const bookingId = get(res.data, RESERVATION_MAPPING.CODE.apiKey);
    const bookingName = get(res.data, RESERVATION_MAPPING.NAME.apiKey);

    yield put(findBookingSagaAction({bookingId, bookingName}));
  } catch (err) {
    yield put(hideLoadingOverlay());

    yield put(
      setNotification({
        type: NOTIFICATION_TYPES.ERROR,
        messageKey: getNotificationKeyByApiErrorCode(
          err.response?.data?.errorCode ||
            NOTIFICATION_MAPPINGS.GENERAL.TECHNICAL.ERROR_CODE
        ),
        isPersistent: true,
      })
    );
  }
}

export function* findBookingDetailsByGuidSaga() {
  yield takeLeading(FIND_BOOKING_DETAILS_BY_GUID__SAGA, findBookingDetailsByGuid);
}
