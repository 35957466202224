const translations = {
  summary: {
    successTitle: "Successfully checked in!",
    welcomeTitle: "Welcome back!",
    yourRoom: "Your Room",
    forwardToKeyOverview: "Continue to the Key Overview",
    preCheckIn: {
      title: "Pre-Check-In completed",
      subtitle: "All data collected",
      text:
        "Thank you! \n\nPlease finalize the <1>Check-in</1>\n on your <1>arrival day.</1>",
    },
  },
};

export {translations};
