import {get, set} from "lodash";

export const updateSourceFromTargetByKey = (
  source,
  target,
  mapping,
  isUiKeyToApi = false,
  defaultValue
) => {
  set(
    target,
    isUiKeyToApi ? mapping.apiKey : mapping.uiKey,
    get(source, isUiKeyToApi ? mapping.uiKey : mapping.apiKey, defaultValue)
  );

  return target;
};

export const getUpdateObjectFn =
  (source, target, isUiToApiMapping = false) =>
  (mapping, defaultValue) =>
    updateSourceFromTargetByKey(source, target, mapping, isUiToApiMapping, defaultValue);
