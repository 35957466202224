const translations = {
  mobileKeys: {
    header: "Schlüsselübersicht",
    title: "Ihre digitalen Schlüssel",
    subtitle:
      "Zum Öffnen der Tür, schalten Sie Ihr Bluetooth an, ziehen Sie den Knopf nach rechts und halten Sie Ihr Handy direkt an die Tür.",
    mobileKeys: "Digitale Schlüssel",
    locked: "Geschlossen",
    open: "Offen",
    processing: "Lädt",
    error: "Fehler",
    room: "Raum",
    touchDoor: "Bitte halten Sie Ihr Handy an die Tür",
    doorOpen: "Tür ist geöffnet",
    secCount: "{{count}} sek",
    validFor: "Ihre digitalen Schlüssel sind gültig für:",
    hrs: "Std.",
    min: "Min.",
    sec: "Sek.",
    expired: "Ihre digitalen Schlüssel sind abgelaufen",
    support: {
      title: "Digitale Schlüssel nicht mehr zugänglich",
      content:
        "Die Zugriffszeit auf Ihre mobilen Schlüssel ist abgelaufen. Wenn Sie noch Zugang zu Ihrem Zimmer benötigen, wenden Sie sich bitte an die Rezeption...",
    },
  },
};

export {translations};
