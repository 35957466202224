import "moment/locale/de";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import {initReactI18next} from "react-i18next";

import {LANGUAGES} from "../constants";
import {translations as de} from "./de";
import {translations as en} from "./en";
import {locales} from "./momentLocale";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .on("languageChanged", (lng) => {
    let detectedLangShort = lng.split("-")[0];

    document.getElementsByTagName("html")[0].setAttribute("lang", detectedLangShort);
    moment.updateLocale(lng, locales[detectedLangShort]);
  })
  .init({
    fallbackLng: [LANGUAGES.DE, LANGUAGES.EN],
    whitelist: [LANGUAGES.DE, LANGUAGES.EN],
    nonExplicitSupportedLngs: false,
    debug: process.env.NODE_ENV !== "productive",
    resources: {
      de,
      en,
    },
    ns: ["common"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
