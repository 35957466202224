import "./ConfirmGoHomeModal.scss";

import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {goHomeAction, toggleConfirmGoHomeModal} from "../../store";
import {Button, buttonTypes} from "../Button";
import {Modal} from "../Modal";

const ConfirmGoHomeModal = () => {
  const {t} = useTranslation(["general"]),
    dispatch = useDispatch();
  const isConfirmGoHomeVisible = useSelector(
    ({supportReducer}) => supportReducer.isConfirmGoHomeVisible
  );

  const handleConfirmationCancel = () => {
    dispatch(toggleConfirmGoHomeModal(false));
  };

  const goToStartPage = () => {
    dispatch(goHomeAction());
  };

  return (
    <Modal
      className="confirm-go-home-modal"
      isVisible={isConfirmGoHomeVisible}
      onHide={handleConfirmationCancel}
      title={t("confirmGoHomePopupTitle")}>
      <div className="confirm-go-home-modal__content">
        {t("confirmGoHomePopupContent")}
      </div>
      <Button
        buttonType={buttonTypes.primary}
        onClick={handleConfirmationCancel}
        className="confirm-go-home-modal__button">
        {t("backToProcess")}
      </Button>
      <Button
        buttonType={buttonTypes.secondary}
        onClick={goToStartPage}
        className="confirm-go-home-modal__button">
        {t("leaveAndGoToHomeScreen")}
      </Button>
    </Modal>
  );
};

export {ConfirmGoHomeModal};
