const translations = {
  summary: {
    successTitle: "Erfolgreich eingecheckt!",
    welcomeTitle: "Willkommen zurück!",
    yourRoom: "Ihr Zimmer",
    forwardToKeyOverview: "Weiter zur Schlüsselübersicht",
    preCheckIn: {
      title: "Pre-Check-In abgeschlossen",
      subtitle: "Alle Daten erfasst.",
      text:
        "Vielen Dank! \n\nBitte schließen Sie den <1>Check-In</1>\n am <1>Tag Ihrer Anreise</1> ab.",
    },
  },
};

export {translations};
