import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

import {POST_KEY_CARD_FINISH__SAGA} from "../../../constants";
import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  setReservationDataSagaAction,
  showLoadingOverlay,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postKeyCardFinish() {
  try {
    yield put(showLoadingOverlay());

    let store = yield select(),
      {code, name} = store.reservationReducer;

    const client = yield* getClientTypeFn();
    const keyFinishResponse = yield call([client, "postKeyCardFinish"], code, name);

    yield put(setReservationDataSagaAction({response: keyFinishResponse}));
  } catch (e) {
    yield put(apiErrorSagaAction({error: e, isAllowedRetry: true}));
  }
  yield put(hideLoadingOverlay());
}

export function* postKeyCardFinishSaga() {
  yield takeEvery(POST_KEY_CARD_FINISH__SAGA, postKeyCardFinish);
}
