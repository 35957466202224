import {translations as covid} from "./covid";
import {translations as date} from "./date";
import {translations as findBooking} from "./findBooking";
import {translations as finish} from "./finish";
import {translations as folio} from "./folio";
import {translations as form} from "./form";
import {translations as general} from "./general";
import {translations as inactivity} from "./inactivity";
import {translations as key} from "./key";
import {translations as mobileKeys} from "./mobileKeys";
import {translations as notification} from "./notification";
import {translations as payment} from "./payment";
import {translations as personalData} from "./personalData";
import {translations as qrCode} from "./qrCode";
import {translations as registration} from "./registration";
import {translations as reservation} from "./reservation";
import {translations as signature} from "./signature";
import {translations as stepper} from "./stepper";
import {translations as summary} from "./summary";
import {translations as support} from "./support";
import {translations as terms} from "./terms";

const translations = {
  locale: {long: "de-DE", short: "de"},
  ...general,
  ...notification,
  ...date,
  ...support,
  ...terms,
  ...form,
  ...inactivity,
  ...findBooking,
  ...qrCode,
  ...registration,
  ...personalData,
  ...signature,
  ...folio,
  ...payment,
  ...key,
  ...reservation,
  ...stepper,
  ...finish,
  ...summary,
  ...mobileKeys,
  ...covid,
};

export {translations};
