import {createReducer} from "@reduxjs/toolkit";

import {
  SHOW_CONFIRM_GO_HOME_MODAL,
  SHOW_HOTEL_SUPPORT,
  SHOW_KEY_SUPPORT,
} from "../../../constants";

let initialState = {
  isHotelSupportVisible: false,
  isKeySupportVisible: false,
  isConfirmGoHomeVisible: false,
};

const supportReducer = createReducer(initialState, (builder) => {
  builder.addCase(SHOW_HOTEL_SUPPORT, (state, action) => {
    state.isHotelSupportVisible = action.payload;
  });
  builder.addCase(SHOW_KEY_SUPPORT, (state, action) => {
    state.isKeySupportVisible = action.payload;
  });
  builder.addCase(SHOW_CONFIRM_GO_HOME_MODAL, (state, action) => {
    state.isConfirmGoHomeVisible = action.payload;
  });
});

export {supportReducer};
