import {push} from "connected-react-router";
import i18next from "i18next";
import {get, isNil} from "lodash";
import {now} from "moment";
import {call, put} from "redux-saga/effects";

import {RESERVATION_MAPPING} from "../../../constants";
import {
  generalDataSagaAction,
  setReservation,
  setReservationDataSagaAction,
  setReservationFetchTime,
  showLoadingOverlay,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

export function* findBookingFn(data) {
  const {bookingId, bookingName, type} = data;

  if (!isNil(bookingId) && !isNil(bookingName) && !isNil(type)) {
    yield put(showLoadingOverlay(i18next.t("findBooking:loadingSearchResults")));

    const bookingIdParsed = bookingId.replace(new RegExp("/", "g"), "-");

    const client = yield* getClientTypeFn();
    const res = yield call(
      [client, "getBookingForCheckIn"],
      bookingIdParsed,
      bookingName
    );

    yield put(setReservationFetchTime(now()));

    const reservation = get(res.data, RESERVATION_MAPPING.apiKey);

    if (!isNil(reservation)) {
      yield put(setReservation(reservation));
    }

    yield put(setReservationDataSagaAction({response: res}));
    yield put(generalDataSagaAction());
  } else {
    yield put(push(`/${type}/search`));
    throw new Error();
  }
}

// todo: refactor into saga.
