import "./PrivacyPoliciesSupport.scss";

import {LANGUAGES, PAYMENT_PRIVACY_LINKS} from "../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";

import {ReactComponent as InformationCircle} from "../../assets/images/Icon_info.svg";
import {Modal} from "../Modal";
import PropTypes from "prop-types";
import QRCodeImage from "qrcode.react";
import {getPrimaryTextColor} from "../../utils/dom.utils";
import {useSelector} from "react-redux";

const PrivacyPoliciesSupport = ({isPaymentPrivacyPolicy = false}) => {
  const {t} = useTranslation(["general"]);

  const general = useSelector(({generalReducer}) => generalReducer),
    signature = useSelector(({signatureReducer}) => signatureReducer),
    {siteData, lang, isTerminal} = general,
    payment = useSelector(({paymentReducer}) => paymentReducer),
    provider =
      payment.paymentResult?.paymentConfiguration?.provider ||
      signature?.providerInfo?.provider;

  const termsAndConditionsUrl =
    provider && lang && PAYMENT_PRIVACY_LINKS[provider]?.[lang];

  const [privacyPolicyLinks, setPrivacyPolicyLinks] = useState({
    en: siteData?.contact?.website || null,
    de: siteData?.contact?.website || null,
  });

  const [isPrivacyPoliciesVisible, setIsPrivacyPoliciesVisible] = useState(false);

  useEffect(() => {
    if (siteData?.siteLegalTextTranslations?.length > 0) {
      let newPrivacyLinks = {};

      siteData.siteLegalTextTranslations.forEach((item) => {
        if (item.languageCode === LANGUAGES.DE) {
          newPrivacyLinks[LANGUAGES.DE] = item.termsAndConditionsUrl;
        } else if (item.languageCode === LANGUAGES.EN) {
          newPrivacyLinks[LANGUAGES.EN] = item.termsAndConditionsUrl;
        }
      });

      setPrivacyPolicyLinks(newPrivacyLinks);
    }
  }, [siteData?.siteLegalTextTranslations]);

  const handlePrivacyPoliciesSupportClick = () => {
    if (isPaymentPrivacyPolicy) {
      if (!isTerminal) {
        window.open(termsAndConditionsUrl, "_blank");
      }
    } else {
      setIsPrivacyPoliciesVisible(
        (isPrivacyPoliciesVisible) => !isPrivacyPoliciesVisible
      );
    }
  };

  const hidePrivacyPoliciesModal = useCallback(() => {
    setIsPrivacyPoliciesVisible(false);
  }, []);

  return (
    <div
      className={`privacy-support ${
        isPaymentPrivacyPolicy ? "privacy-support--payment" : ""
      }`}>
      <div className="privacy-support__text">
        <InformationCircle className="icon" />

        {!!isPaymentPrivacyPolicy &&
          (isTerminal ? (
            <div
              className="privacy-support__button"
              onClick={handlePrivacyPoliciesSupportClick}>
              {t("general:privacyPolicyScan")}
            </div>
          ) : (
            <div
              className="privacy-support__button"
              onClick={handlePrivacyPoliciesSupportClick}>
              {t("general:privacyPolicy")}
            </div>
          ))}

        {!isPaymentPrivacyPolicy && (
          <div className="privacy-support__button">
            {/* prettier-ignore */}
            <Trans i18nKey="support:privacyPolicy" transWrapTextNodes>
            Your data is safe. <span
              className="privacy-support__clickable-text"
              onClick={handlePrivacyPoliciesSupportClick}>
              Click here
            </span> to learn more.
          </Trans>
          </div>
        )}
      </div>

      {isTerminal && isPaymentPrivacyPolicy && (
        <div>
          <QRCodeImage
            width="96"
            height="96"
            className="qr-code"
            value={termsAndConditionsUrl || ""}
            fgColor={getPrimaryTextColor()}
          />
        </div>
      )}

      <Modal
        className="privacy-support__modal"
        isVisible={isPrivacyPoliciesVisible}
        onHide={hidePrivacyPoliciesModal}
        doesHideOnBlur>
        <iframe src={privacyPolicyLinks[lang]} title="Privacy Policies Website"></iframe>
      </Modal>
    </div>
  );
};

PrivacyPoliciesSupport.propTypes = {
  isPaymentPrivacyPolicy: PropTypes.bool,
};

export {PrivacyPoliciesSupport};
