import {Button, ButtonGroup, buttonTypes} from "../Button";

import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

const BackNextButtons = ({
  isNextButtonActive,
  textForNext = "",
  textForBack = "",
  onClickBack = () => {},
  onClickNext = () => {},
}) => {
  const {t} = useTranslation(["general"]);

  return (
    <ButtonGroup>
      <Button buttonType={buttonTypes.secondary} onClick={onClickBack}>
        {textForBack || t("back")}
      </Button>
      <Button
        buttonType={isNextButtonActive ? buttonTypes.primary : buttonTypes.inactive}
        onClick={onClickNext}>
        {textForNext || t("next")}
      </Button>
    </ButtonGroup>
  );
};

BackNextButtons.propTypes = {
  isNextButtonActive: PropTypes.bool,
  textForNext: PropTypes.string,
  textForBack: PropTypes.string,
  onClickBack: PropTypes.func,
  onClickNext: PropTypes.func,
};

export {BackNextButtons};
