import {
  apiErrorSagaAction,
  setAppConfiguration,
  setGuestAuthConfiguration,
} from "../../actions";
import {call, put} from "redux-saga/effects";

import {CONFIG__SAGA} from "../../../constants";
import {getClientTypeFn} from "./../utils/getClientType.generatorFunction";
import {takeEvery} from "redux-saga/effects";

function* handleConfigSaga() {
  try {
    let client = yield* getClientTypeFn();
    const appConfig = yield call([client, "getConfiguration"]);

    if (appConfig.status === 200 && appConfig.data) {
      yield put(setAppConfiguration(appConfig.data));
    } else {
      yield put(apiErrorSagaAction());
    }

    // todo: move the getGuestAuthConfiguration call to auth page.
    client = yield* getClientTypeFn();
    const guestAuthConfig = yield call([client, "getGuestAuthConfiguration"]);
    yield put(setGuestAuthConfiguration(guestAuthConfig.data));
  } catch (e) {
    yield put(apiErrorSagaAction({error: e}));
  }
}

export function* configSaga() {
  yield takeEvery(CONFIG__SAGA, handleConfigSaga);
}
