import "./Search.scss";

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";

import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";

import {Plugins} from "@capacitor/core";

import {ReactComponent as BellBoyIcon} from "../../assets/images/Icon_bell_boy_list.svg";
import {ReactComponent as InformationCircle} from "../../assets/images/Icon_info.svg";
import {NotificationsPlaceholder, Page} from "../../components/Page";
import {BackNextButtons} from "../../components/PageNavBtn";
import {PrivacyPoliciesSupport} from "../../components/PrivacyPolicies";
import {QrCode} from "../../components/QrCode";
import {
  FORM_REQUIREMENTS,
  LOOKUP_FIELDS_MAPPING,
  NOTIFICATION_TYPES,
  STATUS,
} from "../../constants";
import {goHomeAction} from "../../store";
import {
  findBookingSagaAction,
  hideAllNotifications,
  postLookupFieldsSagaAction,
  setNotification,
} from "../../store/actions";
import {useForm} from "../../utils/customHooks/form.hook";
import {isEmailValid, isNameValid} from "../../utils/formInput.utils";
import {isNativeApp} from "../../utils/mobileDevice.utils";
import {
  getNotificationNameByText,
  NOTIFICATION_MAPPINGS,
  notificationMessages,
} from "../../utils/notification.utils";
import {scrollToErrorAndFocus} from "../../utils/scrollToEvent.utils";
import {BookingNumber} from "./BookingNumber";
import {PersonalData} from "./PersonalData";
import {isJsonString} from "../../utils/json.utils";

const {BarcodeScanner} = Plugins;

const SEARCH_TYPES = {
  BOOKING_NUMBER: "bookingNumber",
  PERSONAL_DATA: "personalData",
  QR_CODE: "QRCode",
};

const Search = () => {
  const {t} = useTranslation(["findBooking", "general"]),
    dispatch = useDispatch();

  const lookupFields = useSelector(({lookupFieldsReducer}) => lookupFieldsReducer),
    {requirements = {}} = lookupFields;

  const guestAuthOptions = useSelector(
    ({generalReducer}) => generalReducer.guestAuthConfiguration
  );

  const enabledSearchTypes = useMemo(
    () => [
      ...(guestAuthOptions?.byReservationNumberEnabled
        ? [SEARCH_TYPES.BOOKING_NUMBER]
        : []),
      ...(guestAuthOptions?.byPersonalDataEnabled ? [SEARCH_TYPES.PERSONAL_DATA] : []),
      ...(guestAuthOptions?.byQrCodeEnabled ? [SEARCH_TYPES.QR_CODE] : []),
    ],
    [guestAuthOptions]
  );

  const [openSection, setOpenSection] = useState(""),
    [isGoBackQRScanning, setIsGoBackQRScanning] = useState(false),
    [isScanning, setIsScanning] = useState(false);

  const formHelper = useForm();

  const scrollbarContainerRef = useRef(null);

  const [bookingNumberData, setBookingNumberData] = useState({
      [LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey]: "",
      [LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey]: "",
    }),
    [invalidBookingNumberData, setInvalidBookingNumberData] = useState({
      [LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey]: true,
      [LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey]: true,
    }),
    [personalData, setPersonalData] = useState({}),
    [invalidPersonalData, setInvalidPersonalData] = useState({}),
    [isPersonalDataConfirmed, setIsPersonalDataConfirmed] = useState(false),
    [lastSearchedSection, setLastSearchedSection] = useState();

  const [isScanSuccessful, setIsScanSuccessful] = useState(false),
    [scannedText, setScannedText] = useState(""),
    [isQrCodeClosing, setIsQrCodeClosing] = useState(false);

  useEffect(() => {
    setOpenSection(enabledSearchTypes.length > 0 ? enabledSearchTypes[0] : "");
  }, [enabledSearchTypes]);

  const updateBookingNumberDataValidity = useCallback(
    (searchedData) => {
      let invalidValues = {};

      Object.entries(searchedData).forEach(([key, value]) => {
        invalidValues[key] =
          !formHelper.isValid(FORM_REQUIREMENTS.REQUIRED, value) ||
          (key === LOOKUP_FIELDS_MAPPING.MAIN_GUEST.LAST_NAME.uiKey &&
            !isNameValid(value));
      });

      setInvalidBookingNumberData((lastState) => ({
        ...lastState,
        ...invalidValues,
      }));
    },
    [formHelper]
  );

  const isValidBookingNumberData = useCallback(() => {
    const objectValues = Object.values(invalidBookingNumberData);
    const invalidValues = objectValues.filter(Boolean);

    return objectValues.length > 0 && invalidValues.length === 0;
  }, [invalidBookingNumberData]);

  const updatePersonalDataValidity = useCallback(
    (searchedData) => {
      setInvalidPersonalData((lastState) => {
        let invalidValues = {};

        Object.entries(searchedData).forEach(([key, value]) => {
          let isValid = formHelper.isValid(
            requirements[key]
              ? FORM_REQUIREMENTS.REQUIRED
              : FORM_REQUIREMENTS.NOT_AVAILABLE,
            value
          );

          if (
            [
              LOOKUP_FIELDS_MAPPING.MAIN_GUEST.FIRST_NAME.uiKey,
              LOOKUP_FIELDS_MAPPING.MAIN_GUEST.LAST_NAME.uiKey,
            ].includes(key) &&
            isValid
          ) {
            isValid = isNameValid(value);
          } else if (key === LOOKUP_FIELDS_MAPPING.MAIN_GUEST.EMAIL.uiKey && isValid) {
            isValid = isEmailValid(value);
          }

          invalidValues[key] = !isValid;
        });

        return {...lastState, ...invalidValues};
      });
    },
    [formHelper, requirements]
  );

  const isValidPersonalData = useCallback(() => {
    const objectValues = Object.values(invalidPersonalData);
    const invalidValues = objectValues.filter(Boolean);

    return (
      isPersonalDataConfirmed && objectValues.length > 0 && invalidValues.length === 0
    );
  }, [invalidPersonalData, isPersonalDataConfirmed]);

  const isValid = useCallback(() => {
    switch (openSection) {
      case SEARCH_TYPES.BOOKING_NUMBER: {
        return isValidBookingNumberData();
      }
      case SEARCH_TYPES.PERSONAL_DATA: {
        return isValidPersonalData();
      }
      case SEARCH_TYPES.QR_CODE: {
        return isScanSuccessful;
      }
      default: {
        return false;
      }
    }
  }, [isScanSuccessful, isValidBookingNumberData, isValidPersonalData, openSection]);

  const handleClickBack = () => {
    const isQrCodeSection = openSection === SEARCH_TYPES.QR_CODE;

    if (isQrCodeSection && isScanning) {
      setIsGoBackQRScanning(true);
      return;
    }

    dispatch(goHomeAction());
  };

  const handleToggleSection = (type) => {
    if (isNativeApp) {
      BarcodeScanner.stopScan();
      setIsQrCodeClosing(
        openSection === SEARCH_TYPES.QR_CODE && type !== SEARCH_TYPES.QR_CODE
      );
    }
    dispatch(hideAllNotifications());
    setOpenSection(type);
  };

  const handleClickNext = () => {
    switch (openSection) {
      case SEARCH_TYPES.BOOKING_NUMBER:
        searchBookingByNumber();
        break;
      case SEARCH_TYPES.PERSONAL_DATA:
        searchBookingByPersonalData();
        break;
      case SEARCH_TYPES.QR_CODE:
        searchQrCode();
        break;
      default:
        setOpenSection(SEARCH_TYPES.BOOKING_NUMBER);
        setLastSearchedSection(SEARCH_TYPES.BOOKING_NUMBER);
        setTimeout(scrollToErrorAndFocus, 300);
        break;
    }
  };

  const searchBookingByPersonalData = () => {
    document.activeElement.blur();

    if (!isValidPersonalData()) {
      setLastSearchedSection(SEARCH_TYPES.PERSONAL_DATA);

      scrollToErrorAndFocus();
      return;
    }

    new Promise((resolve, reject) => {
      dispatch(postLookupFieldsSagaAction({searchData: personalData, resolve, reject}));
    }).catch((error) => {
      if (error.errors) {
        setInvalidPersonalData({...Object.keys(error.errors)});
      }
    });
  };

  const searchBookingByNumber = useCallback(() => {
    document.activeElement.blur();

    if (!isValidBookingNumberData()) {
      setLastSearchedSection(SEARCH_TYPES.BOOKING_NUMBER);

      scrollToErrorAndFocus();
      return;
    }

    new Promise((resolve, reject) => {
      dispatch(
        findBookingSagaAction({
          bookingId: bookingNumberData[LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey],
          bookingName: bookingNumberData[LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey],
          resolve,
          reject,
        })
      );
    }).catch((error) => {
      if (error?.status === STATUS.ERROR) {
        setInvalidBookingNumberData({
          [LOOKUP_FIELDS_MAPPING.BOOKING_ID.uiKey]:
            error.errorCode ===
            NOTIFICATION_MAPPINGS.AUTHENTICATION.INCORRECT_CODE.ERROR_CODE,
          [LOOKUP_FIELDS_MAPPING.BOOKING_NAME.uiKey]:
            error.errorCode ===
            NOTIFICATION_MAPPINGS.AUTHENTICATION.NAME_NOT_FOUND.ERROR_CODE,
        });
      }
    });
  }, [bookingNumberData, dispatch, isValidBookingNumberData]);

  const searchQrCode = () => {
    if (!scannedText.trim() && !isJsonString(scannedText)) {
      return;
    }

    try {
      if (isJsonString(scannedText)) {
        const {uuid} = JSON.parse(scannedText);
        if (uuid && typeof uuid === "string") {
          dispatch(push(`/reservation-id/${uuid}`));
        }
      } else {
        var parsedUrl = new URL(scannedText);

        new Promise(() => {
          dispatch(push(parsedUrl.pathname));
        });
      }
    } catch (error) {
      if (
        error.Status === STATUS.ERROR ||
        error instanceof SyntaxError ||
        error instanceof TypeError
      ) {
        dispatch(
          setNotification({
            type: NOTIFICATION_TYPES.WARNING,
            messageKey: getNotificationNameByText(
              notificationMessages.warning.noValidQrCode
            ),
          })
        );
      }
    }

    setIsScanSuccessful(false);
    setScannedText("");
  };

  const isValidQR = (data) => {
    let isValid = false;

    try {
      if (isJsonString(data)) {
        let {uuid} = JSON.parse(data);

        isValid = !!uuid?.trim();
      } else {
        const values = data.split("/").slice(-2);

        if (values.length === 2) {
          isValid = true;
        }
      }
    } catch {
      isValid = false;
    }

    return isValid;
  };

  const handleSuccessScan = (text) => {
    setIsScanSuccessful(true);
    setScannedText(text);
  };

  const handleBookingNumberChange = useCallback(
    (searchedData) => {
      setBookingNumberData((lastState) => ({...lastState, ...searchedData}));
      updateBookingNumberDataValidity(searchedData);
    },
    [updateBookingNumberDataValidity]
  );

  const handlePersonalDataChange = (searchedData) => {
    setPersonalData((lastState) => ({...lastState, ...searchedData}));
    updatePersonalDataValidity(searchedData);
  };

  const handleToggleConfirmPersonalData = (isChecked) => {
    setIsPersonalDataConfirmed(isChecked);
  };

  const handleNavigation = (type) => {
    handleToggleSection(type);

    const scrollWidth =
      scrollbarContainerRef.current.scrollWidth -
      scrollbarContainerRef.current.clientWidth;

    if (type === enabledSearchTypes[enabledSearchTypes.length - 1]) {
      scrollbarContainerRef.current.scrollLeft = scrollWidth;
    } else if (type === enabledSearchTypes[0]) {
      scrollbarContainerRef.current.scrollLeft = 0;
    } else if (type === enabledSearchTypes[1]) {
      scrollbarContainerRef.current.scrollLeft = scrollWidth / 2;
    }
  };

  const handleIsScanningChange = useCallback((isScanning) => {
    setIsScanning(isScanning);
    setIsGoBackQRScanning(false);
  }, []);

  return (
    <>
      <Page
        Icon={BellBoyIcon}
        title={t("title")}
        subtitle={t("subTitle")}
        withStepper={false}
        className="search-page">
        <Page.Content>
          <div className="navigation">
            <div className="navigation__left-shadow" />
            <div className="navigation__scrollbar-container" ref={scrollbarContainerRef}>
              <div
                className={`navigation-tabs navigation-tabs--${enabledSearchTypes.length}`}>
                {guestAuthOptions?.byReservationNumberEnabled && (
                  <div
                    onClick={() => handleNavigation(SEARCH_TYPES.BOOKING_NUMBER)}
                    className={`navigation-tabs__item ${
                      openSection === SEARCH_TYPES.BOOKING_NUMBER
                        ? "navigation-tabs__item--active"
                        : ""
                    }`}>
                    {t("bookingId")}
                  </div>
                )}
                {guestAuthOptions?.byPersonalDataEnabled && (
                  <div
                    onClick={() => handleNavigation(SEARCH_TYPES.PERSONAL_DATA)}
                    className={`navigation-tabs__item ${
                      openSection === SEARCH_TYPES.PERSONAL_DATA
                        ? "navigation-tabs__item--active"
                        : ""
                    }`}>
                    {t("personalDetails")}
                  </div>
                )}
                {guestAuthOptions?.byQrCodeEnabled && (
                  <div
                    onClick={() => handleNavigation(SEARCH_TYPES.QR_CODE)}
                    className={`navigation-tabs__item ${
                      openSection === SEARCH_TYPES.QR_CODE
                        ? "navigation-tabs__item--active"
                        : ""
                    }`}>
                    {t("scanQrCode")}
                  </div>
                )}
              </div>
            </div>
            <div className="navigation__right-shadow" />
          </div>

          {openSection === SEARCH_TYPES.BOOKING_NUMBER && (
            <BookingNumber
              bookingData={bookingNumberData}
              onSearch={searchBookingByNumber}
              invalidData={invalidBookingNumberData}
              onChange={handleBookingNumberChange}
              isSearched={lastSearchedSection === SEARCH_TYPES.BOOKING_NUMBER}
            />
          )}
          {openSection === SEARCH_TYPES.PERSONAL_DATA && (
            <PersonalData
              personalData={personalData}
              invalidData={invalidPersonalData}
              onChange={handlePersonalDataChange}
              isSearched={lastSearchedSection === SEARCH_TYPES.PERSONAL_DATA}
              onSearch={handleClickNext}
              isPersonalDataConfirmed={isPersonalDataConfirmed}
              onToggleConfirm={handleToggleConfirmPersonalData}
            />
          )}
          {openSection === SEARCH_TYPES.QR_CODE && (
            <QrCode
              isScanSuccessful={isScanSuccessful}
              scannedText={scannedText}
              onSuccessScan={handleSuccessScan}
              isClosing={isQrCodeClosing}
              shouldStopScanning={isGoBackQRScanning}
              onIsScanningChange={handleIsScanningChange}
              isValidQR={isValidQR}
            />
          )}
        </Page.Content>

        <Page.Footer>
          <NotificationsPlaceholder />

          {openSection === SEARCH_TYPES.BOOKING_NUMBER && (
            <span className="booking__info">
              <InformationCircle className="icon" />
              {t("bookingNumberInfo")}
            </span>
          )}
          <PrivacyPoliciesSupport />
          <BackNextButtons
            isNextButtonActive={isValid()}
            onClickBack={handleClickBack}
            onClickNext={handleClickNext}
          />
        </Page.Footer>
      </Page>
    </>
  );
};

Search.propTypes = {
  backToRoute: PropTypes.string,
  type: PropTypes.string,
};

export {Search};
