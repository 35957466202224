import {
  get,
  set,
} from "lodash";

import {createReducer} from "@reduxjs/toolkit";

import {
  PAYMENT_MAPPING,
  SET_PAYMENT_INSTRUMENTS,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_PENDING,
  SET_PAYMENT_RESULT,
} from "../../../constants";
import {getUpdateObjectFn} from "../../../utils/state.utils";

let initialState = {
  paymentResult: {
    paymentUuid: undefined,
    redirectUrl: undefined,
    paymentStatus: undefined,
    action: undefined,
    paymentConfiguration: {
      provider: undefined,
      merchantId: undefined,
      configuration: undefined,
      redirectParameters: [],
    },
  },
  paymentPending: false,
  paymentMethods: [],
  paymentInstruments: [
    {
      id: undefined,
    },
  ],
};

const paymentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_PAYMENT_RESULT, (state, action) => {
      const paymentResult = {};
      const updatePaymentResult = getUpdateObjectFn(action.payload, paymentResult);

      updatePaymentResult(PAYMENT_MAPPING.PAYMENT_RESULT.PAYMENT_UUID);
      updatePaymentResult(PAYMENT_MAPPING.PAYMENT_RESULT.CODE);
      updatePaymentResult(PAYMENT_MAPPING.PAYMENT_RESULT.TOKEN);
      updatePaymentResult(PAYMENT_MAPPING.PAYMENT_RESULT.REDIRECT_URL);
      updatePaymentResult(PAYMENT_MAPPING.PAYMENT_RESULT.PAYMENT_STATUS);
      updatePaymentResult(PAYMENT_MAPPING.PAYMENT_RESULT.ACTION);
      updatePaymentResult(
        PAYMENT_MAPPING.PAYMENT_RESULT.PAYMENT_CONFIGURATION.CONFIGURATION
      );
      updatePaymentResult(
        PAYMENT_MAPPING.PAYMENT_RESULT.PAYMENT_CONFIGURATION.MERCHANT_ID
      );
      updatePaymentResult(PAYMENT_MAPPING.PAYMENT_RESULT.PAYMENT_CONFIGURATION.PROVIDER);

      const redirectParameters = get(
        paymentResult,
        PAYMENT_MAPPING.PAYMENT_RESULT.REDIRECT_PARAMETERS.apiKey,
        []
      ).map((result) => {
        const mappedResult = {};
        const updateResult = getUpdateObjectFn(result, mappedResult);

        updateResult(PAYMENT_MAPPING.PAYMENT_RESULT.REDIRECT_PARAMETERS.KEY);
        updateResult(PAYMENT_MAPPING.PAYMENT_RESULT.REDIRECT_PARAMETERS.VALUE);

        return mappedResult;
      });

      set(
        paymentResult,
        PAYMENT_MAPPING.PAYMENT_RESULT.REDIRECT_PARAMETERS.uiKey,
        redirectParameters
      );

      set(state, PAYMENT_MAPPING.PAYMENT_RESULT.uiKey, paymentResult);
    })
    .addCase(SET_PAYMENT_METHODS, (state, action) => {
      state.paymentMethods = action.payload || [];
    })
    .addCase(SET_PAYMENT_INSTRUMENTS, (state, action) => {
      state.paymentInstruments = (action.payload || []).map((instrument) => {
        let mappedInstrument = {};

        const updateInstrument = getUpdateObjectFn(instrument, mappedInstrument);

        updateInstrument(PAYMENT_MAPPING.PAYMENT_INSTRUMENTS.ID);

        return mappedInstrument;
      });
    })
    .addCase(SET_PAYMENT_PENDING, (state, action) => {
      set(state, PAYMENT_MAPPING.PAYMENT_PENDING.uiKey, action.payload || false);
    });
});

export {paymentReducer};
