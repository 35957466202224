const translations = {
  registration: {
    registrationFormPagesTitle: "Tell us more about yourself",
    registrationFormPagesSubtitle:
      "Your Data is safe and required only for stay purposes.",
    contactFormTitle: "Additional Information",
    contactFormSubtitle: "Your Data is safe and required only for stay purposes.",
    title: "Complete guest data",
    address: "Registration address",
    guest: "Guest",
    moreGuests: "Additional guests",
    travelType: "What is the reason for your travel?",
    addBillingAddress: "Another Billing Address",
    additionalGuestsChange:
      "If you are arriving with fewer or more people than what is on file in your reservation, please contact the hotel.",
    legalAge: "You must be at least {{age}} years old",
  },
};

export {translations};
