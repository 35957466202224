import {
  get,
  isNil,
} from "lodash";
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

import {
  SEND_SIGNATURE__SAGA,
  SIGNATURE_MAPPING,
} from "../../../constants";
import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  showLoadingOverlay,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* sendSignature(action) {
  let {
    reject = null,
    resolve = null,
    signature = "",
    paymentType = "",
    paymentCode = "",
  } = action.payload;

  try {
    if (!signature && (!paymentCode || !paymentType)) {
      return;
    }

    let store = yield select(),
      {code, name} = store.reservationReducer,
      {type} = store.routesReducer;

    yield put(showLoadingOverlay());

    const client = yield* getClientTypeFn();

    const signatureResp = yield call([client, "postSignature"], code, name, {
      signature,
      creditCardData: {
        browserInformation: {
          colorDepth: 24,
          screenWidth: window.innerWidth,
          screenHeight: window.innerHeight,
          acceptHeader:
            "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
          userAgent: window.navigator.userAgent,
          userHostAddress: null,
          origin: window.origin,
        },
        successUrl: `${window.location.origin}/${type}/${code}/${name}/signature`,
        failureUrl: `${window.location.origin}/${type}/${code}/${name}/signature?error=1`,
        signatureInstrumentType: paymentType,
        signatureInstrumentCode: paymentCode,
      },
    });

    const registrationFormSignature = get(signatureResp.data, SIGNATURE_MAPPING.apiKey);

    if (!isNil(registrationFormSignature)) {
      const redirectUrl = get(
        registrationFormSignature,
        SIGNATURE_MAPPING.REDIRECT_URL.apiKey,
        ""
      );

      if (redirectUrl.length > 0) {
        window.location.href = redirectUrl;
      }
    }

    if (resolve) {
      resolve(signatureResp);
    }
  } catch (e) {
    yield put(apiErrorSagaAction({error: e, isAllowedRetry: true}));

    if (reject) {
      reject();
    }
  }

  yield put(hideLoadingOverlay());
}

export function* sendSignatureSaga() {
  yield takeEvery(SEND_SIGNATURE__SAGA, sendSignature);
}
