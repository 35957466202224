import React from "react";

import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import {Search} from "../../Search";
import {RenderRoutes} from "./RenderRoutes.component.js";

const CheckInRoutes = ({nextRoutes}) => {
  const {path} = useRouteMatch();

  const reservation = useSelector(({reservationReducer}) => reservationReducer),
    {code, name} = reservation;

  const {pathname} = useLocation();

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={({location}) => (
          <Redirect
            to={{
              pathname: `${path}/search`,
              state: {from: location},
            }}
          />
        )}
      />
      <Route path={`${path}/search`}>
        <Search />
      </Route>

      <>
        {nextRoutes.map((route, i) => (
          <RenderRoutes key={i} {...route} path={`${path}/${code}/${name}`} />
        ))}
        <Route>
          {nextRoutes.length > 0 &&
            !nextRoutes.find((route) =>
              pathname.startsWith(`${path}/${code}/${name}${route.pathname}`)
            ) && <Redirect to={`${path}/${code}/${name}`} />}
        </Route>
      </>
    </Switch>
  );
};

CheckInRoutes.propTypes = {
  nextRoutes: PropTypes.arrayOf(PropTypes.object),
};

export {CheckInRoutes};
