import "./Stepper.scss";

import React from "react";

import {useSelector} from "react-redux";

import {ReactComponent as SummaryIcon} from "../../assets/images/Icon_bed.svg";
import {ReactComponent as BillIcon} from "../../assets/images/Icon_bill.svg";
import {ReactComponent as CheckMark} from "../../assets/images/Icon_checkmark.svg";
import {ReactComponent as GuestIcon} from "../../assets/images/Icon_guest_id.svg";
import {ReactComponent as HotelIcon} from "../../assets/images/Icon_hotel.svg";
import {ReactComponent as LoginKeys} from "../../assets/images/Icon_keys.svg";
import {
  ReactComponent as CheckoutSummaryIcon,
} from "../../assets/images/Icon_luggage_plane.svg";
import {
  FLOWS,
  STEPS,
} from "../../constants";
import {
  checkInRules,
  checkOutRules,
} from "../../utils/route.utils";
import {Step} from "./Step.component";

const UI_STEPS = {
  RESERVATION_OVERVIEW: "RESERVATION_OVERVIEW",
  REGISTRATION: "REGISTRATION",
  PAYMENT: "PAYMENT",
  SUMMARY: "SUMMARY",
  CHECKIN_FINISH: "CHECKIN_FINISH",
  KEY_ENCODING: "KEY_ENCODING",
  CHECKOUT_FINISH: "CHECKOUT_FINISH",
  CHECKOUT_SUMMARY: "CHECKOUT_SUMMARY",
};

const Stepper = () => {
  const {steps} = useSelector(({generalReducer}) => generalReducer),
    routes = useSelector(({routesReducer}) => routesReducer),
    {next} = routes;

  const currentFlow = routes.flow;
  const currentStep = next;

  const isPaymentActive =
    steps &&
    (steps.indexOf(STEPS.PAYMENT) !== -1 || steps.indexOf(STEPS.COST_OVERVIEW) !== -1);
  const isKeyEncodingEnabled = steps && steps.indexOf(STEPS.KEY_ENCODING) !== -1;
  const checkInProcessIsEnabled =
    steps && steps.indexOf(STEPS.REGISTRATION_FORM_COMPLETED) !== -1 ? false : true;

  const preCheckInSteps = [
    {
      icon: <HotelIcon className="fill" width="29.4px" height="30.24px" />,
      label: "overview",
      name: UI_STEPS.RESERVATION_OVERVIEW,
    },
    {
      icon: <GuestIcon className="fill" width="24px" height="25px" />,
      label: "guest_registration",
      name: UI_STEPS.REGISTRATION,
    },
    ...(isPaymentActive
      ? [
          {
            icon: <BillIcon className="stroke" width="20px" height="26px" />,
            label: "payment",
            name: UI_STEPS.PAYMENT,
          },
        ]
      : []),
    {
      icon: <CheckMark className="fill" width="17.45px" height="16.87px" />,
      label: "pre_checkin_summary",
      name: UI_STEPS.SUMMARY,
    },
  ];

  const checkInSteps = [
    {
      icon: <HotelIcon className="fill" width="29.4px" height="30.24px" />,
      label: "overview",
      name: UI_STEPS.RESERVATION_OVERVIEW,
    },
    {
      icon: <GuestIcon className="fill" width="24px" height="25px" />,
      label: "guest_registration",
      name: UI_STEPS.REGISTRATION,
    },
    ...(isPaymentActive
      ? [
          {
            icon: <BillIcon className="stroke" width="20px" height="26px" />,
            label: "payment",
            name: UI_STEPS.PAYMENT,
          },
        ]
      : []),
    {
      icon: <CheckMark className="checkmark" />,
      label: "checkin_finish",
      name: UI_STEPS.CHECKIN_FINISH,
    },
    ...(isKeyEncodingEnabled
      ? [
          {
            icon: <LoginKeys className="stroke" width="24px" height="24px" />,
            label: "issue_key",
            name: UI_STEPS.KEY_ENCODING,
          },
        ]
      : []),
    {
      icon: <SummaryIcon className="fill" width="26px" height="24px" />,
      label: "checkin_summary",
      name: UI_STEPS.SUMMARY,
    },
  ];

  const checkOutSteps = [
    ...(isPaymentActive
      ? [
          {
            icon: <BillIcon className="stroke" width="20px" height="26px" />,
            label: "payment",
            name: UI_STEPS.PAYMENT,
          },
        ]
      : []),
    {
      icon: <CheckMark className="fill" width="17.45px" height="16.87px" />,
      label: "checkout_finish",
      name: UI_STEPS.CHECKOUT_FINISH,
    },
    {
      icon: <CheckoutSummaryIcon className="fill" width="26px" height="26px" />,
      label: "checkout_summary",
      name: UI_STEPS.CHECKOUT_SUMMARY,
    },
  ];

  return (
    <div className="stepper">
      <ul
        className={`stepper-container ${
          steps && steps.length >= 6 ? "stepper-container--compressed" : ""
        }`}>
        {(currentFlow === FLOWS.PRE_CHECK_IN || !checkInProcessIsEnabled) &&
          preCheckInSteps.map((step) => (
            <Step
              currentStep={currentStep}
              rules={checkInRules}
              step={step}
              key={`step-${step.name}`}
            />
          ))}
        {currentFlow === FLOWS.CHECK_IN &&
          checkInProcessIsEnabled &&
          checkInSteps.map((step) => (
            <Step
              step={step}
              currentStep={currentStep}
              rules={checkInRules}
              isCheckMarkInMiddleOfStep={step.name === UI_STEPS.CHECKIN_FINISH}
              key={`step-${step.name}`}
            />
          ))}
        {currentFlow === FLOWS.CHECK_OUT &&
          checkOutSteps.map((step) => (
            <Step
              step={step}
              rules={checkOutRules}
              currentStep={currentStep}
              key={`step-${step.name}`}
            />
          ))}
      </ul>
    </div>
  );
};

export {Stepper};
