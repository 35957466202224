const translations = {
  qrCode: {
    title: "Scan QR Code",
    startScan: "Start Scan",
    stopScan: "Stop Scan",
    msg:
      "Your device does not support writing your access card. Please go to one of the Check-In terminals to receive your access card.",
    info: "You can use the QR code to speed up your process at the terminal.",
    scanSuccessful: "Scan successful",
    hint: "Please point the camera on the QR code",
    additionalHint: "Press on the play button if camera doesn’t load",
  },
};

export {translations};
