import {
  SET_SIGNATURE_CHALLENGE_DATA,
  SET_SIGNATURE_PROVIDER_INFO,
  SET_SIGNATURE_REDIRECT_URL,
  SIGNATURE_MAPPING,
} from "../../../constants";

import {createReducer} from "@reduxjs/toolkit";
import {get} from "lodash";

let initialState = {
  redirectUrl: "",
  providerInfo: {
    provider: undefined,
    externalSystemUsername: undefined,
  },
  challengeData: {
    acsUrl: undefined,
    base64EncodedChallengeRequest: undefined,
    challengeWindowSize: undefined,
  },
};

const signatureReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_SIGNATURE_PROVIDER_INFO, (state, action) => {
      state.providerInfo = {
        provider: get(action.payload, SIGNATURE_MAPPING.PROVIDER.apiKey),
        externalSystemUsername: get(
          action.payload,
          SIGNATURE_MAPPING.EXTERNAL_SYSTEM_USERNAME.apiKey
        ),
      };
    })
    .addCase(SET_SIGNATURE_REDIRECT_URL, (state, action) => {
      state.redirectUrl = action.payload;
    })
    .addCase(SET_SIGNATURE_CHALLENGE_DATA, (state, action) => {
      state.challengeData = {
        acsUrl: get(action.payload, SIGNATURE_MAPPING.ACS_URL.apiKey),
        base64EncodedChallengeRequest: get(
          action.payload,
          SIGNATURE_MAPPING.BASE64_ENCODED_CHALLENGE_REQUEST.apiKey
        ),
        challengeWindowSize: get(
          action.payload,
          SIGNATURE_MAPPING.CHALLENGE_WINDOW_SIZE.apiKey
        ),
      };
    });
});

export {signatureReducer};
