import "./Router.scss";

import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";

import {push} from "connected-react-router";
import {isNil} from "lodash";
import {useTranslation} from "react-i18next";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import {Plugins} from "@capacitor/core";

import {ConfirmGoHomeModal} from "../../components/ConfirmGoHomeModal";
import {InactivityOverlay} from "../../components/InactivityOverlay";
import {LoadingOverlay} from "../../components/LoadingOverlay";
import {Notifications} from "../../components/Notifications";
import {NotificationsPlaceholder} from "../../components/Page";
import {PageHeader} from "../../components/PageHeader";
import {
  FLOWS,
  LANGUAGES,
  ROUTE_PATHS,
  ROUTES,
  STEPS,
} from "../../constants";
import {isTerminal} from "../../env";
import {
  findBookingUrlSagaAction,
  setCurrentFlow,
  setCurrentNext,
  setIsTerminal,
  setLang,
} from "../../store/actions";
import {useDidMount} from "../../utils/customHooks/didMount.hook";
import {usePreviousValue} from "../../utils/customHooks/previousValue.hook";
import {useUpdatedManifest} from "../../utils/customHooks/updatedManifest.hook";
import {
  attachTerminalInputEventListeners,
  removeKeyboardPadding,
} from "../../utils/inputKeyBoardEvent.utils";
import {isNativeApp} from "../../utils/mobileDevice.utils";
import {
  getLanguageQueryParam,
  isStepInFlow,
} from "../../utils/route.utils";
import {scrollToTop} from "../../utils/scrollToEvent.utils";
import {ReservationByGuid} from "../ReservationByGuid/ReservationByGuid.component";
import {StartPage} from "../StartPage";
import {routes} from "./router.constants";
import {
  CheckInRoutes,
  CheckOutRoutes,
} from "./RoutesComponents";

const Router = () => {
  const {AssaAbloyBle} = Plugins;

  const dispatch = useDispatch(),
    {pathname} = useLocation(),
    {i18n} = useTranslation();

  const routesData = useSelector(({routesReducer}) => routesReducer),
    reservation = useSelector(({reservationReducer}) => reservationReducer),
    keys = useSelector(({keysReducer}) => keysReducer),
    {allowed} = keys.mobile,
    {code, name, uuid} = reservation,
    {next, type, flow, currentFlow, currentNext, hasCheckOutStartedForNative} =
      routesData,
    previousNext = usePreviousValue(next),
    previousFlow = usePreviousValue(currentFlow),
    didMount = useDidMount();

  useUpdatedManifest();

  const [nextRoutes, setNextRoutes] = useState([]);

  const shouldCheckOutStartPageBeShown =
    isNativeApp &&
    allowed &&
    flow === FLOWS.CHECK_OUT &&
    next !== STEPS.CHECKOUT_SUMMARY &&
    next !== STEPS.IDENTIFY &&
    !hasCheckOutStartedForNative;

  useEffect(() => {
    if (
      !isNil(next) &&
      next.length > 0 &&
      routes.hasOwnProperty(next) &&
      previousNext !== next
    ) {
      setNextRoutes(routes[next]);

      if (next !== currentNext || flow !== currentFlow) {
        dispatch(setCurrentNext(next));
        dispatch(setCurrentFlow(flow));

        dispatch(
          push(
            `/${type}/${code}/${name}${
              shouldCheckOutStartPageBeShown
                ? ROUTE_PATHS.CHECKOUT_START.PATHNAME
                : routes[next][0]["pathname"]
            }`
          )
        );
      }
    }
  }, [
    next,
    previousNext,
    currentFlow,
    currentNext,
    flow,
    name,
    type,
    code,
    dispatch,
    shouldCheckOutStartPageBeShown,
  ]);

  const prepareForTerminal = useCallback(() => {
    if (window.hasOwnProperty("KioskUtils") || isTerminal()) {
      attachTerminalInputEventListeners();

      document.querySelector("body").classList.add("isTerminal");

      dispatch(setIsTerminal(true));
    }
  }, [dispatch]);

  const checkLanguage = useCallback(() => {
    let languageCode = getLanguageQueryParam();

    if (languageCode) {
      if (!i18n.languages.includes(languageCode)) {
        languageCode = LANGUAGES.EN;
      }

      i18n.changeLanguage(languageCode).then(() => {
        dispatch(setLang(languageCode));
      });
    }
  }, [i18n, dispatch]);

  useEffect(() => {
    if (previousFlow !== currentFlow) {
      Object.keys(routes).forEach((step) => {
        if (isStepInFlow(currentFlow, step)) {
          routes[step].forEach((route) => {
            const preloadComponent = (route) => {
              if (route.Component?.preload) {
                route.Component.preload();
              }
            };
            preloadComponent(route);
            if (route.children) {
              route.children.forEach((child) => {
                preloadComponent(child);
              });
            }
          });
        }
      });
    }
  }, [currentFlow, previousFlow]);

  useEffect(() => {
    scrollToTop();
    prepareForTerminal();
    dispatch(findBookingUrlSagaAction());
    removeKeyboardPadding();
    checkLanguage();
  }, [pathname, dispatch, prepareForTerminal, checkLanguage]);

  // Save reservations whenever only the code and name change, except for the first render
  // where the reservation just needs to be fetched
  useEffect(() => {
    if (isNativeApp && didMount) {
      AssaAbloyBle.saveReservationGUID({
        "reservation-id": uuid,
      });

      AssaAbloyBle.saveReservation({
        lastName: name,
        reservationCode: code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, name, uuid]);

  return (
    <div className="page-wrapper">
      <PageHeader />
      <Suspense fallback={<LoadingOverlay />}>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <Route exact path="/">
            <StartPage />
          </Route>
          <Route
            path={ROUTES.RESERVATION_ID}
            render={() => {
              return <ReservationByGuid />;
            }}
          />
          <Route
            path={ROUTES.CHECK_IN}
            render={() => {
              return <CheckInRoutes nextRoutes={nextRoutes} />;
            }}
          />
          <Route
            path={ROUTES.PRE_CHECK_IN}
            render={() => {
              return <CheckInRoutes nextRoutes={nextRoutes} />;
            }}
          />
          <Route
            path={ROUTES.CHECK_OUT}
            render={() => {
              return <CheckOutRoutes nextRoutes={nextRoutes} />;
            }}
          />
          <Route exact path="/:type/:code/:name">
            <></>
          </Route>
          <Route>
            <Redirect to={`${type}/${code}/${name}`} />
          </Route>
        </Switch>
      </Suspense>
      <InactivityOverlay />
      <LoadingOverlay />
      <ConfirmGoHomeModal />
      <Notifications />
      <NotificationsPlaceholder floating />
    </div>
  );
};

export {Router};
