const translations = {
  covid: {
    title: "COVID QR Code Scan",
    subtitle:
      "According to § 6 para. 1 CoronaVO we are obliged to request a Covid proof from you. Therefore, we ask you to provide this proof in this step.",

    explanationText:
      "We need a <1>Scan</1> or <1>Screenshot</1> of your <1>COVID QR Code</1>",
    infoText: "How to take a screenshot?",

    addScreenshot: "Add Screenshot",
    scanMyQr: "Scan my QR Code",
    noQrCode: "I don't have a QR Code",

    verificationTitle: "Verification of your COVID QR Code",
    verificationSkippedTitle: "COVID Proof Skipped",
    verificationSkippedSubtitle:
      "This proof is required in order to get access to the hotel.",
    verificationSkippedContent:
      "You can still continue, but we kindly ask you to come to the reception after completing your Check-In and show your COVID proof.",
    infoPopupText:
      "This verification is required in order to get access to the hotel. \n\n You can still continue, but we kindly ask you to come to the reception after completing your Check-In and show your COVID proof.",
    continue: "Continue",

    phoneWithButtonTitle: "Phone with home button",
    phoneWithButtonContent: "Press the side button and the home button at the same time.",
    phoneWithoutButtonTitle: "Phone without home button",
    phoneWithoutButtonContent:
      "Press the top button and the home button at the same time.",
    screenshotInGallery: "Screenshot will be available in your device gallery.",
    scanSelectedScreenshot: "Scan selected screenshot",
    securedVerificationTitle: "Secure Code Verification",
    securedVerificationSubtitle: "Only scanned, not downloaded, not stored.",

    validQrCode: "COVID QR Code Approved",
    invalidQrCode:
      "This QR Code is not valid. Please provide a valid code or press “Back” to cancel.",

    codeRejectedTitle: "COVID QR Code not approved",
    codeRejectedSubtitle: "The type of your COVID QR Code is not approved in the hotel.",
    codeRejectedContentWithoutProof:
      "Please come to the front desk after completing the check-in process and show your valid COVID proof.",
    codeRejectedContent: "Please come to the front desk and show your valid COVID proof.",

    codeMalformedTitle: "Wrong QR Code",
    codeMalformedSubtitle: "Unfortunately the scanned QR code seems to be corrupted.",
    codeMalformedContent:
      "Please try again or come to the reception after completing the check-in process and show your valid COVID proof.",

    technicalErrorTitle: "Technical Error",
    technicalErrorSubtitle:
      "Unfortunately a technical error occurred during the COVID QR Code verification.",
    technicalErrorContent:
      "Please try again or come to the reception after completing the Check-In process and show your valid COVID Proof.",

    verifyAtReception: "Verify COVID Status at the reception",
    comeToReception:
      "If you don’t have a QR Code, please come to the reception and provide your proof directly to us",
    presentedProof: "I presented COVID Proof at reception",
    presentAtReception: "I presented COVID Proof at reception",
    popupText:
      "Unfortunately the COVID QR Code is not marked in the System as being verified. Please try again or contact the Reception staff.",

    thankYouTitle: "Thank you!",
    thankYouSubtitle: "Congratulations! You finished all steps.",
    textUnderneathIcon: "We wish you a nice stay!",
    thankYouInfoText: "If you have any questions, please contact us.",
  },
};

export {translations};
