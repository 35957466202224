import {Capacitor, Plugins, StatusBarStyle} from "@capacitor/core";

const {StatusBar} = Plugins,
  isAvailable = Capacitor.isPluginAvailable("StatusBar");

if (isAvailable) {
  StatusBar.setStyle({
    style: StatusBarStyle.Dark,
  });

  StatusBar.setBackgroundColor({
    color: "#212c35",
  });

  StatusBar.setOverlaysWebView({
    overlay: false,
  });
}

export {StatusBar};
