import "./LangSelect.scss";

import React, {useEffect, useRef, useState} from "react";
import {generalDataSagaAction, setLang} from "../../../store/actions";

import {useDispatch} from "react-redux";
import {useOutsideAlert} from "../../../utils/customHooks/refHooks";
import {useTranslation} from "react-i18next";

const LangSelect = () => {
  const {i18n} = useTranslation(),
    dispatch = useDispatch();
  const [showLangSelect, setShowLangSelect] = useState(false);

  useEffect(() => {
    dispatch(setLang(i18n?.language));
  }, [dispatch, i18n?.language]);

  const wrapperRef = useRef(null);
  useOutsideAlert(wrapperRef, null, () => {
    setShowLangSelect(false);
  });

  const openLanguageSelection = () => {
    setShowLangSelect(true);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang).then(() => {
      dispatch(setLang(lang));
      setShowLangSelect(false);
    });
  };

  useEffect(() => {
    dispatch(generalDataSagaAction());
  }, [i18n.language, dispatch]);

  return (
    <div className="lang-select" onClick={openLanguageSelection}>
      {i18n?.language?.toUpperCase()}
      {showLangSelect && (
        <div ref={wrapperRef} className="lang-select__dropdown">
          {i18n.languages.sort().map((lang) => {
            return (
              <div
                className={`lang-select__option ${
                  i18n.language === lang && "lang-select__option--checked"
                }`}
                onClick={() => {
                  changeLanguage(lang);
                }}
                key={lang}>
                {lang?.toUpperCase()}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export {LangSelect};
