import "./Notification.scss";

import React, {
  useCallback,
  useEffect,
} from "react";

import {get} from "lodash";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";

import {ReactComponent as Close} from "../../../assets/images/Icon_close.svg";
import {NOTIFICATION_TYPES} from "../../../constants";
import {hideNotification} from "../../../store/actions";
import {usePreviousValue} from "../../../utils/customHooks/previousValue.hook";
import {
  plainNotificationMappingsWithoutCategories,
} from "../../../utils/notification.utils";

const Notification = ({
  type = NOTIFICATION_TYPES.SUCCESS,
  messageHeader,
  messageKey,
  messageAttributes,
  duration,
  shouldCloseManually = true,
}) => {
  const {t} = useTranslation("notification"),
    {pathname} = useLocation(),
    dispatch = useDispatch(),
    previousPathname = usePreviousValue(pathname);

  const removeSearch = useCallback(() => {
    if (window.location.search.length > 0) {
      window.history.replaceState("", "", "" + window.location.pathname);
    }
  }, []);

  const handleHideNotification = useCallback(() => {
    dispatch(hideNotification({messageKey, type}));
  }, [messageKey, type, dispatch]);

  const handleClose = useCallback(() => {
    removeSearch();
    handleHideNotification();
  }, [removeSearch, handleHideNotification]);

  useEffect(() => {
    if (previousPathname && previousPathname !== pathname) {
      handleHideNotification();
    }
  }, [pathname, previousPathname, handleHideNotification]);

  useEffect(() => {
    if (!shouldCloseManually) {
      setTimeout(() => {
        handleClose();
      }, duration);
    }
  }, [shouldCloseManually, duration, handleClose]);

  const scrollToErrorForUX = useCallback(() => {
    document.activeElement?.blur();

    const page = document.querySelector(".page") || document.querySelector("body");
    const notification = document.querySelector(".notification");
    const animationDuration = 500;

    if (!page) {
      return;
    }

    setTimeout(() => {
      page.scrollTo({
        behavior: "smooth",
        top: page.scrollTop + notification.getBoundingClientRect().top - page.offsetTop,
        left: (page.scrollWidth - page.clientWidth) / 2,
      });
    }, animationDuration);
  }, []);

  const notificationObject = messageKey
    ? t(messageKey, {...messageAttributes, returnObjects: true})
    : null;

  const getTitle = useCallback(() => {
    if (messageHeader) {
      return t(messageHeader);
    }

    return get(notificationObject, "headline", null);
  }, [messageHeader, notificationObject, t]);

  const getContent = useCallback(() => {
    return typeof notificationObject === "string"
      ? notificationObject
      : get(notificationObject, "text", null);
  }, [notificationObject]);

  const getType = useCallback(() => {
    return (
      type ||
      Object.values(plainNotificationMappingsWithoutCategories).find(
        (notification) => notification.NOTIFICATION_KEY === messageKey
      )?.TYPE ||
      NOTIFICATION_TYPES.SUCCESS
    );
  }, [type, messageKey]);

  useEffect(scrollToErrorForUX, [scrollToErrorForUX]);

  return (
    <div
      className={`notification notification--${getType()?.toLocaleLowerCase()}`}
      onClick={handleClose}>
      {shouldCloseManually && (
        <Close className="notification__close-button" onClick={handleClose} />
      )}
      {messageHeader && <div className="notification__header">{getTitle()}</div>}
      {messageKey && <div className="notification__message">{getContent()}</div>}
    </div>
  );
};

export {Notification};

Notification.propTypes = {
  type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)),
  messageHeader: PropTypes.string,
  messageKey: PropTypes.string,
  messageAttributes: PropTypes.object,
  duration: PropTypes.number,
  shouldCloseManually: PropTypes.bool,
};
