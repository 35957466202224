import {
  get,
  isNil,
  set,
} from "lodash";
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

import {
  MOBILE_CARD_KEYS_MAPPING,
  POST_ISSUE_MOBILE_KEY__SAGA,
} from "../../../constants";
import {
  apiErrorSagaAction,
  setMobileKeyData,
  setRegisteredDevice,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postIssueMobileKey(action) {
  const {resolve, reject} = action.payload;

  try {
    const store = yield select();

    const reservation = store.reservationReducer,
      keysReducer = store.keysReducer,
      {registeredDevice} = keysReducer,
      deviceName = registeredDevice.name,
      {code, name} = reservation;

    const client = yield* getClientTypeFn();
    const issueMobileKeyResp = yield call(
      [client, "postIssueMobileKey"],
      code,
      name,
      deviceName
    );

    const keys = get(issueMobileKeyResp.data, MOBILE_CARD_KEYS_MAPPING.apiKey, {});

    if (!isNil(keys)) {
      yield put(setMobileKeyData(keys));
    }

    if (resolve) {
      resolve();
    }
  } catch (error) {
    let registeredDevice = {};
    set(registeredDevice, MOBILE_CARD_KEYS_MAPPING.REGISTERED_DEVICE.NAME.apiKey, "");
    set(
      registeredDevice,
      MOBILE_CARD_KEYS_MAPPING.REGISTERED_DEVICE.INVITATION_CODE.apiKey,
      ""
    );
    yield put(setRegisteredDevice(registeredDevice));

    yield put(apiErrorSagaAction({error: error, isAllowedRetry: true}));

    if (reject) {
      reject();
    }
  }
}

export function* postIssueMobileKeySaga() {
  yield takeEvery(POST_ISSUE_MOBILE_KEY__SAGA, postIssueMobileKey);
}
