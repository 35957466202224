import {
  put,
  takeEvery,
} from "@redux-saga/core/effects";

import {
  API_ERROR__SAGA,
  NOTIFICATION_TYPES,
} from "../../../constants";
import {
  getIsPersistentByApiErrorCode,
  getNotificationKeyByApiErrorCode,
  getNotificationNameByText,
  isKnownErrorCode,
  notificationMessages,
} from "../../../utils/notification.utils";
import {setNotification} from "../../actions";

function* handleApiErrorByDisplayingNotification({payload: {error, isAllowedRetry}}) {
  try {
    if (isKnownErrorCode(error?.response?.data?.errorCode)) {
      yield put(
        setNotification({
          type: NOTIFICATION_TYPES.ERROR,
          messageKey: getNotificationKeyByApiErrorCode(error.response.data.errorCode),
          isPersistent: getIsPersistentByApiErrorCode(error.response.data.errorCode),
          isAllowedRetry,
        })
      );
    } else {
      throw error;
    }
  } catch (err) {
    console.log(err);

    yield put(
      setNotification({
        type: NOTIFICATION_TYPES.ERROR,
        messageHeader: getNotificationNameByText(notificationMessages.errorTitle),
        messageKey: getNotificationNameByText(notificationMessages.error.technical),
        isAllowedRetry,
        shouldCloseManually: true,
        isPersistent: true,
      })
    );
  }
}

export function* apiErrorSaga() {
  yield takeEvery(API_ERROR__SAGA, handleApiErrorByDisplayingNotification);
}
