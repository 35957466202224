import {
  useCallback,
  useRef,
} from "react";

import {useSelector} from "react-redux";

import {FORM_REQUIREMENTS} from "../../constants";

const useForm = () => {
  const formHelperRef = useRef(null);

  const siteCountry = useSelector(
    ({appConfigReducer}) => appConfigReducer.siteSettings.address?.country
  );

  const isRequiredByLaw = useCallback(
    (requirement) => requirement === FORM_REQUIREMENTS.REQUIRED,
    []
  );

  const isRequiredBySite = useCallback(
    (requirement) => requirement === FORM_REQUIREMENTS.REQUIRED_BY_SITE,
    []
  );

  const isRequiredForForeigners = useCallback(
    (requirement) => requirement === FORM_REQUIREMENTS.REQUIRED_FOR_FOREIGNERS,
    []
  );

  const isForeigner = useCallback(
    (guestCountry) =>
      siteCountry &&
      guestCountry &&
      siteCountry.toLowerCase() !== guestCountry.toLowerCase(),
    [siteCountry]
  );

  const isRequired = useCallback(
    (requirement, guestCountry) => {
      return (
        isRequiredByLaw(requirement) ||
        isRequiredBySite(requirement) ||
        (isForeigner(guestCountry) && isRequiredForForeigners(requirement))
      );
    },
    [isRequiredByLaw, isRequiredBySite, isRequiredForForeigners, isForeigner]
  );

  const isOptional = useCallback((requirement) => {
    return requirement === FORM_REQUIREMENTS.OPTIONAL;
  }, []);

  const isAvailable = useCallback(
    (requirement, guestCountry) => {
      if (isRequiredForForeigners(requirement) && !isForeigner(guestCountry)) {
        return false;
      }

      return requirement !== FORM_REQUIREMENTS.NOT_AVAILABLE;
    },
    [isForeigner, isRequiredForForeigners]
  );

  const isValid = useCallback(
    (requirement, value, guestCountry) => {
      if (!isRequired(requirement, guestCountry)) {
        return true;
      }

      return value != null && (typeof value !== "string" || value.trim().length > 0);
    },
    [isRequired]
  );

  if (formHelperRef.current === null) {
    formHelperRef.current = {
      isRequired,
      isOptional,
      isAvailable,
      isValid,
      isRequiredByLaw,
      isRequiredBySite,
      isRequiredForForeigners,
      isForeigner,
    };
  }

  return formHelperRef.current;
};

export {useForm};
