import axIos from "axios";
import {getI18n} from "react-i18next";

import {
  API_LANGUAGE_CODES,
  API_MODULE_TYPES,
  DEFAULT_LANGUAGE,
  LANGUAGES,
  MODULE_TYPES,
} from "../constants";
import {isTerminal} from "../env";
import {getBaseUrl, getModuleType, getStoredDeviceId} from "../utils/route.utils";

if (
  (window.hasOwnProperty("KioskUtils") || isTerminal()) &&
  typeof window.KioskUtils?.getDeviceCode === "function"
) {
  axIos.defaults.headers.common = {
    "X-Context-Secret": window.KioskUtils.getDeviceCode() || "",
  };
}

class Client {
  _type;

  constructor(type, language, siteId, deviceId) {
    this.siteId = siteId;
    this.deviceId = deviceId;
    this.type = type || getModuleType();
    this.baseUrl = getBaseUrl();
    this.language = language;

    axIos.defaults.headers.common = {
      "Accept-Language": this.language || getI18n()?.language || DEFAULT_LANGUAGE,
      "X-BS-SITE-ID": this.siteId || "",
      "X-BS-DEVICEID": this.deviceId || "",
    };
  }

  set type(moduleType) {
    const moduleTypeKey = Object.entries(MODULE_TYPES)
      .map(([key, value]) => ({key, value}))
      .find(({value}) => value === moduleType)?.key;
    const apiModuleType = API_MODULE_TYPES[moduleTypeKey] || API_MODULE_TYPES.CHECK_IN;
    this._type = apiModuleType;
  }

  get type() {
    return this._type;
  }

  // APP CONFIG

  getConfiguration() {
    const shouldCallDevAPI = false;

    return axIos.get(
      (shouldCallDevAPI ? "/dev" : this.baseUrl) + "api/v2/configuration",
      {
        headers: {
          "X-BS-DEVICEID": getStoredDeviceId() || "",
        },
      }
    );
  }

  getGuestAuthConfiguration() {
    return axIos.get(
      `${this?.baseUrl}api/v2/stay/guest-configuration/guest-authentication`
    );
  }

  getStylesheetURL() {
    return `${this.baseUrl}api/v2/stylesheet`;
  }

  // FIND BOOKING

  getBookingForCheckIn(code, name) {
    return axIos.post(`${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}`);
  }

  // REGISTRATION

  saveRegistration(code, name, data) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/registration-form`,
      data
    );
  }

  postSignature(code, name, data) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/registration-form/signature`,
      data
    );
  }

  postSignatureCheck(code, name) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/registration-form/signature/check`
    );
  }

  // COVID

  getCovidCheckStatuses(code, name) {
    return axIos.get(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/registration-form/covid-verification/check`
    );
  }

  getCovidCheckGuestStatus(code, name, guestId) {
    return axIos.get(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/registration-form/covid-verification/check/${guestId}`
    );
  }

  postCovidCheck(code, name, guestId, qrCode) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/registration-form/covid-verification/check`,
      {
        guestId,
        qrCode,
      }
    );
  }

  covidCheckFinish(code, name) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/registration-form/covid-verification/finish`
    );
  }

  // FLOW FINISH

  postFlowFinish(code, name) {
    return axIos.post(`${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/finish`);
  }

  // COST OVERVIEW

  costOverviewFinish(code, name) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/cost-overview/finish`
    );
  }

  // PAYMENT

  postPaymentInitiate(code, name, data) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/payments`,
      data
    );
  }

  postPaymentFinish(code, name, uuid) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/payments/finish`,
      {
        PaymentUuid: uuid,
      }
    );
  }

  postPaymentSplit(code, name, positions) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/split-invoice`,
      {
        positions,
      }
    );
  }

  postPaymentInProgressStart(code, name) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/payment-in-progress-start`
    );
  }

  // ISSUE KEY

  postKeyCardWriting(code, name) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/issue-key`
    );
  }

  postKeyCardReceived(code, name, body) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/key-received`,
      body
    );
  }

  postKeyCardFinish(code, name) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/${this.type}/issue-key-finish`
    );
  }

  // ADDITIONAL ISSUE KEY

  postKeyCardAdditionalWriting(code, name) {
    return axIos.post(`${this.baseUrl}api/v2/stay/${code}/${name}/issue-key`);
  }

  postKeyCardAdditionalReceived(code, name, body) {
    return axIos.post(`${this.baseUrl}api/v2/stay/${code}/${name}/key-received`, body);
  }

  // IDENTIFICATION-DOCUMENT-TYPES

  getIdDocumentTypes() {
    return axIos.get(`${this?.baseUrl}api/v2/identification-document-types`);
  }

  // TRANSLATIONS

  getApplicationTranslations() {
    return axIos.get(
      `${this?.baseUrl}api/v2/locales?lang=${
        this?.language === LANGUAGES.EN ? API_LANGUAGE_CODES.EN : API_LANGUAGE_CODES.DE
      }`
    );
  }

  // ISSUE MOBILE KEY

  postIssueMobileKey(code, name, deviceName) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/checkin/issue-mobile-key`,
      {
        deviceName,
      }
    );
  }

  postRegisterDevice(code, name) {
    return axIos.post(
      `${this.baseUrl}api/v2/stay/${code}/${name}/checkin/register-device`
    );
  }

  // LOOK-UP

  getLookupFields() {
    return axIos.get(`${this.baseUrl}api/v2/stay/reservation-lookup/${this.type}`);
  }

  postLookupFields(data) {
    return axIos.post(`${this.baseUrl}api/v2/stay/reservation-lookup/${this.type}`, data);
  }

  getReservationByGuid(guid) {
    return axIos.get(`${this.baseUrl}api/v2/stay/reservation-lookup/identify/${guid}`);
  }

  // 4SUITS KEY

  getDoors(url, jwt) {
    return axIos.get(url, {headers: {Authorization: `Bearer ${jwt}`}});
  }

  openDoor(url, jwt) {
    return axIos.post(url, {}, {headers: {Authorization: `Bearer ${jwt}`}});
  }

  // COUNTRIES

  getCountries() {
    return axIos.get(`${this?.baseUrl}api/v2/countries`);
  }

  // GENDERS

  getGenders() {
    return axIos.get(`${this?.baseUrl}api/v2/genders`);
  }

  // SINGLE SITE DATA

  getSingleSiteData() {
    return axIos.get(`${this?.baseUrl}api/v2/sites/${this.siteId}`);
  }

  // CITIZENSHIPS

  getCitizenships() {
    return axIos.get(`${this?.baseUrl}api/v2/citizenships`);
  }

  // SALUTATIONS

  getSalutations() {
    return axIos.get(`${this?.baseUrl}api/v2/salutations`);
  }

  // REGULA

  postIDDocument(data) {
    return axIos.post(
      `${this.baseUrl}api/v2/identity-document-scanner/document-data`,
      data
    );
  }

  // LOGGING

  postLoggingError(data) {
    return axIos.post(`${this.baseUrl}api/v2/logging/error`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export {Client};
