const translations = {
  form: {
    address: "Adresse",
    salutation: "Anrede",
    gender: "Geschlecht",
    firstName: "Vorname",
    lastName: "Name",
    streetNum: "Strasse / Nummer",
    postalCode: "PLZ",
    city: "Stadt",
    country: "Land",
    companyName: "Name des Unternehmens",
    department: "Abteilung",

    guestData: "Gästedaten",
    recipient: "Empfänger",
    dateOfBirth: "Geburtstag",
    citizenShip: "Staatsangehörigkeit",
    identificationDocumentTypeCode: "Identifikationsdokument",
    identificationDocumentNumber: "Reisepass / Personalausweisnummer",
    identificationDocumentAuthority: "Ausstellende Behörde",
    identificationDocumentDateOfIssue: "Ausstellungsdatum",
    idCard: "Ausweis",
    passport: "Reisepass",

    additionalGuests: "Zusätzliche Gäste",
    additionalGuest: "Weiterer Gast",
    addAdditionalGuest: "Weitere Gäste hinzufügen",

    contactSection: "Kontakt",
    contactEmail: "E-Mail-Adresse",
    phone: "Telefonnummer",
    licensePlate: "Nummernschild",
    additionalInformationSection: "Zusätzliche Information",
    reasonForTravel: "Reiseart",
    billingAddressSection: "Rechnungsadresse",
    addressType: "Adress Art",
    individualAddress: "Individuell",
    companyAddress: "Unternehmen",
    billingAddressIsPrivate: "Meine Privatadresse als Rechnungsadresse verwenden",

    billingAddressItemsInfo:
      "Welche Rechnungsbestandteile sollen auf diese Rechnungsadresse gebucht werden?",
    all: "Alle",
    accommodation: "Übernachtungen",
    breakfast: "Frühstück",
    furtherFoodAndDrinks: "Weitere Speisen & Getränke",
    other: "Andere",
    otherDescription: "Schreiben Sie kurz, wie Ihre Rechnung aufgeteilt werden soll",

    mandatoryByGDPR: "** Pflichtfeld, basierend auf §§ 6.1 b,f DSGVO",
    mandatory: "* Pflichtfelder - bitte ausfüllen.",
    mandatoryField: "* Pflichtfeld",

    externalReservationCode: "Reservierungsnummer",
    arrival: "Anreisedatum",
    departure: "Abreisedatum",
    chooseArrival: "Anreisedatum auswählen",
    chooseDeparture: "Abreisedatum auswählen",
    private: "Privat",
    business: "Geschäftlich",
    pleaseFillIn: "Bitte ausfüllen",
    invalidDate: "Bitte geben Sie ein gültiges Datum ein.",

    idScanner: {
      scanInfo: "Nur gescannt, nicht gespeichert.",
      autoFillInfo: "Automatisches Ausfüllen mit ID /Pass-Scan",
      autoFillOptional: "*Automatisches Ausfüllen ist optional",
      fillManually: "Bitte vervollständigen Sie das Formular",
    },
  },
};

export {translations};
