const translations = {
  payment: {
    title: "Bezahlung",
    subtitle: "Bitte schließen Sie den Zahlungsprozess ab, um fortzufahren",
    onProcessPayment: "Ihre Zahlung wird verarbeitet...\nBitte warten.",
    declinedByProvider:
      "Der Zahlungsvorgang wurde abgebrochen.\nBitte versuchen Sie es erneut.",
    paymentIncomplete:
      "Ihre Bezahlung konnte nicht abgeschlossen werden.\nBitte versuchen Sie es erneut.",
    errorTitle: "Bezahlung fehlgeschlagen",
    successTitle: "Bezahlung erfolgreich abgeschlossen",
    pay: "Bezahlen",
    returnToInvoice: "Zurück zur Rechnung",
  },
};

export {translations};
