import {NOTIFICATION_TYPES, POST_KEY_CARD_RECEIVED__SAGA} from "../../../constants";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {
  getNotificationNameByText,
  notificationMessages,
} from "../../../utils/notification.utils";
import {setNotification, setReservationDataSagaAction} from "../../actions";

import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postKeyCardReceived() {
  try {
    let {code, name} = yield select(({reservationReducer}) => reservationReducer);

    const client = yield* getClientTypeFn();
    const keyReceivedResponse = yield call([client, "postKeyCardReceived"], code, name, {
      received: 1,
    });

    yield put(setReservationDataSagaAction({response: keyReceivedResponse}));
  } catch (e) {
    yield put(
      setNotification({
        type: NOTIFICATION_TYPES.ERROR,
        messageKey: getNotificationNameByText(notificationMessages.error.writeKey),
        duration: 10000,
      })
    );
  }
}

export function* postKeyCardReceivedSaga() {
  yield takeEvery(POST_KEY_CARD_RECEIVED__SAGA, postKeyCardReceived);
}
