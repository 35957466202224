import "./Subtitle.scss";

import PropTypes from "prop-types";
import React from "react";
import {positions} from "../title.constants";

const Subtitle = ({value, position = positions.left, ...args}) => {
  return (
    <div
      className={`subtitle ${
        Object.values(positions).find((p) => p === position)
          ? "subtitle__position--" + position
          : "subtitle__position--left"
      }`}
      {...args}>
      {value}
    </div>
  );
};

Subtitle.propTypes = {
  value: PropTypes.string.isRequired,
  position: PropTypes.oneOf(Object.values(positions)),
};

export {Subtitle};
