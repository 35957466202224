import "./LegendText.scss";

import PropTypes from "prop-types";
import React from "react";

const LegendText = ({text, isClickable, className = "", ...args}) => {
  return (
    <div
      className={`legend-text ${isClickable ? "legend-text--button" : ""} ${className}`}
      {...args}>
      {text}
    </div>
  );
};

LegendText.propTypes = {
  text: PropTypes.string,
  isClickable: PropTypes.bool,
  className: PropTypes.string,
};

export {LegendText};
