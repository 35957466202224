import {select} from "redux-saga/effects";

import {Client} from "../../../services";
import {getModuleType} from "../../../utils/route.utils";

export function* getClientTypeFn() {
  const type = getModuleType();
  const lang = yield select(({generalReducer}) => generalReducer?.lang);
  const appConfig = yield select(({appConfigReducer}) => appConfigReducer),
    siteId = appConfig?.siteSettings?.id || "",
    deviceId = appConfig?.configuration?.device?.id || "";
  return new Client(type, lang, siteId, deviceId);
}
