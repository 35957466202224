const translations = {
  notification: {
    errorTitle: "Error",
    retry: "Erneut versuchen",
    contactHotelAction: "Hotel kontaktieren",
    error: {
      technical: {
        headline: "Fehler",
        text: "Leider ist ein technischer Fehler aufgetreten.\nBitte kontaktieren Sie das Hotel.",
      },

      inconsistentPayment:
        "Der Zahlungsversuch ist fehlgeschlagen. Bitte kommen Sie an die Rezeption, um den Vorgang abzuschließen.",

      invalidAuthBookingNumber:
        "Keine Reservierung mit dieser Buchungsnummer gefunden. Bitte überprüfen Sie Ihre Buchungsdaten und versuchen Sie es erneut.",
      invalidAuthName:
        "Keine Reservierung mit diesem Namen gefunden. Bitte überprüfen Sie Ihre Buchungsdaten und versuchen Sie es erneut.",
      reservationNotFound:
        "Keine Reservierung gefunden. Bitte überprüfen Sie Ihre Buchungsdaten und versuchen Sie es erneut.",
      moreThanOneReservationWithTheSameData:
        "Keine Reservierung gefunden. Bitte wählen Sie eine andere Suchmethode.",

      cameraAccessDenied:
        "Leider wurde der Kamerazugriff abgelehnt. Bitte wählen erlauben Sie den Zugriff auf Ihre Kamera oder wählen Sie eine andere Option zur Authentifizierung.",
      noVideoInputDevicesError:
        "Leider ist die Kamera nicht verfügbar. Bitte wählen Sie eine andere Option zur Authentifizierung.",
      bluetoothNotGranted:
        "Bluetooth-Erlaubnis nicht erteilt. Bitte passen Sie Ihre Bluetooth-Einstellungen an.",
      bluetoothNotEnabled:
        "Bluetooth nicht aktiviert. Bitte schalten Sie Ihr Bluetooth an, um Zugang zu Ihren Schlüsseln zu erhalten.",
      checkInNotAvailable: {
        headline: "Check-In fehlgeschlagen",
        text: "Ihr Check-In konnte nicht abgeschlossen werden.\nBitte kontaktieren Sie das Hotel.",
      },
      checkInNoRoomAssigned: {
        headline: "Check-In fehlgeschlagen",
        text: "Ihr Check-In konnte nicht abgeschlossen werden.\nBitte kontaktieren Sie das Hotel.",
      },
      checkOutGeneral: "Bitte gehen Sie zur Rezeption",
      checkinRoomNotReady: {
        headline: "Ihr Zimmer ist noch nicht fertig",
        text: "Wir brauchen ein wenig mehr Zeit, um Ihr Zimmer vorzubereiten.\nBitte versuchen Sie später einzuchecken.",
      },

      checkoutWrongTime: {
        headline: "Es ist nicht Ihre Ankunftszeit",
        text: "Ihr Check-In konnte nicht abgeschlossen werden,\nbitte kontaktieren Sie das Hotel.",
      },
      checkoutNotAvailable: {
        headline: "Check-Out nicht möglich",
        text: "Ihr Check-Out konnte nicht abgeschlossen werden.\nBitte kontaktieren Sie das Hotel.",
      },
      checkOutFinish:
        "Beim Auschecken ist ein Fehler aufgetreten. \n Bitte versuchen Sie es erneut oder kontaktieren Sie das Hotel.",
      checkOutOpenInvoice: {
        headline: "Bitte Zahlung abschließen",
        text: "Wenn Sie alle Zahlungen abgeschlossen haben,\nkönnen Sie mit dem nächsten Schritt fortfahren.",
        buttonTryAgain: "Zahlung",
      },
      writeKey:
        "Fehler beim Beschreiben der Schlüsselkarte. \n Bitte versuchen Sie es erneut oder kontaktieren Sie das Hotel.",
      openDoor:
        "Bitte versuchen Sie es erneut. Falls wiederholt ein Fehler auftreten sollte, erneuern Sie die Schlüssel oder kontaktieren Sie das Hotel.",
      mobileKey:
        "Tür konnte nicht geöffnet werden. Bitte halten Sie Ihr Smartphone direkt an das Türschloss.",
      invalidQrCode:
        'Dieser QR-Code ist ungültig. Bitte geben Sie einen gültigen Code ein oder drücken Sie "Zurück", um abzubrechen.',
    },
    warning: {
      noValidQrCode: "Das ist kein gültiger QrCode",
      emptyKeys: "Derzeit sind keine Schlüssel vorhanden.",
    },
    success: {
      writingDone:
        "Ihre Zimmerkarte wurde erfolgreich beschrieben, \n Sie können die Karte entfernen.",
      openDoor: "{{name}} kann geöffnet werden.",
      payment: "Bezahlung erfolgreich abgeschlossen",
    },
  },
};

export {translations};
