import "./Page.scss";

import React from "react";

import PropTypes from "prop-types";

import {Stepper} from "../Stepper";
import {TitleWrapper} from "../Title";

const Page = ({
  title,
  subtitle,
  withStepper,
  children,
  className = "",
  Icon,
  isWide = false,
}) => {
  return (
    <div className={`page scrollbar ${isWide ? "page--wide" : ""} ${className}`}>
      {Icon && (
        <div className="page__icon">
          <Icon />
        </div>
      )}
      {withStepper && <Stepper />}
      {(title || subtitle) && <TitleWrapper title={title} subtitle={subtitle} />}
      {children}
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  subtitle: PropTypes.string,
  withStepper: PropTypes.bool,
  children: PropTypes.any,
  Icon: PropTypes.object,
  isWide: PropTypes.bool,
};

Page.Content = ({children, className = "", hasSmallMargin = false}) => (
  <div
    className={`page__content page-content ${className} ${
      hasSmallMargin ? "page-content--small-margin" : ""
    }`}>
    <div className="page-container container">{children}</div>
  </div>
);

Page.Content.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  hasSmallMargin: PropTypes.bool,
};

Page.Footer = ({children, withShadow = false, isSticky = false, className = ""}) => (
  <div
    className={`page__bottom ${withShadow || isSticky ? "page__bottom--shadowed" : ""} ${
      isSticky ? "page__bottom--sticky" : ""
    } ${className}`}>
    {children}
  </div>
);

Page.Footer.propTypes = {
  children: PropTypes.any,
  withShadow: PropTypes.bool,
  isSticky: PropTypes.bool,
  className: PropTypes.string,
};

export {Page};
