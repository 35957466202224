import "./Title.scss";

import {positions, sizes} from "../title.constants";

import PropTypes from "prop-types";
import React from "react";

const Title = ({
  value,
  size = sizes.default,
  position = positions.left,
  className = "",
  ...args
}) => {
  return (
    <div
      className={`title ${
        Object.values(sizes).find((s) => s === size)
          ? "title__size--" + size
          : "title__size--default"
      } 
            ${
              Object.values(positions).find((p) => p === position)
                ? "title__position--" + position
                : "title__position--left"
            } ${className}`}
      {...args}>
      {value}
    </div>
  );
};

Title.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(sizes)),
  position: PropTypes.oneOf(Object.values(positions)),
};

export {Title};
