import "./Input.scss";

import React, {useCallback, useState} from "react";

import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {has} from "lodash";

import {ReactComponent as CheckMark} from "../../assets/images/Icon_checkmark.svg";
import {ReactComponent as WarningSign} from "../../assets/images/Icon_warning.svg";
import {useForm} from "../../utils/customHooks/form.hook";
import {getElementId, getRandomName} from "../../utils/formInput.utils";
import {
  handleTerminalInputBlur,
  handleTerminalInputFocus,
} from "../../utils/inputKeyBoardEvent.utils";
import {RequirementAsterisk} from "../Form/RequirementAsterisk.component";

const iconTypes = {
  success: "success",
  error: "error",
  none: "none",
};

const inputStyleTypes = {
  default: "",
  box: "box",
};

const Input = React.memo(
  ({
    inputType = "text",
    inputStyleType = "",
    label = "",
    onKeyPress = () => {},
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onClick = () => {},
    filter = (value) => value,
    value = "",
    requirement,
    isInvalid,
    shouldDisplayErrorMessage = false,
    autofocus = false,
    autoCapitalize = "on",
    className = "",
    index = 0,
    autocomplete = true,
    isInputEnabled = true,
    fieldKey,
    placeholder = "",
    pattern = "",
    ...args
  }) => {
    const {t} = useTranslation(["form"]);

    const {isTerminal} = useSelector(({generalReducer}) => generalReducer);

    const formHelper = useForm();

    const [isTouched, setIsTouched] = useState(false),
      [isFocused, setIsFocused] = useState(false);

    const getFieldId = useCallback(() => {
      return getElementId(label, index);
    }, [label, index]);

    const getIconType = useCallback(() => {
      if (isTouched || shouldDisplayErrorMessage || value) {
        if (isInvalid || !formHelper.isValid(requirement, value)) {
          return iconTypes.error;
        } else {
          return iconTypes.success;
        }
      } else {
        return iconTypes.none;
      }
    }, [isTouched, shouldDisplayErrorMessage, value, requirement, formHelper, isInvalid]);

    const handleFocus = (event) => {
      if (isTerminal) {
        handleTerminalInputFocus(event.target);
      }

      onFocus(event);
      setIsFocused(true);
    };

    const handleBlur = (event) => {
      if (isTerminal) {
        handleTerminalInputBlur();
      }

      onBlur(event);
      setIsTouched(true);
      setIsFocused(false);
    };

    const handleClick = (e) => {
      if (!isTouched) setIsTouched(true);
      onClick(e);
    };

    const handleChange = (event) => {
      onChange(
        {
          [fieldKey]: filter(event.target.value),
        },
        event
      );
    };

    return (
      <div
        className={`input input--${getIconType()} ${value ? "input--filled" : ""} ${
          has(inputStyleTypes, inputStyleType)
            ? `input--${inputStyleTypes[inputStyleType]}`
            : ""
        } ${className}`}
        {...args}>
        {label && (
          <label htmlFor={getFieldId()} className="input__description">
            {label}
            <RequirementAsterisk requirement={requirement} />
          </label>
        )}

        {isInputEnabled ? (
          <input
            className={`input__content ${
              isInvalid || !formHelper.isValid(requirement, value)
                ? "error--focusable"
                : ""
            }`}
            id={getFieldId()}
            name={autocomplete ? getFieldId() : getRandomName()}
            type={inputType}
            value={`${value || ""}`}
            autoFocus={autofocus}
            autoComplete={autocomplete ? "on" : "nope"}
            autoCorrect="off"
            autoCapitalize={autoCapitalize}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={shouldDisplayErrorMessage ? t("pleaseFillIn") : placeholder}
            pattern={pattern}
          />
        ) : (
          <div
            onClick={handleClick}
            tabIndex={0}
            className={`input__content ${
              isInvalid || !formHelper.isValid(requirement, value)
                ? "error--focusable"
                : ""
            }`}>
            {value || ""}
          </div>
        )}
        {!isFocused && (
          <>
            {getIconType() === iconTypes.success && <CheckMark className="input__icon" />}
            {getIconType() === iconTypes.error && <WarningSign className="input__icon" />}
          </>
        )}
      </div>
    );
  }
);

Input.propTypes = {
  inputType: PropTypes.string,
  inputStyleType: PropTypes.string,
  label: PropTypes.string,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requirement: PropTypes.string,
  isInvalid: PropTypes.bool,
  autofocus: PropTypes.bool,
  autoCapitalize: PropTypes.string,
  className: PropTypes.string,
  index: PropTypes.number,
  shouldDisplayErrorMessage: PropTypes.bool,
  autocomplete: PropTypes.bool,
  isInputEnabled: PropTypes.bool,
  fieldKey: PropTypes.string.isRequired,
  filter: PropTypes.func,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
};

export {iconTypes, Input};
