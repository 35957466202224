const translations = {
  covid: {
    title: "COVID QR Code Scan",
    subtitle:
      "Nach § 6 Abs. 1 CoronaVO sind wir verpflichtet einen Covid-Nachweis von Ihnen abzufragen. Daher bitten wir Sie, in diesem Schritt diesen Nachweis vorzulegen.",

    explanationText:
      "Wir benötigen einen <1>Scan</1> oder <1>Screenshot</1> Ihres <1>COVID QR-Codes</1>",
    infoText: "Wie erstellt man einen Screenshot?",

    addScreenshot: "Screenshot hinzufügen",
    scanMyQr: "Meinen QR-Code scannen",
    noQrCode: "Ich habe keinen QR-Code",

    verificationTitle: "Verifizierung Ihres COVID QR-Codes",
    verificationSkippedTitle: "COVID-Prüfung übersprungen",
    verificationSkippedSubtitle:
      "Dieser Schritt ist erforderlich, um Zugang zum Hotel zu erhalten.",
    verificationSkippedContent:
      "Sie können trotzdem fortfahren, aber wir bitten Sie, sich nach dem Check-in an der Rezeption zu melden und Ihren COVID-Nachweis vorzulegen.",
    infoPopupText:
      "Dieser Nachweis ist erforderlich, um Zugang zum Hotel zu erhalten. \n\n Sie können trotzdem fortfahren, aber wir bitten Sie, nach dem Check-in zur Rezeption zu kommen und Ihren COVID-Nachweis vorzulegen.",
    continue: "Fortfahren",

    phoneWithButtonTitle: "Smartphone mit Home Button",
    phoneWithButtonContent:
      "Drücken Sie gleichzeitig den Seiten- und Hauptknopf Ihres Smartphones.",
    phoneWithoutButtonTitle: "Smartphone ohne Home Button",
    phoneWithoutButtonContent:
      "Drücken Sie gleichzeitig den Seiten- und Hauptknopf Ihres Smartphones.",
    screenshotInGallery:
      "Der Screenshot wird in der Galerie Ihres Endgeräts verfügbar sein.",
    scanSelectedScreenshot: "Ausgewählten Screenshot scannen",
    securedVerificationTitle: "Sichere Code-Verifizierung",
    securedVerificationSubtitle:
      "Nur gescannt, nicht heruntergeladen, nicht gespeichert.",

    validQrCode: "COVID QR-Code genehmigt",
    invalidQrCode:
      'Dieser QR-Code ist ungültig. Bitte geben Sie einen gültigen Code ein oder drücken Sie "Zurück", um abzubrechen.',

    codeRejectedTitle: "COVID QR-Code nicht genehmigt",
    codeRejectedSubtitle: "Der Typ Ihres COVID QR-Codes ist im Hotel nicht zugelassen.",
    codeRejectedContentWithoutProof:
      "Bitte kommen Sie nach dem Einchecken an die Rezeption und zeigen Sie Ihren gültigen COVID-Nachweis vor.",
    codeRejectedContent:
      "Bitte kommen Sie an die Rezeption und zeigen Sie Ihren gültigen COVID-Nachweis vor.",

    codeMalformedTitle: "Falscher QR-Code",
    codeMalformedSubtitle: "Leider scheint der gescannte QR-Code fehlerhaft zu sein.",
    codeMalformedContent:
      "Bitte versuchen Sie es erneut oder kommen Sie nach Abschluss des Check-In-Prozesses zur Rezeption und zeigen Sie Ihren gültigen COVID-Nachweis vor.",

    technicalErrorTitle: "Technischer Fehler",
    technicalErrorSubtitle:
      "Leider ist bei der Überprüfung des COVID QR-Codes ein technischer Fehler aufgetreten.",
    technicalErrorContent:
      "Bitte versuchen Sie es erneut oder kommen Sie nach dem Check-in zur Rezeption und zeigen Sie Ihren gültigen COVID-Nachweis vor.",

    verifyAtReception: "Überprüfung des COVID-Status an der Rezeption",
    comeToReception:
      "Wenn Sie keinen QR-Code haben, kommen Sie bitte an die Rezeption und geben Sie uns Ihren Nachweis direkt",
    presentedProof: "Covid Nachweis wurde am Empfang gezeigt",
    presentAtReception: "Ich habe meinen COVID-Nachweis an der Rezeption vorgezeigt",
    popupText:
      "Leider ist der COVID QR Code im System nicht als verifiziert gekennzeichnet. Bitte versuchen Sie es erneut oder kontaktieren Sie das Empfangspersonal.",

    thankYouTitle: "Vielen Dank!",
    thankYouSubtitle: "Herzlichen Glückwunsch! Sie haben alle Schritte abgeschlossen.",
    textUnderneathIcon: "Wir wünschen Ihnen einen angenehmen Aufenthalt!",
    thankYouInfoText: "Falls Sie Fragen haben, kontaktieren Sie uns bitte.",
  },
};

export {translations};
