import "./Modal.scss";

import React, {useRef} from "react";

import PropTypes from "prop-types";

import {ReactComponent as CloseIcon} from "../../assets/images/Icon_close.svg";
import {useOutsideAlert} from "../../utils/customHooks/refHooks";
import {useWindowSize} from "../../utils/customHooks/windowResize.hook";
import {positions, Subtitle, Title} from "../Title";

const Modal = ({
  isVisible = false,
  title,
  subtitle,
  hasCloseButton = true,
  doesHideOnBlur = false,
  className = "",
  onHide = () => {},
  children,
}) => {
  const wrapperRef = useRef(null);

  useOutsideAlert(wrapperRef, null, () => {
    if (doesHideOnBlur) {
      onHide();
    }
  });

  const {isSmallDisplay} = useWindowSize();

  return (
    isVisible && (
      <div className={`modal ${className}`}>
        <div
          ref={wrapperRef}
          className={`modal__container-outer modal--compact ${
            isSmallDisplay ? "" : "scrollbar"
          }`}>
          <div className="modal__container-inner">
            {hasCloseButton && (
              <div className="modal__close-button" onClick={onHide}>
                <CloseIcon />
              </div>
            )}

            {title && <Title value={title} position={positions.center} />}
            {subtitle && (
              <div className={`${title ? "modal__subtitle" : ""}`}>
                <Subtitle value={subtitle} position={positions.center} />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    )
  );
};

Modal.propTypes = {
  isVisible: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  doesHideOnBlur: PropTypes.bool,
};

export {Modal};
