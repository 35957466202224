import "./Support.scss";

import React, {useLayoutEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CollapsedSection} from "../../CollapsedSection";
import {ReactComponent as EnvelopeIcon} from "../../../assets/images/Icon_mail.svg";
import {Modal} from "../../Modal";
import {ReactComponent as PhoneIcon} from "../../../assets/images/Icon_phone.svg";
import PropTypes from "prop-types";
import {ReactComponent as QuestionCircleIcon} from "../../../assets/images/Icon_support.svg";
import {toggleSupportModal} from "../../../store/actions";
import {useTranslation} from "react-i18next";

const Support = () => {
  const {t} = useTranslation("support"),
    dispatch = useDispatch();

  const support = useSelector(({supportReducer}) => supportReducer),
    {isHotelSupportVisible} = support;

  const toggleSupport = () => {
    dispatch(toggleSupportModal(!isHotelSupportVisible));
  };

  const hideSupport = () => {
    dispatch(toggleSupportModal(false));
  };

  return (
    <>
      <div className="support-icon" onClick={toggleSupport}>
        <QuestionCircleIcon />
      </div>

      <Modal
        isVisible={isHotelSupportVisible}
        onHide={hideSupport}
        title={t("modal.title")}
        subtitle={t("modal.subtitle")}
        className="support-modal"
        doesHideOnBlur>
        <Contacts />

        <CollapsedSection title={t("modal.imprint")}>
          <div dangerouslySetInnerHTML={{__html: t("modal.imprintText")}} />
        </CollapsedSection>
      </Modal>
    </>
  );
};

const Contacts = ({isEmailShown = true}) => {
  const {t} = useTranslation("support");
  const {isTerminal} = useSelector(({generalReducer}) => generalReducer);

  const siteSettings = useSelector(({appConfigReducer}) => appConfigReducer.siteSettings),
    {email, phone} = siteSettings.contact;

  const emailRef = useRef(null);
  const phoneRef = useRef(null);

  useLayoutEffect(() => {
    const emailElement = emailRef.current;
    const phoneElement = phoneRef.current;

    if (!emailElement && !phoneElement) {
      return;
    }

    const updateViewBox = (element) => {
      if (!element) {
        return;
      }

      const bBox = element.getBBox();

      element.setAttribute("viewBox", `${bBox.x} ${bBox.y} ${bBox.width} ${bBox.height}`);

      if (element.getBoundingClientRect().width > bBox.width) {
        element.setAttribute("width", `${bBox.width}`);
      }
    };

    setTimeout(() => {
      updateViewBox(phoneElement);
      updateViewBox(emailElement);
    }, 500);
  }, []);

  const openPhoneWindow = () => {
    window.open(`tel:${phone}`);
  };

  const openEmailWindow = () => {
    window.open(`mailto:${email}`);
  };

  const hasContacts = email?.length > 0 || phone?.length > 0;

  const hasActions = !isTerminal;

  return (
    <>
      {phone && (
        <div className="support-contact" onClick={() => hasActions && openPhoneWindow()}>
          <div className="support-contact__icon">
            <PhoneIcon />
          </div>
          <div
            className={`support-contact__text ${
              hasActions ? "support-contact__text--action" : ""
            }`}>
            <svg viewBox="0 0 0 0" ref={phoneRef}>
              <text>{phone}</text>
            </svg>
          </div>
        </div>
      )}

      {isEmailShown && email && (
        <div className="support-contact" onClick={() => hasActions && openEmailWindow()}>
          <div className="support-contact__icon">
            <EnvelopeIcon />
          </div>
          <div
            className={`support-contact__text ${
              hasActions ? "support-contact__text--action" : ""
            }`}>
            <svg viewBox="0 0 0 0" ref={emailRef}>
              <text>{email}</text>
            </svg>
          </div>
        </div>
      )}
      {!hasContacts && (
        <div className="support-contact__empty-text">{t("modal.emptyContacts")}</div>
      )}
    </>
  );
};

Contacts.propTypes = {
  isEmailShown: PropTypes.bool,
};

export {Support, Contacts};
