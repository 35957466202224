import {call, select, takeEvery} from "redux-saga/effects";

import {POST_KEY_CARD_ADDITIONAL_RECEIVED__SAGA} from "../../../constants";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postKeyCardAdditionalReceived() {
  let store = yield select(),
    {code, name} = store.bookingReducer;

  const client = yield* getClientTypeFn();
  yield call([client, "postKeyCardAdditionalReceived"], code, name, {
    received: 1,
  });
}

export function* postKeyCardAdditionalReceivedSaga() {
  yield takeEvery(POST_KEY_CARD_ADDITIONAL_RECEIVED__SAGA, postKeyCardAdditionalReceived);
}
