import {ROUTE_PATHS, STEPS} from "../../constants";

import {LoadingOverlay} from "../../components/LoadingOverlay";
import {lazyWithPreload} from "../../utils/lazy.utils";

export const loading = [
  {
    pathname: ROUTE_PATHS.HOME.PATHNAME,
    Component: LoadingOverlay,
  },
];

export const checkOutStartRoutes = [
  {
    pathname: ROUTE_PATHS.CHECKOUT_START.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../StartPage/CheckOut" /* webpackChunkName: "CheckOutStartPage" */).then(
        (module) => ({
          default: module.CheckOutStartPage,
        })
      )
    ),
  },
  {
    pathname: ROUTE_PATHS.KEY_OVERVIEW.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../KeyOverview" /* webpackChunkName: "KeyOverview" */).then((module) => ({
        default: module.KeyOverview,
      }))
    ),
  },
];

export const guestRegistrationRoutes = [
  {
    pathname: ROUTE_PATHS.RESERVATION_OVERVIEW.PATHNAME,
    Component: lazyWithPreload(() =>
      import(
        "../Reservation/Overview" /* webpackChunkName: "ReservationOverview" */
      ).then((module) => ({
        default: module.ReservationOverview,
      }))
    ),
  },
  {
    pathname: ROUTE_PATHS.GUEST_REGISTRATION.PATHNAME,
    children: [
      {
        pathname: ROUTE_PATHS.GUEST_REGISTRATION.CHILDREN.ADDRESS.PATHNAME,
        Component: lazyWithPreload(() =>
          import(
            "../Registration/PrimaryRegistrationPage" /* webpackChunkName: "PrimaryRegistrationPage" */
          ).then((module) => ({
            default: module.PrimaryRegistrationPage,
          }))
        ),
      },
      {
        pathname: ROUTE_PATHS.GUEST_REGISTRATION.CHILDREN.MORE_DATA.PATHNAME,
        Component: lazyWithPreload(() =>
          import(
            "../Registration/SecondaryRegistrationPage" /* webpackChunkName: "SecondaryRegistrationPage" */
          ).then((module) => ({
            default: module.SecondaryRegistrationPage,
          }))
        ),
      },
    ],
  },
  {
    pathname: ROUTE_PATHS.PERSONAL_DATA.PATHNAME,
    Component: lazyWithPreload(() =>
      import(
        "../RegistrationOverview" /* webpackChunkName: "RegistrationOverview" */
      ).then((module) => ({
        default: module.RegistrationOverview,
      }))
    ),
  },
];

export const signatureRoutes = [
  {
    pathname: ROUTE_PATHS.SIGNATURE.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../Signature" /* webpackChunkName: "Signature" */).then((module) => ({
        default: module.SignaturePage,
      }))
    ),
  },
  ...guestRegistrationRoutes,
];

export const covidRoutes = [
  {
    pathname: ROUTE_PATHS.COVID_QR_CODE_SCAN.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../Covid" /* webpackChunkName: "CovidCheck" */).then((module) => ({
        default: module.Covid,
      }))
    ),
  },
  ...guestRegistrationRoutes,
];

export const costOverviewRoutes = [
  {
    pathname: ROUTE_PATHS.COST_OVERVIEW.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../Invoice" /* webpackChunkName: "Invoice" */).then((module) => ({
        default: module.CostOverview,
      }))
    ),
  },
  ...checkOutStartRoutes,
];

export const paymentRoutes = [
  {
    pathname: ROUTE_PATHS.COST_OVERVIEW.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../Invoice" /* webpackChunkName: "Invoice" */).then((module) => ({
        default: module.CostOverview,
      }))
    ),
  },
  {
    pathname: ROUTE_PATHS.PAYMENT.PATHNAME,
    children: [
      {
        pathname: ROUTE_PATHS.PAYMENT.CHILDREN.PAYMENT_SELECT.PATHNAME,
        Component: lazyWithPreload(() =>
          import("../Payment" /* webpackChunkName: "Payment" */).then((module) => ({
            default: module.PaymentSelect,
          }))
        ),
      },
      {
        pathname: ROUTE_PATHS.PAYMENT.CHILDREN.PAYMENT_FINISH.PATHNAME,
        Component: lazyWithPreload(() =>
          import("../Payment" /* webpackChunkName: "PaymentFinish" */).then((module) => ({
            default: module.FinishPayment,
          }))
        ),
      },
    ],
  },
  ...checkOutStartRoutes,
];

export const keyRoutes = [
  {
    pathname: ROUTE_PATHS.KEY.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../Key" /* webpackChunkName: "Key" */).then((module) => ({
        default: module.Key,
      }))
    ),
  },
];

export const checkInFinishRoutes = [
  {
    pathname: ROUTE_PATHS.CHECKIN_FINISH.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../Finish/CheckIn" /* webpackChunkName: "CheckInFinish" */).then(
        (module) => ({
          default: module.CheckInFinish,
        })
      )
    ),
  },
];

export const checkOutFinishRoutes = [
  {
    pathname: ROUTE_PATHS.CHECKOUT_FINISH.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../Finish/CheckOut" /* webpackChunkName: "CheckOutFinish" */).then(
        (module) => ({
          default: module.CheckOutConfirmFinish,
        })
      )
    ),
  },
  ...checkOutStartRoutes,
];

export const preCheckInSummaryRoutes = [
  {
    pathname: ROUTE_PATHS.PRE_CHECKIN_SUMMARY.PATHNAME,
    Component: lazyWithPreload(() =>
      import(
        "../Reservation/Summary/PreCheckIn" /* webpackChunkName: "PreCheckInSummary" */
      ).then((module) => ({
        default: module.PreCheckInSummary,
      }))
    ),
  },
];

export const checkInSummaryRoutes = [
  {
    pathname: ROUTE_PATHS.CHECKIN_SUMMARY.PATHNAME,
    Component: lazyWithPreload(() =>
      import(
        "../Reservation/Summary/CheckIn" /* webpackChunkName: "CheckInSummary" */
      ).then((module) => ({
        default: module.CheckInSummary,
      }))
    ),
  },
  {
    pathname: ROUTE_PATHS.KEY_OVERVIEW.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../KeyOverview" /* webpackChunkName: "KeyOverview" */).then((module) => ({
        default: module.KeyOverview,
      }))
    ),
  },
  ...keyRoutes,
];

export const checkOutSummaryRoutes = [
  {
    pathname: ROUTE_PATHS.CHECKOUT_SUMMARY.PATHNAME,
    Component: lazyWithPreload(() =>
      import(
        "../Reservation/Summary/CheckOut" /* webpackChunkName: "CheckOutSummary" */
      ).then((module) => ({
        default: module.CheckOutSummary,
      }))
    ),
  },
];

export const registrationFormCompletedRoutes = [
  {
    pathname: ROUTE_PATHS.REGISTRATION_FORM_COMPLETED.PATHNAME,
    Component: lazyWithPreload(() =>
      import("../RegistrationFinish" /* webpackChunkName: "RegistrationFinish" */).then(
        (module) => ({
          default: module.RegistrationFinish,
        })
      )
    ),
  },
];

export const routes = {
  [STEPS.IDENTIFY]: loading,
  [STEPS.GUEST_REGISTRATION]: guestRegistrationRoutes,
  [STEPS.SIGNATURE]: signatureRoutes,
  [STEPS.COVID_QR_CODE_SCAN]: covidRoutes,
  [STEPS.PAYMENT]: paymentRoutes,
  [STEPS.COST_OVERVIEW]: costOverviewRoutes,
  [STEPS.KEY_ENCODING]: keyRoutes,
  [STEPS.CHECKIN_FINISH]: checkInFinishRoutes,
  [STEPS.CHECKOUT_FINISH]: checkOutFinishRoutes,
  [STEPS.PRE_CHECKIN_SUMMARY]: preCheckInSummaryRoutes,
  [STEPS.CHECKIN_SUMMARY]: checkInSummaryRoutes,
  [STEPS.CHECKOUT_SUMMARY]: checkOutSummaryRoutes,
  [STEPS.REGISTRATION_FORM_COMPLETED]: registrationFormCompletedRoutes,
};
