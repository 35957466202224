import {
  get,
  isNil,
} from "lodash";
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

import {
  MOBILE_CARD_KEYS_MAPPING,
  POST_REGISTER_DEVICE__SAGA,
} from "../../../constants";
import {
  apiErrorSagaAction,
  setRegisteredDevice,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postRegisterDevice(action) {
  const {resolve, reject} = action.payload;

  try {
    const store = yield select();

    const reservation = store.reservationReducer,
      {code, name} = reservation,
      client = yield* getClientTypeFn();

    const registeredDeviceResp = yield call([client, "postRegisterDevice"], code, name);
    const registeredDevice = get(
      registeredDeviceResp.data,
      MOBILE_CARD_KEYS_MAPPING.REGISTERED_DEVICE.apiKey
    );

    if (!isNil(registeredDevice)) {
      yield put(setRegisteredDevice(registeredDevice));
    }

    if (resolve) {
      resolve({
        completed: true,
        key: get(
          registeredDevice,
          MOBILE_CARD_KEYS_MAPPING.REGISTERED_DEVICE.INVITATION_CODE.apiKey,
          ""
        ),
      });
    }
  } catch (e) {
    yield put(apiErrorSagaAction({error: e, isAllowedRetry: true}));

    if (reject) {
      reject(false);
    }
  }
}

export function* postRegisterDeviceSaga() {
  yield takeEvery(POST_REGISTER_DEVICE__SAGA, postRegisterDevice);
}
