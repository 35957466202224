export const ENVIRONMENTS = {
  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production",
};

export const STATUS = {
  ERROR: "ERROR",
};

export const FORM_REQUIREMENTS = {
  REQUIRED: "REQUIRED",
  REQUIRED_BY_SITE: "REQUIRED_BY_SITE",
  REQUIRED_FOR_FOREIGNERS: "REQUIRED_FOR_FOREIGNERS",
  OPTIONAL: "OPTIONAL",
  NOT_AVAILABLE: "NOT_AVAILABLE",
};

export const STEPS = {
  IDENTIFY: "IDENTIFY",
  GUEST_REGISTRATION: "GUEST_REGISTRATION",
  SIGNATURE: "SIGNATURE",
  COVID_QR_CODE_SCAN: "COVID_VERIFICATION",
  COST_OVERVIEW: "COST_OVERVIEW",
  PAYMENT: "PAYMENT",
  CHECKIN_FINISH: "CHECKIN_FINISH",
  CHECKOUT_FINISH: "CHECKOUT_FINISH",
  PRE_CHECKIN_SUMMARY: "PRE_CHECKIN_SUMMARY",
  CHECKIN_SUMMARY: "CHECKIN_SUMMARY",
  CHECKOUT_SUMMARY: "CHECKOUT_SUMMARY",
  KEY_OVERVIEW: "KEY_OVERVIEW",
  KEY_ENCODING: "KEY_ENCODING",
  REGISTRATION_FORM_COMPLETED: "REGISTRATION_FORM_COMPLETED",
};

export const FLOWS = {
  PRE_CHECK_IN: "PRE_CHECK_IN",
  CHECK_IN: "CHECK_IN",
  CHECK_OUT: "CHECK_OUT",
};

export const MODULE_TYPES = {
  CHECK_IN: "check-in",
  CHECK_OUT: "check-out",
  PRE_CHECK_IN: "pre-check-in",
};

export const API_MODULE_TYPES = {
  CHECK_IN: "checkin",
  CHECK_OUT: "checkout",
  PRE_CHECK_IN: "precheckin",
};

export const ROUTES = {
  RESERVATION_ID: "/reservation-id/:guid",
  CHECK_IN: `/${MODULE_TYPES.CHECK_IN}`,
  CHECK_OUT: `/${MODULE_TYPES.CHECK_OUT}`,
  PRE_CHECK_IN: `/${MODULE_TYPES.PRE_CHECK_IN}`,
};

export const KEY_PROVIDERS = {
  ASSA_ABLOY: "AssaAbloy",
  FOUR_SUITES: "4Suites",
};

export const LANGUAGES = {
  DE: "de",
  EN: "en",
};

export const API_LANGUAGE_CODES = {
  DE: "de-DE",
  EN: "en-EN",
};

export const API_WIDGET_TYPES = {
  LINK: "LINK",
  TEXT: "TEXT",
  PICTURE: "PICTURE",
};

export const DEFAULT_LANGUAGE = LANGUAGES.DE;

export const NOTIFICATION_TYPES = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
};

export const MIN_MAIN_GUEST_AGE = 18;
export const MAX_GUEST_AGE = 117;

export const DATE_FORMAT = "YYYY-MM-DD";

export const IDENTIFICATION_DOCUMENT_TYPE_CODES = {
  IDENTITY_CARD: "IC",
  PASSPORT: "PP",
};

export const COVID_PROOF_SCREENSHOT_MAX_SIZE_IN_MB = 1;
