import "./Notifications.scss";

import {ErrorScreen} from "./ErrorScreen";
import {Notification} from "./Notification";
import ReactDOM from "react-dom";
import {useSelector} from "react-redux";

const Notifications = () => {
  const notifications = useSelector(({notificationsReducer}) => notificationsReducer);

  const notificationsContainer = document.getElementsByClassName(
    "notifications-container"
  )?.[0];

  const elementToBeRendered = notifications && notifications.length > 0 && (
    <div className="notifications">
      {notifications.map((notification) =>
        notification.isPersistent ? (
          <ErrorScreen
            key={`${notification.type}${notification.messageKey}-persistent`}
            {...notification}
          />
        ) : (
          <Notification
            key={`${notification.type}${notification.messageKey}`}
            {...notification}
          />
        )
      )}
    </div>
  );

  return notificationsContainer
    ? ReactDOM.createPortal(elementToBeRendered, notificationsContainer)
    : elementToBeRendered;
};

export {Notifications};
