const translations = {
  reservation: {
    summarySuccessTitle: "Alle Daten abgeschlossen",
    preCheckInSummaryMessage:
      "Vielen Dank, dass Sie Ihren Pre Check-In abgeschlossen haben.\nBitte fahren Sie am Anreisetag mit dem Check-In fort.",
    thanksForStay: "Wir hoffen, Sie bald wieder bei uns begrüßen zu dürfen!",
    successCheckOut: "Erfolgreich ausgecheckt!",
    checkoutAdditionalInformation: "Zusätzliche Information:",
    thankYouForRegistration: "Vielen Dank, dass Sie Ihren Meldeschein ausgefüllt haben!",
    pleaseContactUs: "Bitte <1>kontaktieren Sie uns</1>, falls Sie noch Fragen haben",
    confirmReservation: "Daten bestätigen",

    reservationData: "Reservierungsdaten",
    guestData: "Gastdaten",
    guest: "{{count}} Gast",
    guest_plural: "{{count}} Gäste",
    mainGuest: "Hauptgast",
    checkReservation: "Bitte überprüfen Sie Ihre Reservierungsdaten",
    notYours: "Nicht Ihre Reservierung?",
    night: "{{count}} Nacht",
    night_plural: "{{count}} Nächte",
    arrival: "Anreise",
    departure: "Abreise",
    welcome: "Willkommen {{firstName}} {{lastName}}",
    keys: "Schlüssel",
    keyBack:
      "Bitte werfen Sie Ihre Schlüsselkarten in einen der gekennzeichneten Behälter. Vielen Dank.",
    hotelName: "Hotelname",
    arrivalDate: "Anreisedatum",
    departureDate: "Abreisedatum",
    stay: "Aufenthalt",
    roomType: "Zimmertyp",
    additionalServices: "Weitere Dienstleistungen",
    nrGuests: "Anzahl der Gäste",
    accept: "Akzeptieren",
  },
};

export {translations};
