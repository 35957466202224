import {push} from "connected-react-router";
import moment, {now} from "moment";
import {put, select, takeLeading} from "redux-saga/effects";

import {
  FIND_BOOKING_URL__SAGA,
  MODULE_TYPES,
  NOTIFICATION_TYPES,
  STEPS,
} from "../../../constants";
import {
  getNotificationNameByText,
  notificationMessages,
} from "../../../utils/notification.utils";
import {getBookingDetailsFromUrl} from "../../../utils/route.utils";
import {
  hideLoadingOverlay,
  purge,
  setCurrentNext,
  setNext,
  setNotification,
} from "../../actions";
import {findBookingFn} from "./findBooking.generatorFunction";

function* findBookingUrl() {
  yield* findBooking();
  const next = yield select(({routesReducer}) => routesReducer.next);

  if (next === STEPS.IDENTIFY) {
    yield* findBooking();
  }
}

function* findBooking() {
  let {match, bookingId, bookingName, type} = getBookingDetailsFromUrl();

  try {
    let store = yield select(),
      {code: cachedBookingId, name: cachedBookingName} = store.reservationReducer,
      routeType = store.routesReducer.type,
      {bookingDataExpired} = store.generalReducer;

    if (bookingName) {
      bookingName = decodeURIComponent(bookingName);
      //bookingName = bookingName.replace(/\+/g, " ");
    }

    const shouldLoadNew =
      bookingId &&
      bookingName &&
      (!cachedBookingId ||
        !cachedBookingName ||
        cachedBookingId.toLowerCase() !== bookingId.toLowerCase() ||
        cachedBookingName.toLowerCase() !== bookingName.toLowerCase());

    if (
      Object.values(MODULE_TYPES).includes(type) &&
      bookingId &&
      bookingName &&
      (shouldLoadNew ||
        match.isExact ||
        moment(now()).isAfter(bookingDataExpired) ||
        type !== routeType)
    ) {
      if (shouldLoadNew) {
        yield put(purge({whiteList: ["router", "appConfigReducer"]}));
      }

      if (match.isExact) {
        yield put(setCurrentNext(""));
        yield put(setNext(""));
      }

      yield* findBookingFn({bookingId, bookingName, type});
    }
  } catch (e) {
    yield put(push(`/${type}/search`));
    yield put(hideLoadingOverlay());
    yield put(
      setNotification({
        type: NOTIFICATION_TYPES.ERROR,
        messageKey: getNotificationNameByText(
          notificationMessages.error.reservationNotFound
        ),
      })
    );
  }
}

export function* findBookingUrlSaga() {
  yield takeLeading(FIND_BOOKING_URL__SAGA, findBookingUrl);
}
