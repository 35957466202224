const translations = {
  folio: {
    payNow: "Pay now",
    title: "Cost overview",
    subtitle: "Please check your cost overview to continue.",
    invoiceAddress: "Invoice address:",
    positions: "Invoice Positions",
    description: "Description",
    quantity: "Qty",
    price: "Price",
    total: "Total",
    remainingPayments: "Remaining Payments:",
    question: "Question about the invoice?",
    part: "Part {{count}}",
    summary: "Summary",
    onlyCreditCardAllowed:
      "<0>Online payment only possible with a credit card.</0> If you don't have a credit card, please <2>contact the hotel</2>.",

    billingAddressWarningHeader: "Your billing address has changed",
    billingAddressWarningText:
      "Change of the billing address might not be applied immediately to your invoice, but it has been recorded and should be applied until your check-out.\n\nIf you have any further questions or requests regarding the billing please contact <1>the hotel</1>.",
    billingAddressWarningButton: "Continue",

    // split invoice
    invoice: "Invoice",
    noInvoices: "No invoices were found for your booking.",
    editInvoice: "Edit invoice",
    business: "Business",
    private: "Private",
    move: "Move",
  },
};

export {translations};
