import {COVID_MAPPING, GET_COVID_CHECK_GUEST_STATUS__SAGA} from "../../../constants";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {hideLoadingOverlay, showLoadingOverlay} from "../../actions";

import {get} from "lodash";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* getCovidCheckGuestStatus(action) {
  const {resolve, reject, guestId} = action.payload || {};
  yield put(showLoadingOverlay());

  try {
    let store = yield select(),
      {code, name} = store.reservationReducer;

    const client = yield* getClientTypeFn();
    const covidCheckResponse = yield call(
      [client, "getCovidCheckGuestStatus"],
      code,
      name,
      guestId
    );

    yield put(hideLoadingOverlay());

    if (resolve) {
      resolve({
        [guestId]: get(covidCheckResponse.data, COVID_MAPPING.STATUS.apiKey),
      });
    }
  } catch (error) {
    yield put(hideLoadingOverlay());

    if (reject) {
      reject();
    }
  }
}

export function* getCovidCheckGuestStatusSaga() {
  yield takeLatest(GET_COVID_CHECK_GUEST_STATUS__SAGA, getCovidCheckGuestStatus);
}
