import {call, put, select, takeEvery} from "redux-saga/effects";
import {
  hideLoadingOverlay,
  setAdditionalKeyCardsAllowed,
  setAllowExtraKeys,
  setKeyCardsData,
  setMobileKeyData,
} from "../../actions";

import {MOBILE_CARD_KEYS_MAPPING, POST_KEY_CARD_WRITING__SAGA} from "../../../constants";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";
import {get, isNil} from "lodash";

function* postKeyCardWriting(action) {
  const {resolve, reject} = action.payload;

  try {
    let store = yield select();

    let {code, name} = store.reservationReducer;

    const client = yield* getClientTypeFn();
    const keyWritingResponse = yield call([client, "postKeyCardWriting"], code, name);

    if (resolve) {
      resolve(keyWritingResponse);
      yield put(hideLoadingOverlay());
    }

    const data = keyWritingResponse.data;

    const keys = get(data, MOBILE_CARD_KEYS_MAPPING.apiKey, null);

    if (!isNil(keys)) {
      yield put(setKeyCardsData(get(keys, MOBILE_CARD_KEYS_MAPPING.CARD.apiKey, {})));
      yield put(
        setAdditionalKeyCardsAllowed(get(keys, MOBILE_CARD_KEYS_MAPPING.CARD.apiKey, {}))
      );
      yield put(
        setAdditionalKeyCardsAllowed(
          get(keys, MOBILE_CARD_KEYS_MAPPING.CARD.ADDITIONAL_ALLOWED.apiKey)
        )
      );
      yield put(
        setAllowExtraKeys(
          get(keys, MOBILE_CARD_KEYS_MAPPING.CARD.ALLOW_EXTRA_KEYS.apiKey, false)
        )
      );
      yield put(setMobileKeyData(keys));
    }
  } catch (e) {
    yield put(hideLoadingOverlay());

    if (reject) {
      reject(e);
    }
  }
}

export function* postKeyCardWritingSaga() {
  yield takeEvery(POST_KEY_CARD_WRITING__SAGA, postKeyCardWriting);
}
