const translations = {
  reservation: {
    summarySuccessTitle: "All data completed",
    preCheckInSummaryMessage:
      "Thank you for completing your Pre Check-In. \nPlease continue with the Check-In on your arrival day.",
    thanksForStay: "We hope to see you \nagain soon!",
    successCheckOut: "Successfully checked out!",
    checkoutAdditionalInformation: "Additional information:",
    thankYouForRegistration: "Thank you very much for completing your registration form!",
    pleaseContactUs: "Please <1>contact us</1> in case you have any questions.",
    confirmReservation: "Confirm Data",

    reservationData: "Reservation Details",
    guestData: "Guest Information",
    guest: "{{count}} Guest",
    guest_plural: "{{count}} Guests",
    mainGuest: "Main guest",
    checkReservation: "Please check your Reservation Details",
    notYours: "It's not your reservation?",
    night: "{{count}} Night",
    night_plural: "{{count}} Nights",
    arrival: "Arrival",
    departure: "Departure",
    welcome: "Welcome {{firstName}} {{lastName}}",
    keys: "Keys",
    keyBack: "Please throw your keys in one of the marked boxes, thank you.",
    hotelName: "Hotel Name",
    arrivalDate: "Date of arrival",
    departureDate: "Date of departure",
    stay: "Stay",
    roomType: "Room type",
    additionalServices: "Additional services",
    nrGuests: "Number of Guests",
    accept: "Accept",
  },
};

export {translations};
