const translations = {
  personalData: {
    title: "Please check your Information",
    subtitle: "Next step will be Data Confirmation",
    address: "Address:",
    billingAddress: "Invoice address:",
    billingEqualsAddress: "Guest address",
    additionalGuests: "Additional guests",
    notAdditionalGuests: "No additional guests specified",
    guest: "Guest Data",
    contact: "Contact:",
    additionalInformation: "Additional Information:",
    travelPurpose: "Travel Type:",
    processingSave: "One moment please... \n\nYour data is processed...",
    personalInfo: "Personal Info:",
  },
};

export {translations};
