import "./StartPage.scss";

import React, {useCallback, useEffect} from "react";

import {API_WIDGET_TYPES} from "../../constants";
import {ReactComponent as CheckInIcon} from "../../assets/images/Icon_checkin_start.svg";
import {ReactComponent as CheckOutIcon} from "../../assets/images/Icon_checkout_start.svg";
import {Page} from "../../components/Page";
import {Tile} from "../../components/Tile";
import defaultCheckInImg from "../../assets/images/checkin_default_tile.png";
import defaultCheckOutImg from "../../assets/images/checkout_default_tile.png";
import defaultHotelImg from "../../assets/images/reception_tile.png";
import {useSelector} from "react-redux";
import {useState} from "react";

const apiContentKeys = {
  hotel: "general.welcome_tile",
  checkIn: "general.check_in_tile",
  checkOut: "general.check_out_tile",
};

const StartPage = () => {
  const widgets = useSelector(({appConfigReducer}) => appConfigReducer.startPageLayout),
    translations = useSelector(
      ({translationsReducer}) => translationsReducer.translations
    );
  const [tiles, setTiles] = useState([]);

  const getGridArea = useCallback((tile) => {
    const top = tile.top + 1;
    const left = tile.left + 1;
    const bottom = tile.height + top;
    const right = tile.width + left;

    return `${top} / ${left} / ${bottom} / ${right}`;
  }, []);

  useEffect(() => {
    let tiles = widgets
      .filter(
        (widget) =>
          widget.template === API_WIDGET_TYPES.PICTURE ||
          widget.template === API_WIDGET_TYPES.TEXT
      )
      .map((widget, index) => {
        const tile = {...widget};

        // todo: remove the default image assignment when back-end will do that on their side.
        switch (tile.contentKey) {
          case apiContentKeys.checkIn: {
            if (!tile.image) {
              tile.image = defaultCheckInImg;
            }
            tile.Icon = CheckInIcon;
            tile.hasArrow = true;
            tile.hasRadialGradient = true;
            break;
          }
          case apiContentKeys.checkOut: {
            if (!tile.image) {
              tile.image = defaultCheckOutImg;
            }
            tile.Icon = CheckOutIcon;
            tile.hasArrow = true;
            tile.hasRadialGradient = true;
            break;
          }
          case apiContentKeys.hotel: {
            if (!tile.image) {
              tile.image = defaultHotelImg;
            }
            break;
          }
          default: {
            break;
          }
        }

        // todo: remove this workaround when back-end is ready.
        if (widgets.length === 2 && index === 1) {
          tile.width = 2;
          tile.left = 0;
        }

        tile.style = {gridArea: getGridArea(tile)};
        tile.title = translations[tile.contentKey] || tile.title;

        return tile;
      });

    setTiles(tiles);
  }, [widgets, getGridArea, translations]);

  return (
    <Page withStepper={false} className="start-page">
      <Page.Content>
        {tiles.map((tile) => (
          <Tile
            key={tile.contentKey}
            style={tile.style}
            title={tile.title}
            link={tile.link}
            hasArrow={tile?.hasArrow}
            hasRadialGradient={tile?.hasRadialGradient}
            Icon={tile?.Icon}
            imageUrl={tile.image}
            target={tile.contentKey !== apiContentKeys.hotel ? "_self" : "_blank"}
          />
        ))}
      </Page.Content>
    </Page>
  );
};

export {StartPage};
