import {
  FOLIOS_MAPPING,
  SET_INVOICE,
  SET_INVOICE_SPLIT_AVAILABLE,
} from "../../../constants";
import {get, set} from "lodash";

import {createReducer} from "@reduxjs/toolkit";
import {getUpdateObjectFn} from "../../../utils/state.utils";

let initialState = {
  invoices: [],
  invoiceSplitAvailable: false,
};

const folioReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_INVOICE, (state, action) => {
      let invoices = (action.payload || []).map((invoice) => {
        let mappedInvoice = {};

        const updateInvoice = getUpdateObjectFn(invoice, mappedInvoice);

        updateInvoice(FOLIOS_MAPPING.INVOICES.ID);
        updateInvoice(FOLIOS_MAPPING.INVOICES.UUID);
        updateInvoice(FOLIOS_MAPPING.INVOICES.IS_BUSINESS, false);
        updateInvoice(FOLIOS_MAPPING.INVOICES.COMPANY_NAME, "");
        updateInvoice(FOLIOS_MAPPING.INVOICES.DEPARTMENT, "");
        updateInvoice(FOLIOS_MAPPING.INVOICES.RECIPIENT, "");
        updateInvoice(FOLIOS_MAPPING.INVOICES.STREET);
        updateInvoice(FOLIOS_MAPPING.INVOICES.ADDRESS_LINES, []);
        updateInvoice(FOLIOS_MAPPING.INVOICES.POSTAL_CODE);
        updateInvoice(FOLIOS_MAPPING.INVOICES.CITY);
        updateInvoice(FOLIOS_MAPPING.INVOICES.COUNTRY_CODE);
        updateInvoice(FOLIOS_MAPPING.INVOICES.DUE_AMOUNT, 0);
        updateInvoice(FOLIOS_MAPPING.INVOICES.PAYABLE_AMOUNT, 0);
        updateInvoice(FOLIOS_MAPPING.INVOICES.RECEIVED_AMOUNT, 0);
        updateInvoice(FOLIOS_MAPPING.INVOICES.GROSS_AMOUNT, 0);
        updateInvoice(FOLIOS_MAPPING.INVOICES.NET_AMOUNT, 0);
        updateInvoice(FOLIOS_MAPPING.INVOICES.CURRENCY_CODE);

        let positions = get(invoice, FOLIOS_MAPPING.INVOICES.POSITIONS.apiKey, []).map(
          (position) => {
            let mappedPosition = {};

            const updatePosition = getUpdateObjectFn(position, mappedPosition);

            updatePosition(FOLIOS_MAPPING.INVOICES.POSITIONS.ID);
            updatePosition(FOLIOS_MAPPING.INVOICES.POSITIONS.UUID);
            updatePosition(FOLIOS_MAPPING.INVOICES.POSITIONS.NAME);
            updatePosition(FOLIOS_MAPPING.INVOICES.POSITIONS.DATE);
            updatePosition(FOLIOS_MAPPING.INVOICES.POSITIONS.QUANTITY, 0);
            updatePosition(FOLIOS_MAPPING.INVOICES.POSITIONS.ITEM_PRICE, 0);

            return mappedPosition;
          }
        );

        mappedInvoice = set(
          mappedInvoice,
          FOLIOS_MAPPING.INVOICES.POSITIONS.uiKey,
          positions
        );

        return mappedInvoice;
      });

      set(state, FOLIOS_MAPPING.INVOICES.uiKey, invoices);
    })
    .addCase(SET_INVOICE_SPLIT_AVAILABLE, (state, action) => {
      set(state, FOLIOS_MAPPING.INVOICES_SPLIT_AVAILABLE.uiKey, action.payload);
    });
});

export {folioReducer};
