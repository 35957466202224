import "./Datepicker.scss";

import React, {useCallback, useEffect} from "react";

import moment from "moment";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {isEmpty, isNil} from "lodash";

import {DATE_FORMAT, MIN_MAIN_GUEST_AGE} from "../../constants";
import {usePreviousValue} from "../../utils/customHooks/previousValue.hook";
import {Input} from "../Input/Input.component";
import {jumpToNextInputWithoutKey, keepOnlyNumbers} from "../../utils/formInput.utils";
import useCallbackState from "../../utils/customHooks/stateCallback.hook";

const Datepicker = React.memo(
  ({
    label,
    date: incomingDate,
    onChange = () => {},
    onKeyPress = () => {},
    requirement,
    className = "",
    minDate,
    maxDate,
    index = 0,
    shouldDisplayErrorMessage = false,
    shouldDisplayLegalAgeErrorMessage = false,
    placeholderDay = "DD",
    placeholderMonth = "MM",
    placeholderYear = "YYYY",
    fieldKey,
  }) => {
    const {t} = useTranslation(["form", "date", "registration"]);

    const [day, setDay] = useCallbackState(null),
      [month, setMonth] = useCallbackState(null),
      [year, setYear] = useCallbackState(null);

    const previousInputDate = usePreviousValue(incomingDate);

    useEffect(() => {
      if (previousInputDate === incomingDate) {
        return;
      }

      if (incomingDate) {
        let formattedDate = incomingDate && moment(incomingDate, DATE_FORMAT);

        if (!formattedDate || !formattedDate.isValid()) {
          return;
        }

        setDay(formattedDate.format("DD"));
        setMonth(formattedDate.format("MM"));
        setYear(formattedDate.format("YYYY"));
      }
    }, [incomingDate, previousInputDate, setDay, setMonth, setYear]);

    const isInputFilledOut = useCallback(() => {
      return !isNil(day) && !isNil(month) && !isNil(year);
    }, [day, month, year]);

    const isDateValid = useCallback(() => {
      if (isInputFilledOut()) {
        const dateString = `${year}-${addLeadingZero(month)}-${addLeadingZero(day)}`;
        const date = moment(dateString, DATE_FORMAT, true);
        if (minDate && moment(date).isBefore(minDate)) {
          return false;
        } else if (maxDate && moment(date).isAfter(maxDate)) {
          return false;
        } else {
          return moment(date, DATE_FORMAT, true).isValid();
        }
      } else {
        return false;
      }
    }, [minDate, maxDate, isInputFilledOut, day, month, year]);

    const isLegal = useCallback(() => {
      if (!shouldDisplayLegalAgeErrorMessage) {
        return true;
      } else if (isInputFilledOut()) {
        const date = `${year}-${addLeadingZero(month)}-${addLeadingZero(day)}`;
        const age = moment().diff(moment(date, DATE_FORMAT, true), "years");
        return age >= MIN_MAIN_GUEST_AGE;
      } else {
        return false;
      }
    }, [shouldDisplayLegalAgeErrorMessage, isInputFilledOut, day, month, year]);

    const isValidDay = useCallback(() => !isInputFilledOut() || isDateValid(), [
      isInputFilledOut,
      isDateValid,
    ]);

    const isValidMonth = useCallback(() => {
      return !isNil(month);
    }, [month]);

    const isValidYear = useCallback(
      () => !isInputFilledOut() || !isDateValid() || isLegal(),
      [isInputFilledOut, isDateValid, isLegal]
    );

    const handleDayChange = (object, event) => {
      let day = Object.values(object)[0];
      let dayParsedString = keepOnlyNumbers(day).slice(0, 2);
      const dayParsedInt = parseInt(dayParsedString) || 1;

      if (!isEmpty(day) && dayParsedInt < 0) {
        dayParsedString = "1";
      } else if (dayParsedInt > 31) {
        dayParsedString = "31";
      }

      if (day.length > 2) {
        setDay("31");
        jumpToNextInputWithoutKey(event);
      } else {
        event.persist();

        setDay(dayParsedString, () => {
          if (day && dayParsedString.length > 1) {
            jumpToNextInputWithoutKey(event);
          }
        });
      }
    };

    const handleMonthChange = (object, event) => {
      let month = Object.values(object)[0];
      let monthParsedString = keepOnlyNumbers(month).slice(0, 2);
      const monthParsedInt = parseInt(monthParsedString) || 1;

      if (!isEmpty(month) && monthParsedInt < 0) {
        monthParsedString = "1";
      } else if (monthParsedInt > 12) {
        monthParsedString = "12";
      }

      if (month.length > 2) {
        setMonth("12");
        jumpToNextInputWithoutKey(event);
      } else {
        event.persist();

        setMonth(monthParsedString, () => {
          if (month && monthParsedString.length > 1) {
            jumpToNextInputWithoutKey(event);
          }
        });
      }
    };

    const handleYearChange = (object, event) => {
      const year = Object.values(object)[0];
      const yearParsedString = keepOnlyNumbers(year).slice(0, 4);

      if (year.length > 4) {
        jumpToNextInputWithoutKey(event);
      } else {
        event.persist();

        setYear(yearParsedString, () => {
          if (year && yearParsedString.length > 3) {
            jumpToNextInputWithoutKey(event);
          }
        });
      }
    };

    const onBlur = () => {
      if (isInputFilledOut() && isDateValid() && isLegal()) {
        onChange({
          [fieldKey]: `${year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day)}`,
        });
      } else {
        onChange({
          [fieldKey]: null,
        });
      }
    };

    const addLeadingZero = (number) => String(parseInt(number, 10)).padStart(2, "0");

    return (
      <div className={`datepicker ${className}`}>
        <label className="datepicker__label">{label}</label>
        <div className="datepicker__fields-wrapper">
          <div>
            <Input
              inputType="tel"
              inputStyleType="box"
              label={t("date:day")}
              value={day}
              onChange={(object, event) => {
                handleDayChange(object, event);
              }}
              onBlur={onBlur}
              fieldKey={fieldKey}
              requirement={requirement}
              isInvalid={!isValidDay()}
              index={index}
              onKeyPress={onKeyPress}
              shouldDisplayErrorMessage={shouldDisplayErrorMessage && !day}
              placeholder={placeholderDay}
            />
            {isInputFilledOut() && !isDateValid() && (
              <div className="datepicker__description">{t("date:invalid")}</div>
            )}
          </div>
          <Input
            inputType="tel"
            inputStyleType="box"
            label={t("date:month")}
            value={month}
            onChange={(object, event) => {
              handleMonthChange(object, event);
            }}
            onBlur={onBlur}
            fieldKey={fieldKey}
            requirement={requirement}
            isInvalid={!isValidMonth()}
            index={index}
            onKeyPress={onKeyPress}
            shouldDisplayErrorMessage={shouldDisplayErrorMessage && !month}
            placeholder={placeholderMonth ?? ""}
          />
          <div>
            <Input
              inputType="tel"
              inputStyleType="box"
              label={t("date:year")}
              value={year}
              onChange={(object, event) => {
                handleYearChange(object, event);
              }}
              onBlur={onBlur}
              fieldKey={fieldKey}
              requirement={requirement}
              isInvalid={!isValidYear()}
              onKeyPress={onKeyPress}
              shouldDisplayErrorMessage={shouldDisplayErrorMessage && !year}
              placeholder={placeholderYear ?? ""}
            />
            {shouldDisplayLegalAgeErrorMessage &&
              isInputFilledOut() &&
              isDateValid() &&
              !isLegal() && (
                <div className="datepicker__description">
                  {t("registration:legalAge", {age: MIN_MAIN_GUEST_AGE})}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
);

Datepicker.propTypes = {
  label: PropTypes.string,
  date: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  minDate: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment),
  requirement: PropTypes.string,
  className: PropTypes.string,
  index: PropTypes.number,
  shouldDisplayErrorMessage: PropTypes.bool,
  shouldDisplayLegalAgeErrorMessage: PropTypes.bool,
  fieldKey: PropTypes.string.isRequired,
  placeholderDay: PropTypes.string,
  placeholderMonth: PropTypes.string,
  placeholderYear: PropTypes.string,
};

export {Datepicker};
