import React, {
  useEffect,
  useState,
} from "react";

import {useClearCache} from "react-clear-cache";
import {useTranslation} from "react-i18next";
import {matchPath} from "react-router-dom";

import {
  Button,
  ButtonGroup,
  buttonTypes,
} from "../../components/Button";
import {Modal} from "../../components/Modal";
import {ROUTE_PATHS} from "../../constants";
import {usePreviousValue} from "../../utils/customHooks/previousValue.hook";
import {isNativeApp} from "../../utils/mobileDevice.utils";

const RefreshCache = () => {
  const {isLatestVersion, emptyCacheStorage} = useClearCache();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {t} = useTranslation(["general"]);

  const handleConfirmClearCache = () => {
    emptyCacheStorage();
    window.location.reload(true);
  };

  const previousIsLatest = usePreviousValue(isLatestVersion);

  useEffect(() => {
    if (isNativeApp || previousIsLatest === isLatestVersion) {
      return;
    }

    const match = matchPath(window.location.pathname, {
      path: `/:type/*`,
      exact: false,
      strict: false,
    });

    const path = `/${match?.params?.[0] || ""}`;

    const canDisplayModal =
      path === ROUTE_PATHS.HOME.PATHNAME || path === ROUTE_PATHS.AUTHENTICATION.PATHNAME;

    if (!isLatestVersion && canDisplayModal) {
      setIsModalVisible(true);
    }
  }, [emptyCacheStorage, isLatestVersion, previousIsLatest]);

  const handleCancelConfirmation = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      isVisible={isModalVisible}
      onHide={handleCancelConfirmation}
      hasCloseButton
      title={t("updateAvailableTitle")}>
      <div>
        <br />
        {t("updateAvailableContent")}
      </div>
      <br />

      <ButtonGroup>
        <Button buttonType={buttonTypes.primary} onClick={handleConfirmClearCache}>
          {t("updateAvailableButton")}
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export {RefreshCache};
