import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

import {COVID_CHECK_FINISH__SAGA} from "../../../constants";
import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  setReservationDataSagaAction,
  showLoadingOverlay,
} from "../../actions";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* covidCheckFinish() {
  const store = yield select(),
    reservation = store.reservationReducer,
    {code, name} = reservation;
  yield put(showLoadingOverlay());

  try {
    const client = yield* getClientTypeFn();
    const covidCheckFinishResponse = yield call([client, "covidCheckFinish"], code, name);

    yield put(setReservationDataSagaAction({response: covidCheckFinishResponse}));
  } catch (e) {
    yield put(hideLoadingOverlay());
    yield put(apiErrorSagaAction({error: e}));
  }
}

export function* covidCheckFinishSaga() {
  yield takeEvery(COVID_CHECK_FINISH__SAGA, covidCheckFinish);
}
