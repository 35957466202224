const translations = {
  mobileKeys: {
    header: "Key Overview",
    title: "Your Mobile Keys",
    subtitle:
      "To unlock the door, turn on your Bluetooth, swipe right and hold your phone close to the door.",
    mobileKeys: "Mobile Keys",
    locked: "Locked",
    open: "Open",
    processing: "Processing",
    error: "Error",
    room: "Room",
    touchDoor: "Please touch your phone to the door",
    doorOpen: "Door is open",
    secCount: "{{count}} sec",
    validFor: "Your Mobile Keys are valid for:",
    hrs: "hrs",
    min: "min",
    sec: "sec",
    expired: "Your Mobile Keys have expired",
    support: {
      title: "Mobile Keys no longer accessible",
      content:
        "The access time to your mobile keys has expired. If you still need access to your room, please contact the reception...",
    },
  },
};

export {translations};
