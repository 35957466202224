import {useEffect, useState} from "react";

import {useSelector} from "react-redux";

export const useUpdatedManifest = () => {
  const siteSettings = useSelector(({appConfigReducer}) => appConfigReducer.siteSettings),
    hotelName = siteSettings.name || "Hotel App";

  const [isManifestUpdated, setIsManifestUpdated] = useState(false);

  useEffect(() => {
    if (!hotelName || isManifestUpdated) {
      return;
    }

    let manifestElement = document.querySelector("[rel='manifest']");

    const styles = getComputedStyle(document.documentElement);
    const brandColor =
      styles.getPropertyValue("--primary-brand-color")?.trim() || "#000000";
    const backgroundColor =
      styles.getPropertyValue("--body-bg-color")?.trim() || "#ffffff";

    const dynamicManifest = {
      short_name: hotelName,
      name: hotelName,
      theme_color: brandColor,
      background_color: backgroundColor,
    };

    try {
      fetch(manifestElement.href)
        .then((response) => response.json())
        .then((existingManifest) => {
          manifestElement.setAttribute(
            "href",
            "data:application/json;charset=utf-8," +
              encodeURIComponent(
                JSON.stringify({
                  ...existingManifest,
                  ...dynamicManifest,
                })
              )
          );
        });
    } catch {
      manifestElement = document.createElement("link");
      manifestElement.rel = "manifest";
      manifestElement.setAttribute(
        "href",
        "data:application/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(dynamicManifest))
      );
      document.head.appendChild(manifestElement);
    } finally {
      setIsManifestUpdated(true);
    }
  }, [isManifestUpdated, hotelName]);

  useEffect(() => {
    if (!hotelName || isManifestUpdated) {
      return;
    }

    const title = document.querySelector("title");

    if (title) {
      title.innerHTML = hotelName;
    }
  }, [isManifestUpdated, hotelName]);
};
