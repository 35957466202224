import PropTypes from "prop-types";
import React from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Step = ({step, rules, currentStep, isCheckMarkInMiddleOfStep = false}) => {
  const location = useLocation();
  const {t} = useTranslation(["stepper"]);

  const getIsActiveStepName = (processRules, stepNameToCheckIfActive, currentStep) => {
    const stepNameOfTheFulfilledRule = Object.keys(processRules).find((rule) => {
      const hasToMatchUrlPathsByRule = processRules[rule].urlPathsToMatch != null;
      let isPathCorrect;

      if (hasToMatchUrlPathsByRule) {
        isPathCorrect = processRules[rule].urlPathsToMatch.some(
          (p) => location.pathname.indexOf(p) !== -1
        );
      } else {
        isPathCorrect = true;
      }

      const isCurrentStepInRule =
        processRules[rule].apiStepsToMatch.indexOf(currentStep) !== -1;
      return isCurrentStepInRule && isPathCorrect;
    });

    return stepNameOfTheFulfilledRule === stepNameToCheckIfActive;
  };

  return (
    <li
      className={`stepper-container__item ${
        getIsActiveStepName(rules, step.name, currentStep)
          ? "stepper-container__item--active"
          : ""
      } ${isCheckMarkInMiddleOfStep ? "checkmark-holder " : ""}`}>
      {step.icon}
      <label>
        <span>{t(step.label)}</span>
      </label>
    </li>
  );
};

Step.propTypes = {
  step: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
  }),
  rules: PropTypes.object,
  currentStep: PropTypes.string,
  isCheckMarkInMiddleOfStep: PropTypes.bool,
};

export {Step};
