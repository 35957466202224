const translations = {
  key: {
    title: "Schlüssel",
    takeAndPlace: "Schlüsselkarte entnehmen und auf den Scanner legen",
    placeConfirm: "Ja, meine Karte liegt auf dem Scanner",
    writing: "Bitte warten Sie bis Ihre Schlüsselkarte vollständig beschrieben wurde",
    additionalCard: "Möchten Sie eine weitere Schlüsselkarte erhalten?",
    additional: "Weitere Schlüsselkarte",
    all: "Sie haben alle verfügbaren Zimmerkarten erhalten.",
    renew: "Erneuern",

    goToTerminal: "Bitte gehen Sie zum Terminal, um Ihren Schlüssel zu erhalten",
    cantFindTerminal: "Können Sie das Terminal nicht finden?",
    clickHere: "Klicken Sie hier",
    inFrontOfTerminal: "Ich stehe vor dem Terminal",
    getYourKeys: "Erhalten Sie Ihre Schlüssel",
    only3Steps: "Nur 3 Schritte zu Ihrer Schlüsselkarte…",
    steps: {
      step1:
        "Wählen Sie <strong>Check-In</strong> auf dem <strong>Terminal</strong> Bildschirm",
      step2: "Wählen Sie die Option <strong>“QR-Code Scannen”</strong>",
      step3: "Scannen Sie <strong>Ihren QR-Code</strong>:",
    },
    keyReceived: "Ich habe meinen Schlüssel erhalten",
    problem: "Ich habe ein Problem mit meinem Schlüssel",

    howTo: "So erhalten Sie Ihren Zimmerschlüssel:",
    takeCard: "Bitte nehmen Sie eine Schlüsselkarte vom Stapel neben dem Terminal",
    nextStep: "Nächster Schritt",
    holdKey: "Legen Sie Ihre Schlüsselkarte direkt auf dem Key Encoder auf",
    startEncoding: "Jetzt meinen Schlüssel beschreiben",
    codingKey: "Schlüsselkarte wird beschrieben...",
    success:
      "Ihre Zimmerkarte wurde erfolgreich beschrieben. Sie können die Karte entfernen.",
    getAdditionalKey: "Zusätzliche Schlüsselkarte",
    goToOverview: "Weiter zur Check-In Übersicht",
    error: "Upps... Etwas ist schiefgelaufen",
    question: "Ihre Schlüsselkarte kann nicht beschrieben werden?",
    cannotEncodeKey: "Schlüssel kann nicht codiert werden",
    createKeyCard: "Schlüsselkarte erstellen",

    modalForPhysicalVsMobileKeyChoice: {
      title: "Wählen Sie Ihren Schlüssel",
      terminalText:
        "<0>Erstellen Sie eine Schlüsselkarte am Terminal an der Hotelrezeption.</0>\n\n(auch später an der Rezeption erhältlich)",
      terminalButton: "Schlüsselkarte jetzt erstellen",
      or: "oder",
      mobileText:
        "<0>den digitalen Schlüssel verwenden</0>\n\n(Türen öffnen mit Ihrem Smartphone)",
      mobileButton: "Digitalen Schlüssel verwenden",
    },
  },
};

export {translations};
