import PropTypes from "prop-types";
import React from "react";

const NotificationsPlaceholder = ({floating = false}) => {
  return (
    <div
      className={`notifications-container ${
        floating ? "notifications-container--floating" : ""
      }`}
    />
  );
};

NotificationsPlaceholder.propTypes = {
  floating: PropTypes.bool,
};

export {NotificationsPlaceholder};
