import {useEffect} from "react";

import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

import {findBookingDetailsByGuidSagaAction} from "../../store";

const ReservationByGuid = () => {
  const dispatch = useDispatch();
  const {guid} = useParams();

  useEffect(() => {
    dispatch(findBookingDetailsByGuidSagaAction({guid}));
  }, [dispatch, guid]);

  return null;
};

export {ReservationByGuid};
