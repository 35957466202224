import {
  apiErrorSagaAction,
  hideLoadingOverlay,
  setReservationDataSagaAction,
  showLoadingOverlay,
} from "../../actions";
import {call, put, select, takeEvery} from "redux-saga/effects";

import {POST_PAYMENT_SPLIT__SAGA} from "../../../constants";
import {getClientTypeFn} from "../utils/getClientType.generatorFunction";

function* postPaymentSplit(action) {
  const {positions} = action.payload;

  try {
    yield put(showLoadingOverlay());
    const store = yield select();

    const reservation = store.reservationReducer,
      {code, name} = reservation;

    const client = yield* getClientTypeFn();

    const splitResponse = yield call([client, "postPaymentSplit"], code, name, positions);

    yield put(setReservationDataSagaAction({response: splitResponse}));
  } catch (e) {
    yield put(apiErrorSagaAction({error: e}));

    yield put(hideLoadingOverlay());
  }
}

export function* postPaymentSplitSaga() {
  yield takeEvery(POST_PAYMENT_SPLIT__SAGA, postPaymentSplit);
}
